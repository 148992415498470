export const STATION_COORDINATES = {
  MLB: {
    "Arizona Diamondbacks": [
      {
        market: "Phoenix",
        latitude: "33.4483771",
        longitude: "-112.0740373",
        station: "KMVP\nKTAR",
        frequency: "98.7 FM\n620 AM",
      },
      {
        market: "Alamogordo",
        latitude: "32.8995325",
        longitude: "-105.960265",
        station: "KNMZ",
        frequency: "103.7 FM",
      },
      {
        market: "Bullhead City",
        latitude: "35.1359386",
        longitude: "-114.5285981",
        station: "KNTR",
        frequency: "100.5 FM",
      },
      {
        market: "Flagstaff",
        latitude: "35.1982836",
        longitude: "-111.651302",
        station: "KVNA",
        frequency: "600 AM",
      },
      {
        market: "Globe",
        latitude: "33.3942223",
        longitude: "-110.7864984",
        station: "KIKO",
        frequency: "1340 AM",
      },
      {
        market: "Holbrook",
        latitude: "34.9022482",
        longitude: "-110.1581768",
        station: "KZUA",
        frequency: "92.1 FM",
      },
      {
        market: "Kingman",
        latitude: "35.189443",
        longitude: "-114.0530065",
        station: "KZKE",
        frequency: "95.1 FM",
      },
      {
        market: "Lake Havasu",
        latitude: "34.483901",
        longitude: "-114.3224548",
        station: "KNTR",
        frequency: "980 AM\n94.3 FM",
      },
      {
        market: "Prescott Valley",
        latitude: "34.6100243",
        longitude: "-112.315721",
        station: "KQNA\nKDDL",
        frequency: "1130 AM\n99.9 FM\n94.3 FM",
      },
      {
        market: "Safford",
        latitude: "32.8339546",
        longitude: "-109.70758",
        station: "KATO",
        frequency: "1230 AM",
      },
      {
        market: "Sedona",
        latitude: "34.8697395",
        longitude: "-111.7609896",
        station: "KAZM",
        frequency: "780 AM",
      },
      {
        market: "Seligman",
        latitude: "35.3255608",
        longitude: "-112.8774057",
        station: "KZKE",
        frequency: "103.3 FM",
      },
      {
        market: "Show Low",
        latitude: "34.1425438",
        longitude: "-109.9603843",
        station: "KNKI",
        frequency: "106.7 FM",
      },
      {
        market: "Springerville",
        latitude: "34.1333799",
        longitude: "-109.2859196",
        station: "KRVZ",
        frequency: "1400 AM",
      },
      {
        market: "Tucson",
        latitude: "32.2539787",
        longitude: "-110.9741769",
        station: "KFFN",
        frequency: "104.9 FM\n1490 AM",
      },
      {
        market: "Winlsow",
        latitude: "35.0241873",
        longitude: "-110.6973571",
        station: "KINO",
        frequency: "1230 AM",
      },
      {
        market: "Yuma",
        latitude: "32.6926512",
        longitude: "-114.6276916",
        station: "KCYK",
        frequency: "1400 AM",
      },
    ],
    "Atlanta Braves": [
      {
        market: "Auburn",
        station: "WAUD",
        frequency: "1230 AM",
        latitude: "32.6098566",
        longitude: "-85.4807825",
      },
      {
        market: "Auburn",
        station: "W266BJ",
        frequency: "101.1 FM",
        latitude: "32.6098566",
        longitude: "-85.4807825",
      },
      {
        market: "Birmingham-Hoover",
        station: "WJOX",
        frequency: "690 AM",
        latitude: "33.4415956",
        longitude: "-86.7818523",
      },
      {
        market: "Birmingham-Tuscaloosa",
        station: "WJOX",
        frequency: "94.5 FM",
        latitude: "33.5185892",
        longitude: "-86.8103567",
      },
      {
        market: "Birmingham-Tuscaloosa",
        station: "WJQX",
        frequency: "100.5 FM",
        latitude: "33.5185892",
        longitude: "-86.8103567",
      },
      {
        market: "Brewton",
        station: "WKNU",
        frequency: "106.3 FM",
        latitude: "31.1051779",
        longitude: "-87.0721918",
      },
      {
        market: "Cullman",
        station: "WKUL",
        frequency: "92.1 FM",
        latitude: "34.1748208",
        longitude: "-86.8436124",
      },
      {
        market: "Florence",
        station: "WYTK",
        frequency: "93.9 FM",
        latitude: "34.79981",
        longitude: "-87.677251",
      },
      {
        market: "Foley",
        station: "WHEP",
        frequency: "1310 AM",
        latitude: "30.4065868",
        longitude: "-87.6835974",
      },
      {
        market: "Foley",
        station: "W223AX",
        frequency: "92.5 FM",
        latitude: "30.4065868",
        longitude: "-87.6835974",
      },
      {
        market: "Gadsden",
        station: "WKXX",
        frequency: "102.9 FM",
        latitude: "34.014264",
        longitude: "-86.0066386",
      },
      {
        market: "Guntersville-Albertville",
        station: "WTWX",
        frequency: "95.9 FM",
        latitude: "34.3581473",
        longitude: "-86.2947041",
      },
      {
        market: "Huntsville",
        station: "WZZN",
        frequency: "97.7 FM",
        latitude: "34.7303688",
        longitude: "-86.5861037",
      },
      {
        market: "Oxford-Anniston",
        station: "WVOK",
        frequency: "1580 AM",
        latitude: "33.6142714",
        longitude: "-85.8349648",
      },
      {
        market: "Scottsboro",
        station: "WWIC",
        frequency: "1050 AM",
        latitude: "34.6723069",
        longitude: "-86.0341463",
      },
      {
        market: "Thomasville-Jackson",
        station: "WJDB",
        frequency: "95.5 FM",
        latitude: "31.9134859",
        longitude: "-87.7358366",
      },
      {
        market: "Troy",
        station: "WTBF",
        frequency: "970 AM",
        latitude: "31.8087678",
        longitude: "-85.969951",
      },
      {
        market: "Troy",
        station: "W242DA",
        frequency: "96.3 FM",
        latitude: "31.8087678",
        longitude: "-85.969951",
      },
      {
        market: "Crestview",
        station: "WJSB",
        frequency: "1050 AM",
        latitude: "30.7541808",
        longitude: "-86.5727995",
      },
      {
        market: "Crestview",
        station: "W266DF",
        frequency: "101.1 FM",
        latitude: "30.7541808",
        longitude: "-86.5727995",
      },
      {
        market: "Ft. Walton Beach-Destin",
        station: "WAAZ",
        frequency: "104.7 FM",
        latitude: "30.3935337",
        longitude: "-86.4957833",
      },
      {
        market: "Panama City",
        station: "WGSX",
        frequency: "104.3 FM",
        latitude: "30.1588129",
        longitude: "-85.6602058",
      },
      {
        market: "Adairsville",
        station: "W269CC",
        frequency: "101.7 FM",
        latitude: "34.3687021",
        longitude: "-84.9341093",
      },
      {
        market: "Albany",
        station: "WALG",
        frequency: "1590 AM",
        latitude: "31.5785074",
        longitude: "-84.155741",
      },
      {
        market: "Albany",
        station: "W257ED",
        frequency: "99.3 FM",
        latitude: "31.5785074",
        longitude: "-84.155741",
      },
      {
        market: "Americus",
        station: "WISK",
        frequency: "98.7 FM",
        latitude: "32.0723861",
        longitude: "-84.2326876",
      },
      {
        market: "Athens",
        station: "WRFC",
        frequency: "960 AM",
        latitude: "33.9519347",
        longitude: "-83.357567",
      },
      {
        market: "Atlanta",
        station: "WCNN",
        frequency: "680 AM",
        latitude: "33.7489954",
        longitude: "-84.3879824",
      },
      {
        market: "Atlanta",
        station: "W229AG",
        frequency: "93.7 FM",
        latitude: "33.7489954",
        longitude: "-84.3879824",
      },
      {
        market: "Brunswick-Saint Simons",
        station: "WSFN",
        frequency: "790 AM",
        latitude: "31.1598492",
        longitude: "-81.3875372",
      },
      {
        market: "Brunswick-Saint Simons",
        station: "W279BC",
        frequency: "103.7 FM",
        latitude: "31.1598492",
        longitude: "-81.3875372",
      },
      {
        market: "Calhoun",
        station: "WJTH",
        frequency: "900 AM",
        latitude: "34.502587",
        longitude: "-84.9510542",
      },
      {
        market: "Carrollton",
        station: "WLBB",
        frequency: "1330 AM",
        latitude: "33.5801103",
        longitude: "-85.0766113",
      },
      {
        market: "Carrollton",
        station: "W292EW",
        frequency: "106.3 FM",
        latitude: "33.5801103",
        longitude: "-85.0766113",
      },
      {
        market: "Carrollton",
        station: "WBTR",
        frequency: "92.1 FM",
        latitude: "33.5801103",
        longitude: "-85.0766113",
      },
      {
        market: "Cartersville",
        station: "WBHF",
        frequency: "1450 AM",
        latitude: "34.1650972",
        longitude: "-84.7999382",
      },
      {
        market: "Cartersville",
        station: "W262CD",
        frequency: "100.3 FM",
        latitude: "34.1650972",
        longitude: "-84.7999382",
      },
      {
        market: "Claxton",
        station: "WCLA",
        frequency: "1470 AM",
        latitude: "32.1612812",
        longitude: "-81.9040199",
      },
      {
        market: "Claxton",
        station: "W229AJ",
        frequency: "93.7 FM",
        latitude: "32.1612812",
        longitude: "-81.9040199",
      },
      {
        market: "Columbus",
        station: "W288CV",
        frequency: "105.5 FM",
        latitude: "32.4609764",
        longitude: "-84.9877094",
      },
      {
        market: "Cornelia-Ne Georgia",
        station: "WCON",
        frequency: "99.3 FM",
        latitude: "34.5114883",
        longitude: "-83.5271166",
      },
      {
        market: "Dalton",
        station: "WBLJ",
        frequency: "1230 AM",
        latitude: "34.7698021",
        longitude: "-84.9702228",
      },
      {
        market: "Dalton",
        station: "WDAL",
        frequency: "1430 AM",
        latitude: "34.7698021",
        longitude: "-84.9702228",
      },
      {
        market: "Dalton",
        station: "WYYU",
        frequency: "104.5 FM",
        latitude: "34.7698021",
        longitude: "-84.9702228",
      },
      {
        market: "Donalsonville-Bainbridge",
        station: "WGMK",
        frequency: "106.3 FM",
        latitude: "31.0404625",
        longitude: "-84.8790911",
      },
      {
        market: "Douglas",
        station: "WPNG",
        frequency: "101.9 FM",
        latitude: "31.5088073",
        longitude: "-82.8498654",
      },
      {
        market: "Dublin",
        station: "WDBG-HD2",
        frequency: "103.1 FM",
        latitude: "32.5404447",
        longitude: "-82.903754",
      },
      {
        market: "Dublin",
        station: "W271CI",
        frequency: "102.1 FM",
        latitude: "32.5404447",
        longitude: "-82.903754",
      },
      {
        market: "Ellijay",
        station: "WLJA",
        frequency: "101.1 FM",
        latitude: "34.6948076",
        longitude: "-84.4821498",
      },
      {
        market: "Ellijay",
        station: "WPGY",
        frequency: "1580 AM",
        latitude: "34.6948076",
        longitude: "-84.4821498",
      },
      {
        market: "Ellijay",
        station: "W229CE",
        frequency: "93.7 FM",
        latitude: "34.6948076",
        longitude: "-84.4821498",
      },
      {
        market: "Fort Valley",
        station: "WXKO",
        frequency: "1150 AM",
        latitude: "32.5537585",
        longitude: "-83.8874084",
      },
      {
        market: "Gainesville",
        station: "WGGA",
        frequency: "1240 AM",
        latitude: "34.2978794",
        longitude: "-83.8240663",
      },
      {
        market: "Gainesville",
        station: "W233CO",
        frequency: "94.5 FM",
        latitude: "34.2978794",
        longitude: "-83.8240663",
      },
      {
        market: "Greensboro",
        station: "WDDK",
        frequency: "103.9 FM",
        latitude: "33.5756831",
        longitude: "-83.1823789",
      },
      {
        market: "Griffin",
        station: "WKEU",
        frequency: "1450 AM",
        latitude: "33.2467807",
        longitude: "-84.2640904",
      },
      {
        market: "Griffin",
        station: "W272DM",
        frequency: "102.3 FM",
        latitude: "33.2467807",
        longitude: "-84.2640904",
      },
      {
        market: "Hiawassee",
        station: "WJUL",
        frequency: "1230 AM",
        latitude: "34.9492586",
        longitude: "-83.7573948",
      },
      {
        market: "Hiawassee",
        station: "W249DB",
        frequency: "97.7 FM",
        latitude: "34.9492586",
        longitude: "-83.7573948",
      },
      {
        market: "Jesup",
        station: "WLOP",
        frequency: "1370 AM",
        latitude: "31.6074365",
        longitude: "-81.8853924",
      },
      {
        market: "Jesup",
        station: "WIFO",
        frequency: "105.5 FM",
        latitude: "31.6074365",
        longitude: "-81.8853924",
      },
      {
        market: "La Grange",
        station: "WTRP",
        frequency: "620 AM",
        latitude: "33.0362218",
        longitude: "-85.0322444",
      },
      {
        market: "La Grange",
        station: "W255DP",
        frequency: "98.9 FM",
        latitude: "33.0362218",
        longitude: "-85.0322444",
      },
      {
        market: "Louisville",
        station: "WPEH",
        frequency: "1420 AM",
        latitude: "33.0015407",
        longitude: "-82.4112377",
      },
      {
        market: "Louisville",
        station: "WPEH",
        frequency: "92.1 FM",
        latitude: "33.0015407",
        longitude: "-82.4112377",
      },
      {
        market: "Macon",
        station: "W226BZ",
        frequency: "93.1 FM",
        latitude: "32.8406946",
        longitude: "-83.6324022",
      },
      {
        market: "Metter",
        station: "WHCG",
        frequency: "1360 AM",
        latitude: "32.397118",
        longitude: "-82.0601195",
      },
      {
        market: "Metter",
        station: "W255AT",
        frequency: "98.9 FM",
        latitude: "32.397118",
        longitude: "-82.0601195",
      },
      {
        market: "Milledgeville",
        station: "WMVG",
        frequency: "1450 AM",
        latitude: "33.0801429",
        longitude: "-83.2320991",
      },
      {
        market: "Milledgeville",
        station: "W283CZ",
        frequency: "104.5 FM",
        latitude: "33.0801429",
        longitude: "-83.2320991",
      },
      {
        market: "Milledgeville",
        station: "WKZR",
        frequency: "102.3 FM",
        latitude: "33.0801429",
        longitude: "-83.2320991",
      },
      {
        market: "Monroe",
        station: "WKUN",
        frequency: "1490 AM",
        latitude: "33.7948364",
        longitude: "-83.713229",
      },
      {
        market: "Monroe",
        station: "W273DL",
        frequency: "102.5 FM",
        latitude: "33.7948364",
        longitude: "-83.713229",
      },
      {
        market: "Moultrie",
        station: "WMTM",
        frequency: "1300 AM",
        latitude: "31.1799407",
        longitude: "-83.7888387",
      },
      {
        market: "Moultrie",
        station: "W286BO",
        frequency: "105.1 FM",
        latitude: "31.1799407",
        longitude: "-83.7888387",
      },
      {
        market: "Pelham-Camilla",
        station: "WQLI",
        frequency: "92.3 FM",
        latitude: "31.1276849",
        longitude: "-84.1518466",
      },
      {
        market: "Pinehurst",
        station: "WCEH",
        frequency: "98.3 FM",
        latitude: "32.1959971",
        longitude: "-83.7629533",
      },
      {
        market: "Rochelle",
        station: "WWKM",
        frequency: "93.1 FM",
        latitude: "31.9510105",
        longitude: "-83.4562744",
      },
      {
        market: "Rome",
        station: "WLAQ",
        frequency: "1410 AM",
        latitude: "34.257038",
        longitude: "-85.1646726",
      },
      {
        market: "Rome",
        station: "W245DG",
        frequency: "96.9 FM",
        latitude: "34.257038",
        longitude: "-85.1646726",
      },
      {
        market: "Sandersville",
        station: "WSNT",
        frequency: "99.9 FM",
        latitude: "32.9815431",
        longitude: "-82.810138",
      },
      {
        market: "Savannah",
        station: "WSEG",
        frequency: "1400 AM",
        latitude: "32.0808989",
        longitude: "-81.091203",
      },
      {
        market: "Savannah",
        station: "W282AR",
        frequency: "104.3 FM",
        latitude: "32.0808989",
        longitude: "-81.091203",
      },
      {
        market: "Swainsboro",
        station: "WJAT",
        frequency: "800 AM",
        latitude: "32.5973856",
        longitude: "-82.3337376",
      },
      {
        market: "Swainsboro",
        station: "W259AK",
        frequency: "99.7 FM",
        latitude: "32.5973856",
        longitude: "-82.3337376",
      },
      {
        market: "Thomaston",
        station: "WTGA",
        frequency: "101.1 FM",
        latitude: "32.8881879",
        longitude: "-84.3265854",
      },
      {
        market: "Thomasville",
        station: "WPAX",
        frequency: "1240 AM",
        latitude: "30.8365815",
        longitude: "-83.9787808",
      },
      {
        market: "Thomasville",
        station: "W279BD",
        frequency: "103.7 FM",
        latitude: "30.8365815",
        longitude: "-83.9787808",
      },
      {
        market: "Trenton",
        station: "WKWN",
        frequency: "1420 AM",
        latitude: "34.8720222",
        longitude: "-85.50913",
      },
      {
        market: "Trenton",
        station: "W267CX",
        frequency: "101.3 FM",
        latitude: "34.8720222",
        longitude: "-85.50913",
      },
      {
        market: "Trenton",
        station: "W291CF",
        frequency: "106.1 FM",
        latitude: "34.8720222",
        longitude: "-85.50913",
      },
      {
        market: "Valdosta",
        station: "WJEM",
        frequency: "1150 AM",
        latitude: "30.8327022",
        longitude: "-83.2784851",
      },
      {
        market: "Valdosta",
        station: "W232DB",
        frequency: "94.3 FM",
        latitude: "30.8327022",
        longitude: "-83.2784851",
      },
      {
        market: "Vidalia",
        station: "WVOP",
        frequency: "970 AM",
        latitude: "32.2176855",
        longitude: "-82.4134614",
      },
      {
        market: "Vidalia",
        station: "WYUM",
        frequency: "101.7 FM",
        latitude: "32.2176855",
        longitude: "-82.4134614",
      },
      {
        market: "Warner Robins",
        station: "W290BD",
        frequency: "105.9 FM",
        latitude: "32.6130007",
        longitude: "-83.624201",
      },
      {
        market: "Waycross-Blackshear",
        station: "WFNS",
        frequency: "1350 AM",
        latitude: "31.2135511",
        longitude: "-82.3540178",
      },
      {
        market: "Waycross-Blackshear",
        station: "W253CI",
        frequency: "98.5 FM",
        latitude: "31.2135511",
        longitude: "-82.3540178",
      },
      {
        market: "West Point",
        station: "WRLA",
        frequency: "1490 AM",
        latitude: "32.8779056",
        longitude: "-85.1832746",
      },
      {
        market: "West Point",
        station: "W222CT",
        frequency: "92.3 FM",
        latitude: "32.8779056",
        longitude: "-85.1832746",
      },
      {
        market: "Winder",
        station: "WJBB",
        frequency: "1300 AM",
        latitude: "33.9926097",
        longitude: "-83.7201709",
      },
      {
        market: "Winder",
        station: "W296CX",
        frequency: "107.1 FM",
        latitude: "33.9926097",
        longitude: "-83.7201709",
      },
      {
        market: "Greenville-Central City",
        station: "WPDQ",
        frequency: "91.3 FM",
        latitude: "37.2011539",
        longitude: "-87.1788854",
      },
      {
        market: "Louisville",
        station: "WXVW",
        frequency: "1450 AM",
        latitude: "38.2526647",
        longitude: "-85.7584557",
      },
      {
        market: "Louisville",
        station: "W241CK",
        frequency: "96.1 FM",
        latitude: "38.2526647",
        longitude: "-85.7584557",
      },
      {
        market: "Asheville",
        station: "WPEK",
        frequency: "880 AM",
        latitude: "35.5950581",
        longitude: "-82.5514869",
      },
      {
        market: "Asheville",
        station: "W225CJ",
        frequency: "92.9 FM",
        latitude: "35.5950581",
        longitude: "-82.5514869",
      },
      {
        market: "Charlotte",
        station: "WZGV",
        frequency: "730 AM",
        latitude: "35.2270869",
        longitude: "-80.8431267",
      },
      {
        market: "Charlotte",
        station: "W248CO",
        frequency: "97.5 FM",
        latitude: "35.2270869",
        longitude: "-80.8431267",
      },
      {
        market: "Cherryville",
        station: "WCSL",
        frequency: "1590 AM",
        latitude: "35.3789361",
        longitude: "-81.3795737",
      },
      {
        market: "Cherryville",
        station: "W222CO",
        frequency: "92.3 FM",
        latitude: "35.3789361",
        longitude: "-81.3795737",
      },
      {
        market: "Lincolnton",
        station: "WLON",
        frequency: "1050 AM",
        latitude: "35.473745",
        longitude: "-81.2545251",
      },
      {
        market: "Lincolnton",
        station: "W298CK",
        frequency: "107.5 FM",
        latitude: "35.473745",
        longitude: "-81.2545251",
      },
      {
        market: "Rutherfordton",
        station: "WCAB",
        frequency: "590 AM",
        latitude: "35.3692877",
        longitude: "-81.9567716",
      },
      {
        market: "Rutherfordton",
        station: "W265DW",
        frequency: "100.9 FM",
        latitude: "35.3692877",
        longitude: "-81.9567716",
      },
      {
        market: "Shelby",
        station: "WOHS",
        frequency: "1390 AM",
        latitude: "35.2923513",
        longitude: "-81.5356463",
      },
      {
        market: "Shelby",
        station: "W268CU",
        frequency: "101.5 FM",
        latitude: "35.2923513",
        longitude: "-81.5356463",
      },
      {
        market: "Waynesville",
        station: "WMXF",
        frequency: "1400 AM",
        latitude: "35.4887145",
        longitude: "-82.9887477",
      },
      {
        market: "Aiken",
        station: "WGUS",
        frequency: "102.7 FM",
        latitude: "33.5604168",
        longitude: "-81.7195533",
      },
      {
        market: "Camden-Lugoff",
        station: "WCAM",
        frequency: "1590 AM",
        latitude: "34.2273712",
        longitude: "-80.6892486",
      },
      {
        market: "Camden-Lugoff",
        station: "W254DQ",
        frequency: "98.7 FM",
        latitude: "34.2273712",
        longitude: "-80.6892486",
      },
      {
        market: "Camden-Lugoff",
        station: "WPUB",
        frequency: "102.7 FM",
        latitude: "34.2273712",
        longitude: "-80.6892486",
      },
      {
        market: "Charleston",
        station: "WTMZ",
        frequency: "910 AM",
        latitude: "32.7764749",
        longitude: "-79.9310512",
      },
      {
        market: "Charleston",
        station: "W234CV",
        frequency: "94.7 FM",
        latitude: "32.7764749",
        longitude: "-79.9310512",
      },
      {
        market: "Columbia",
        station: "WISW",
        frequency: "1320 AM",
        latitude: "34.0007104",
        longitude: "-81.0348144",
      },
      {
        market: "Columbia",
        station: "WNKT",
        frequency: "107.5 FM",
        latitude: "34.0007104",
        longitude: "-81.0348144",
      },
      {
        market: "Florence",
        station: "WOLH",
        frequency: "1230 AM",
        latitude: "34.1954331",
        longitude: "-79.7625625",
      },
      {
        market: "Florence",
        station: "W242CB",
        frequency: "96.3 FM",
        latitude: "34.1954331",
        longitude: "-79.7625625",
      },
      {
        market: "Florence",
        station: "WSIM-HD2",
        frequency: "93.7 FM",
        latitude: "34.1954331",
        longitude: "-79.7625625",
      },
      {
        market: "Hilton Head Island",
        station: "W224DH",
        frequency: "92.7 FM",
        latitude: "32.216316",
        longitude: "-80.752608",
      },
      {
        market: "Mcclellanville",
        station: "WTMZ",
        frequency: "98.9 FM",
        latitude: "33.087972",
        longitude: "-79.467312",
      },
      {
        market: "Spartanburg",
        station: "WSPG",
        frequency: "1400 AM",
        latitude: "34.9495672",
        longitude: "-81.9320482",
      },
      {
        market: "Spartanburg",
        station: "W252DQ",
        frequency: "98.3 FM",
        latitude: "34.9495672",
        longitude: "-81.9320482",
      },
      {
        market: "Union",
        station: "WBCU",
        frequency: "1460 AM",
        latitude: "34.7154122",
        longitude: "-81.623711",
      },
      {
        market: "Union",
        station: "W278BE",
        frequency: "103.5 FM",
        latitude: "34.7154122",
        longitude: "-81.623711",
      },
      {
        market: "Chattanooga",
        station: "WALV",
        frequency: "95.3 FM",
        latitude: "35.0457984",
        longitude: "-85.3093995",
      },
      {
        market: "Cleveland",
        station: "WCLE",
        frequency: "1570 AM",
        latitude: "35.1595182",
        longitude: "-84.8766115",
      },
      {
        market: "Cleveland",
        station: "W256DQ",
        frequency: "99.1 FM",
        latitude: "35.1595182",
        longitude: "-84.8766115",
      },
      {
        market: "Cleveland",
        station: "WCLE",
        frequency: "104.1 FM",
        latitude: "35.1595182",
        longitude: "-84.8766115",
      },
      {
        market: "Cookville",
        station: "WKXD-HD3",
        frequency: "106.9 FM",
        latitude: "36.162839",
        longitude: "-85.5016423",
      },
      {
        market: "Cookville",
        station: "W282AG",
        frequency: "104.7 FM",
        latitude: "36.162839",
        longitude: "-85.5016423",
      },
      {
        market: "Columbia",
        station: "WKRM",
        frequency: "1340 AM",
        latitude: "35.6150716",
        longitude: "-87.0352831",
      },
      {
        market: "Columbia",
        station: "W279DL",
        frequency: "103.7 FM",
        latitude: "35.6150716",
        longitude: "-87.0352831",
      },
      {
        market: "Jackson",
        station: "WTJK",
        frequency: "105.3 FM",
        latitude: "35.6145169",
        longitude: "-88.8139469",
      },
      {
        market: "Johnson City-Kingsport-Bristol",
        station: "WPWT",
        frequency: "870 AM",
        latitude: "36.3134397",
        longitude: "-82.3534727",
      },
      {
        market: "Johnson City-Kingsport-Bristol",
        station: "W242CQ",
        frequency: "96.3 FM",
        latitude: "36.3134397",
        longitude: "-82.3534727",
      },
      {
        market: "Johnson City-Kingsport-Bristol",
        station: "W264BY",
        frequency: "100.7 FM",
        latitude: "36.3134397",
        longitude: "-82.3534727",
      },
      {
        market: "Lawrenceburg",
        station: "WDXE",
        frequency: "1370 AM",
        latitude: "35.2423023",
        longitude: "-87.3347389",
      },
      {
        market: "Lawrenceburg",
        station: "W237FD",
        frequency: "95.3 FM",
        latitude: "35.2423023",
        longitude: "-87.3347389",
      },
      {
        market: "Lawrenceburg",
        station: "W287AA",
        frequency: "105.3 FM",
        latitude: "35.2423023",
        longitude: "-87.3347389",
      },
      {
        market: "Lewisburg",
        station: "WJJM",
        frequency: "1490 AM",
        latitude: "35.4492404",
        longitude: "-86.7888887",
      },
      {
        market: "Lewisburg",
        station: "W223CY",
        frequency: "92.5 FM",
        latitude: "35.4492404",
        longitude: "-86.7888887",
      },
      {
        market: "Loudon",
        station: "WLOD",
        frequency: "1140 AM",
        latitude: "35.7328541",
        longitude: "-84.3338112",
      },
      {
        market: "Loudon",
        station: "W284DC",
        frequency: "104.7 FM",
        latitude: "35.7328541",
        longitude: "-84.3338112",
      },
      {
        market: "Manchester",
        station: "WMSR",
        frequency: "1320 AM",
        latitude: "35.4817431",
        longitude: "-86.0885993",
      },
      {
        market: "Manchester",
        station: "W300BL",
        frequency: "107.9 FM",
        latitude: "35.4817431",
        longitude: "-86.0885993",
      },
      {
        market: "Murfreesboro-Smyrna",
        station: "WGNS",
        frequency: "1450 AM",
        latitude: "35.9470762",
        longitude: "-86.4877959",
      },
      {
        market: "Murfreesboro-Smyrna",
        station: "W263AI",
        frequency: "100.5 FM",
        latitude: "35.9470762",
        longitude: "-86.4877959",
      },
      {
        market: "Murfreesboro-Smyrna",
        station: "W270AF",
        frequency: "101.9 FM",
        latitude: "35.9470762",
        longitude: "-86.4877959",
      },
      {
        market: "Nashville-Brentwood",
        station: "WNSR",
        frequency: "560 AM",
        latitude: "36.0331164",
        longitude: "-86.7827772",
      },
      {
        market: "Nashville-Brentwood-Franklin",
        station: "W240CA",
        frequency: "95.9 FM",
        latitude: "36.0331164",
        longitude: "-86.7827772",
      },
      {
        market: "Nashville-Smyrna",
        station: "W300DO",
        frequency: "107.9 FM",
        latitude: "35.9264207",
        longitude: "-86.4697062",
      },
      {
        market: "Pigeon Forge",
        station: "WPFT",
        frequency: "106.3 FM",
        latitude: "35.7884241",
        longitude: "-83.5543329",
      },
      {
        market: "Sevierville",
        station: "WSEV",
        frequency: "105.5 FM",
        latitude: "35.8681455",
        longitude: "-83.561835",
      },
      {
        market: "Sevierville",
        station: "W281BQ",
        frequency: "104.1 FM",
        latitude: "35.8681455",
        longitude: "-83.561835",
      },
      {
        market: "Tullahoma",
        station: "W294CV",
        frequency: "106.7 FM",
        latitude: "35.3620235",
        longitude: "-86.2094342",
      },
      {
        market: "Winchester",
        station: "WCDT",
        frequency: "1340 AM",
        latitude: "35.1859163",
        longitude: "-86.1122071",
      },
      {
        market: "Richmond",
        station: "WURV-HD2",
        frequency: "103.7 FM",
        latitude: "37.5407246",
        longitude: "-77.4360481",
      },
      {
        market: "Richmond",
        station: "W291CL",
        frequency: "106.1 FM",
        latitude: "37.5407246",
        longitude: "-77.4360481",
      },
      {
        market: "Roanoke",
        station: "WFJX",
        frequency: "910 AM",
        latitude: "37.2709704",
        longitude: "-79.9414266",
      },
      {
        market: "Roanoke",
        station: "W282CK",
        frequency: "104.3 FM",
        latitude: "37.2709704",
        longitude: "-79.9414266",
      },
    ],
    "Baltimore Orioles": [
      {
        market: "Berkeley Springs",
        station: "WCST",
        frequency: "1010 AM",
        latitude: "39.6269902",
        longitude: "-78.2271957",
      },
      {
        market: "Martinsburg",
        station: "WEPM",
        frequency: "1340 AM",
        latitude: "39.4562099",
        longitude: "-77.9638869",
      },
      {
        market: "Martinsburg",
        station: "W229CM",
        frequency: "93.7 FM",
        latitude: "39.4562099",
        longitude: "-77.9638869",
      },
      {
        market: "Hopewell",
        station: "WHAP",
        frequency: "1340 AM",
        latitude: "37.3043154",
        longitude: "-77.2872001",
      },
      {
        market: "Hopewell",
        station: "W245CV",
        frequency: "96.9 FM",
        latitude: "37.3043154",
        longitude: "-77.2872001",
      },
      {
        market: "Lynchburg",
        station: "WLVA",
        frequency: "580 AM",
        latitude: "37.4137536",
        longitude: "-79.1422464",
      },
      {
        market: "Lynchburg",
        station: "W231CE",
        frequency: "94.1 FM",
        latitude: "37.4137536",
        longitude: "-79.1422464",
      },
      {
        market: "Norfolk",
        station: "WVSP",
        frequency: "94.1 FM",
        latitude: "36.8507689",
        longitude: "-76.2858726",
      },
      {
        market: "Petersburg",
        station: "WTPS",
        frequency: "1240 AM",
        latitude: "37.2279279",
        longitude: "-77.4019267",
      },
      {
        market: "Petersburg",
        station: "W274BX",
        frequency: "102.7 FM",
        latitude: "37.2279279",
        longitude: "-77.4019267",
      },
      {
        market: "Richmond",
        station: "WXGI",
        frequency: "950 AM",
        latitude: "37.5407246",
        longitude: "-77.4360481",
      },
      {
        market: "Richmond",
        station: "W258DC",
        frequency: "99.5 FM",
        latitude: "37.5407246",
        longitude: "-77.4360481",
      },
      {
        market: "Carlisle",
        station: "WIOO",
        frequency: "1000 AM",
        latitude: "40.2010241",
        longitude: "-77.2002745",
      },
      {
        market: "Carlisle",
        station: "W250AP",
        frequency: "97.9 FM",
        latitude: "40.2010241",
        longitude: "-77.2002745",
      },
      {
        market: "Carlisle",
        station: "WHYL",
        frequency: "960 AM",
        latitude: "40.2010241",
        longitude: "-77.2002745",
      },
      {
        market: "Gettysburg",
        station: "WGET",
        frequency: "1320 AM",
        latitude: "39.8309293",
        longitude: "-77.2310955",
      },
      {
        market: "Gettysburg",
        station: "W229DK",
        frequency: "93.7 FM",
        latitude: "39.8309293",
        longitude: "-77.2310955",
      },
      {
        market: "Hanover",
        station: "WHVR",
        frequency: "1280 AM",
        latitude: "39.8006553",
        longitude: "-76.9830358",
      },
      {
        market: "Hanover",
        station: "W237ER",
        frequency: "95.3 FM",
        latitude: "39.8006553",
        longitude: "-76.9830358",
      },
      {
        market: "Shippensburg",
        station: "WRDD",
        frequency: "1480 AM",
        latitude: "40.0506453",
        longitude: "-77.5202647",
      },
      {
        market: "Shippensburg",
        station: "W230AX",
        frequency: "93.9 FM",
        latitude: "40.0506453",
        longitude: "-77.5202647",
      },
      {
        market: "York",
        station: "WSBA",
        frequency: "910 AM",
        latitude: "39.9625984",
        longitude: "-76.727745",
      },
      {
        market: "York",
        station: "W230CQ",
        frequency: "93.9 FM",
        latitude: "39.9625984",
        longitude: "-76.727745",
      },
      {
        market: "York",
        station: "WGLD",
        frequency: "1440 AM",
        latitude: "39.9625984",
        longitude: "-76.727745",
      },
      {
        market: "Greenville",
        station: "WGHB",
        frequency: "1250 AM",
        latitude: "35.612661",
        longitude: "-77.3663538",
      },
      {
        market: "Washington",
        station: "WDLX",
        frequency: "930 AM",
        latitude: "35.5465517",
        longitude: "-77.0521742",
      },
      {
        market: "Annapolis",
        station: "WNAV",
        frequency: "1430 AM",
        latitude: "38.9784453",
        longitude: "-76.4921829",
      },
      {
        market: "Annapolis",
        station: "W260BM",
        frequency: "99.9 FM",
        latitude: "38.9784453",
        longitude: "-76.4921829",
      },
      {
        market: "Baltimore",
        station: "WBAL",
        frequency: "1090 AM",
        latitude: "39.2903848",
        longitude: "-76.6121893",
      },
      {
        market: "Baltimore",
        station: "WIYY",
        frequency: "97.9 FM",
        latitude: "39.2903848",
        longitude: "-76.6121893",
      },
      {
        market: "Baltimore",
        station: "WIYY-HD2",
        frequency: "97.9 HD2 FM",
        latitude: "39.2903848",
        longitude: "-76.6121893",
      },
      {
        market: "Baltimore",
        station: "W268BA",
        frequency: "101.5 FM",
        latitude: "39.2903848",
        longitude: "-76.6121893",
      },
      {
        market: "Cambridge",
        station: "WCEM",
        frequency: "1240 AM",
        latitude: "38.5631744",
        longitude: "-76.0788292",
      },
      {
        market: "Crisfield",
        station: "WBEY",
        frequency: "97.9 FM",
        latitude: "37.9834575",
        longitude: "-75.8538211",
      },
      {
        market: "Cumberland",
        station: "WTBO",
        frequency: "1450 AM",
        latitude: "39.6581424",
        longitude: "-78.928357",
      },
      {
        market: "Cumberland",
        station: "W250CM",
        frequency: "97.9 FM",
        latitude: "39.6581424",
        longitude: "-78.928357",
      },
      {
        market: "Frederick",
        station: "W292FR",
        frequency: "106.3 FM",
        latitude: "39.4142688",
        longitude: "-77.4105409",
      },
      {
        market: "Frederick",
        station: "WWEG-HD4",
        frequency: "106.9 HD4 FM",
        latitude: "39.4142688",
        longitude: "-77.4105409",
      },
      {
        market: "Hagerstown",
        station: "WARK",
        frequency: "1490 AM",
        latitude: "39.6417629",
        longitude: "-77.7199932",
      },
      {
        market: "Hagerstown",
        station: "W244CP",
        frequency: "98.9 FM",
        latitude: "39.6417629",
        longitude: "-77.7199932",
      },
      {
        market: "Pocomoke City",
        station: "WGOP",
        frequency: "540 AM",
        latitude: "38.0756773",
        longitude: "-75.5679805",
      },
      {
        market: "Pocomoke City",
        station: "W293DN",
        frequency: "100.7 FM",
        latitude: "38.0756773",
        longitude: "-75.5679805",
      },
      {
        market: "Salisbury",
        station: "WTGM",
        frequency: "960 AM",
        latitude: "38.3606736",
        longitude: "-75.5993692",
      },
      {
        market: "Westminster",
        station: "WTTR",
        frequency: "1470 AM",
        latitude: "39.5753791",
        longitude: "-76.9958149",
      },
      {
        market: "Westminster",
        station: "W272CX",
        frequency: "102.3 FM",
        latitude: "39.5753791",
        longitude: "-76.9958149",
      },
      {
        market: "Washington, D.C.",
        station: "WSBN",
        frequency: "630 AM",
        latitude: "38.9071923",
        longitude: "-77.0368707",
      },
      {
        market: "Lewes",
        station: "WXDE",
        frequency: "105.9 FM",
        latitude: "38.7745565",
        longitude: "-75.1393498",
      },
      {
        market: "Wilmington",
        station: "WWTX",
        frequency: "1290 AM",
        latitude: "39.744655",
        longitude: "-75.5483909",
      },
    ],
    "Boston Red Sox": [
      {
        market: "Boston",
        latitude: "42.3600825",
        longitude: "-71.0588801",
        station: "WEEI",
        frequency: "93.7 FM",
      },
      {
        market: "Worcester",
        latitude: "42.2625932",
        longitude: "-71.8022934",
        station: "WVEI",
        frequency: "1440 AM",
      },
      {
        market: "Fall River",
        latitude: "41.7014912",
        longitude: "-71.1550451",
        station: "WSAR",
        frequency: "1480 AM",
      },
      {
        market: "Pittsfield",
        latitude: "42.4500845",
        longitude: "-73.2453824",
        station: "WBEC",
        frequency: "1420 AM",
      },
      {
        market: "Springfield",
        latitude: "42.1014831",
        longitude: "-72.589811",
        station: "WWEI",
        frequency: "105.5 FM",
      },
      {
        market: "New Bedford",
        latitude: "41.6362152",
        longitude: "-70.934205",
        station: "WBSM",
        frequency: "1420 AM",
      },
      {
        market: "Milford",
        latitude: "42.1398577",
        longitude: "-71.5163049",
        station: "WMRC",
        frequency: "1490 AM",
      },
      {
        market: "Cape Cod",
        latitude: "41.6687897",
        longitude: "-70.2962408",
        station: "WEII",
        frequency: "96.3 FM",
      },
      {
        market: "Fitchburg",
        latitude: "42.5834228",
        longitude: "-71.8022955",
        station: "WPKZ",
        frequency: "1280 AM",
      },
      {
        market: "North Adams",
        latitude: "42.700915",
        longitude: "-73.1087148",
        station: "WNAW",
        frequency: "1230 AM",
      },
      {
        market: "Worcester",
        latitude: "42.2625932",
        longitude: "-71.8022934",
        station: "WCRN",
        frequency: "830 AM",
      },
      {
        market: "Great Barrington",
        latitude: "42.1959798",
        longitude: "-73.362008",
        station: "WSBS",
        frequency: "860 AM",
      },
      {
        market: "Providence",
        latitude: "41.8239891",
        longitude: "-71.4128343",
        station: "WVEI",
        frequency: "103.7 FM",
      },
      {
        market: "Hartford",
        latitude: "41.7658043",
        longitude: "-72.6733723",
        station: "WTIC",
        frequency: "1080 AM",
      },
      {
        market: "Willmantic",
        latitude: "41.7106543",
        longitude: "-72.2081338",
        station: "WILI",
        frequency: "1400 AM",
      },
      {
        market: "Greenwich",
        latitude: "41.0262417",
        longitude: "-73.6281964",
        station: "WGCH",
        frequency: "1490 AM",
      },
      {
        market: "Putnam",
        latitude: "41.9046012",
        longitude: "-71.8689917",
        station: "WINY",
        frequency: "1350 AM",
      },
      {
        market: "Bangor",
        latitude: "44.8016128",
        longitude: "-68.7712257",
        station: "WEZQ",
        frequency: "92.9 FM",
      },
      {
        market: "Portland",
        latitude: "43.6590993",
        longitude: "-70.2568189",
        station: "WJJB",
        frequency: "96.3 FM",
      },
      {
        market: "Portland",
        latitude: "43.6590993",
        longitude: "-70.2568189",
        station: "WLOB",
        frequency: "1310 AM",
      },
      {
        market: "Ellsworth",
        latitude: "44.5434095",
        longitude: "-68.4194641",
        station: "WDEA",
        frequency: "1370 AM",
      },
      {
        market: "Dover",
        latitude: "45.1833421",
        longitude: "-69.2270821",
        station: "WZLO",
        frequency: "103.1 FM",
      },
      {
        market: "Calais",
        latitude: "45.1889633",
        longitude: "-67.2786038",
        station: "WQDY",
        frequency: "92.7 FM",
      },
      {
        market: "Millinocket",
        latitude: "45.6572639",
        longitude: "-68.7098119",
        station: "WSYY",
        frequency: "1240 AM",
      },
      {
        market: "Presque Isle",
        latitude: "46.681153",
        longitude: "-68.0158615",
        station: "WEGP",
        frequency: "1390 AM",
      },
      {
        market: "Presque Isle",
        latitude: "46.681153",
        longitude: "-68.0158615",
        station: "WOZI",
        frequency: "101.9 FM",
      },
      {
        market: "Farmington",
        latitude: "44.6704998",
        longitude: "-70.1512169",
        station: "WKTJ",
        frequency: "99.3 FM",
      },
      {
        market: "Machias",
        latitude: "44.7150789",
        longitude: "-67.461376",
        station: "WALZ",
        frequency: "95.3 FM",
      },
      {
        market: "Portland",
        latitude: "43.5381993",
        longitude: "-70.4204158",
        station: "WPEI",
        frequency: "95.9 FM",
      },
      {
        market: "Rockland",
        latitude: "44.1036914",
        longitude: "-69.1089293",
        station: "WBYA",
        frequency: "105.5 FM",
      },
      {
        market: "Caribou",
        latitude: "46.8639995",
        longitude: "-67.9980365",
        station: "WCXU",
        frequency: "97.7 FM",
      },
      {
        market: "Plymouth",
        latitude: "43.7570196",
        longitude: "-71.6881528",
        station: "WPNH",
        frequency: "100.1 FM",
      },
      {
        market: "Hanover",
        latitude: "43.7022451",
        longitude: "-72.2895526",
        station: "WTSL",
        frequency: "1400 AM",
      },
      {
        market: "Keene",
        latitude: "42.8982264",
        longitude: "-72.2678947",
        station: "WEEY",
        frequency: "93.5 FM",
      },
      {
        market: "Concord",
        latitude: "43.159672",
        longitude: "-71.9113129",
        station: "WTPL",
        frequency: "107.7 FM",
      },
      {
        market: "New London",
        latitude: "43.4284925",
        longitude: "-71.975699",
        station: "WNTK",
        frequency: "99.7 FM",
      },
      {
        market: "Walpole",
        latitude: "43.0794944",
        longitude: "-72.4258229",
        station: "WFYX",
        frequency: "96.3 FM",
      },
      {
        market: "Meredith",
        latitude: "43.6575331",
        longitude: "-71.500317",
        station: "WZEI",
        frequency: "101.5 FM",
      },
      {
        market: "Newport",
        latitude: "43.3653585",
        longitude: "-72.1734388",
        station: "WCNL",
        frequency: "1010 AM",
      },
      {
        market: "Littleton",
        latitude: "44.3061628",
        longitude: "-71.7701237",
        station: "WLTN",
        frequency: "1400 AM",
      },
      {
        market: "Berlin",
        latitude: "44.4686696",
        longitude: "-71.1850768",
        station: "WMOU",
        frequency: "1230 AM",
      },
      {
        market: "Dover",
        latitude: "43.1978624",
        longitude: "-70.8736698",
        station: "WTSN",
        frequency: "1270 AM",
      },
      {
        market: "Woodstock",
        latitude: "43.6242442",
        longitude: "-72.5185112",
        station: "WWOD",
        frequency: "93.9 FM",
      },
      {
        market: "Burlington",
        latitude: "44.4758825",
        longitude: "-73.212072",
        station: "WEAV",
        frequency: "101.3 FM",
      },
      {
        market: "Burlington",
        latitude: "44.4758825",
        longitude: "-73.212072",
        station: "WEAV",
        frequency: "900 AM",
      },
      {
        market: "St. Johnsbury",
        latitude: "44.4192627",
        longitude: "-72.0151183",
        station: "WSTJ",
        frequency: "1340 AM",
      },
      {
        market: "Rutland",
        latitude: "43.6106237",
        longitude: "-72.9726065",
        station: "WSYB",
        frequency: "1380 AM",
      },
      {
        market: "Newport",
        latitude: "44.9364356",
        longitude: "-72.2051022",
        station: "WIKE",
        frequency: "1490 AM",
      },
      {
        market: "Randolph",
        latitude: "43.9247446",
        longitude: "-72.6656948",
        station: "WCVR",
        frequency: "1320 AM",
      },
      {
        market: "Waterbury",
        latitude: "44.3378343",
        longitude: "-72.756229",
        station: "WDEV",
        frequency: "550 AM",
      },
      {
        market: "Waterbury",
        latitude: "44.3378343",
        longitude: "-72.756229",
        station: "WDEV",
        frequency: "96.1 FM",
      },
      {
        market: "Morrisville",
        latitude: "44.5617189",
        longitude: "-72.5984486",
        station: "WLVB",
        frequency: "93.9 FM",
      },
      {
        market: "Albany",
        latitude: "42.6525793",
        longitude: "-73.7562317",
        station: "WOFX",
        frequency: "980 AM",
      },
      {
        market: "St. Johnsville",
        latitude: "43.0012346",
        longitude: "-74.6781003",
        station: "WKAJ",
        frequency: "1120 AM",
      },
      {
        market: "Fort Myers",
        latitude: "26.640628",
        longitude: "-81.8723084",
        station: "WWCN",
        frequency: "99.3 FM",
      },
    ],
    "Chicago White Sox": [
      {
        latitude: "41.7605849",
        longitude: "-88.3200715",
        market: "Aurora",
        station: "WBIG",
        frequency: "1280 AM",
      },
      {
        latitude: "40.0175483",
        longitude: "-90.4242916",
        market: "Beardstown",
        station: "WVIL",
        frequency: "101.3 FM",
      },
      {
        latitude: "40.4842027",
        longitude: "-88.9936873",
        market: "Bloomington",
        station: "WHOW",
        frequency: "106.5 FM",
      },
      {
        latitude: "37.0053293",
        longitude: "-89.1764608",
        market: "Cairo",
        station: "WKRO",
        frequency: "1490 AM",
      },
      {
        latitude: "37.0053293",
        longitude: "-89.1764608",
        market: "Cairo",
        station: "WKRO",
        frequency: "103.3 FM",
      },
      {
        latitude: "41.8781136",
        longitude: "-87.6297982",
        market: "Chicago",
        station: "ESPN WMVP",
        frequency: "1000 AM",
      },
      {
        latitude: "40.1536479",
        longitude: "-88.9645253",
        market: "Clinton",
        station: "WHOW",
        frequency: "1520 AM",
      },
      {
        latitude: "40.1536479",
        longitude: "-88.9645253",
        market: "Clinton",
        station: "WHOW",
        frequency: "92.3 FM",
      },
      {
        latitude: "41.5236437",
        longitude: "-90.5776367",
        market: "Davenport",
        station: "KBOB",
        frequency: "1170 AM",
      },
      {
        latitude: "41.079273",
        longitude: "-85.1393513",
        market: "Fort Wayne",
        station: "WKJG",
        frequency: "100.9 FM",
      },
      {
        latitude: "41.079273",
        longitude: "-85.1393513",
        market: "Fort Wayne",
        station: "WKJG",
        frequency: "1380 AM",
      },
      {
        latitude: "41.1200325",
        longitude: "-87.8611531",
        market: "Kankakee",
        station: "WYKT",
        frequency: "105.5 FM",
      },
      {
        latitude: "37.723286",
        longitude: "-89.21322",
        market: "Marion",
        station: "WGGH",
        frequency: "1150 AM",
      },
      {
        latitude: "37.723286",
        longitude: "-89.21322",
        market: "Marion",
        station: "WGGH",
        frequency: "98.5 FM",
      },
      {
        latitude: "41.3455892",
        longitude: "-88.8425769",
        market: "Ottawa",
        station: "WCMY",
        frequency: "1430 AM",
      },
      {
        latitude: "41.3455892",
        longitude: "-88.8425769",
        market: "Ottawa",
        station: "WCMY",
        frequency: "93.5 FM",
      },
      {
        latitude: "40.6936488",
        longitude: "-89.5889864",
        market: "Peoria",
        station: "WZPN",
        frequency: "101.1 FM",
      },
      {
        latitude: "40.6936488",
        longitude: "-89.5889864",
        market: "Peoria",
        station: "WZPN",
        frequency: "96.5 FM",
      },
      {
        latitude: "40.9367045",
        longitude: "-87.1508562",
        market: "Rensselear",
        station: "WRIN",
        frequency: "1560 AM",
      },
      {
        latitude: "40.9367045",
        longitude: "-87.1508562",
        market: "Rensselear",
        station: "WRIN",
        frequency: "104.5 FM",
      },
      {
        latitude: "42.2711311",
        longitude: "-89.0939952",
        market: "Rockford",
        station: "WROK",
        frequency: "1440 AM",
      },
      {
        latitude: "39.548935",
        longitude: "-89.294533",
        market: "Taylorville",
        station: "96.5 The Winner",
        frequency: "96.5 FM",
      },
      {
        latitude: "42.4694321",
        longitude: "-93.8159149",
        market: "Webster City",
        station: "KQWC",
        frequency: "95.7 FM",
      },
    ],
    "Chicago Cubs": [
      {
        station: "WSCR\nWBMX-HD2",
        frequency: "670 AM\n104.3 FM",
        market: "Chicago",
        latitude: "41.8781136",
        longitude: "-87.6297982",
      },
      {
        station: "KADR",
        frequency: "1400 AM",
        market: "Elkader",
        latitude: "42.8538739",
        longitude: "-91.4054159",
      },
      {
        station: "KCIM",
        frequency: "1380 AM",
        market: "Carroll",
        latitude: "42.0655986",
        longitude: "-94.8672793",
      },
      {
        station: "KCNZ",
        frequency: "1650 AM\n102.3 FM",
        market: "Cedar Falls",
        latitude: "42.5348993",
        longitude: "-92.4453161",
      },
      {
        station: "KCPS",
        frequency: "1150 AM",
        market: "W. Burlington",
        latitude: "40.9973188",
        longitude: "-92.3105496",
      },
      {
        station: "KNOD",
        frequency: "105.3 FM",
        market: "Harlan",
        latitude: "41.653044",
        longitude: "-95.3255541",
      },
      {
        station: "KIBM\nK293CX",
        frequency: "1490 AM\n106.5 FM",
        market: "Omaha",
        latitude: "41.2565369",
        longitude: "-95.9345034",
      },
      {
        station: "KRNT",
        frequency: "1350 AM",
        market: "Des Moines",
        latitude: "41.5868353",
        longitude: "-93.6249593",
      },
      {
        station: "KVTK",
        frequency: "1570 AM",
        market: "Vermillion",
        latitude: "42.7794417",
        longitude: "-96.9292104",
      },
      {
        station: "KXIC",
        frequency: "800 AM",
        market: "Iowa City",
        latitude: "41.6611277",
        longitude: "-91.5301683",
      },
      {
        station: "KWBG",
        frequency: "1590 AM",
        market: "Boone",
        latitude: "42.0597047",
        longitude: "-93.8802273",
      },
      {
        station: "WAKO",
        frequency: "103.1 FM",
        market: "Lawrenceville",
        latitude: "38.7292137",
        longitude: "-87.6816927",
      },
      {
        station: "WARU",
        frequency: "101.9 FM",
        market: "Peru",
        latitude: "40.7536528",
        longitude: "-86.0688811",
      },
      {
        station: "WASK",
        frequency: "98.7 FM",
        market: "Lafayette",
        latitude: "40.4167022",
        longitude: "-86.8752869",
      },
      {
        station: "WAXI",
        frequency: "104.9 FM",
        market: "Terre Haute",
        latitude: "39.4667034",
        longitude: "-87.4139092",
      },
      {
        station: "WBAT",
        frequency: "1400 AM",
        market: "Marion",
        latitude: "40.5583739",
        longitude: "-85.6591442",
      },
      {
        station: "WBHR",
        frequency: "660 AM",
        market: "St. Cloud",
        latitude: "45.5579451",
        longitude: "-94.1632404",
      },
      {
        station: "WBNL",
        frequency: "1540 AM\n97.7 FM",
        market: "Booneville",
        latitude: "38.0492131",
        longitude: "-87.2741723",
      },
      {
        station: "WDBQ",
        frequency: "1490 AM",
        market: "Dubuque",
        latitude: "42.506348",
        longitude: "-90.6677347",
      },
      {
        station: "WDZ",
        frequency: "1050 AM",
        market: "Decatur",
        latitude: "39.8403147",
        longitude: "-88.9548001",
      },
      {
        station: "WFMB",
        frequency: "1450 AM",
        market: "Springfield",
        latitude: "39.7817213",
        longitude: "-89.6501481",
      },
      {
        station: "WFRX",
        frequency: "1300 AM\n95.5 FM",
        market: "W. Frankfort",
        latitude: "37.8961816",
        longitude: "-88.9501665",
      },
      {
        station: "WGEM",
        frequency: "1440 AM",
        market: "Quincy",
        latitude: "39.9356016",
        longitude: "-91.4098726",
      },
      {
        station: "WGKC",
        frequency: "105.9 FM",
        market: "Champaign",
        latitude: "40.1164204",
        longitude: "-88.2433829",
      },
      {
        station: "WKEI",
        frequency: "1450 AM\n1001.1 FM",
        market: "Kewanee",
        latitude: "41.2455927",
        longitude: "-89.9248303",
      },
      {
        station: "WLDS",
        frequency: "1180 AM",
        market: "Jacksonville",
        latitude: "39.733936",
        longitude: "-90.2290098",
      },
      {
        station: "WRAM",
        frequency: "1330 AM\n94.1 FM",
        market: "Monmouth",
        latitude: "40.9114271",
        longitude: "-90.6473576",
      },
      {
        station: "WRSW",
        frequency: "1480 AM\n99.7 FM",
        market: "Warsaw",
        latitude: "41.2381",
        longitude: "-85.8530469",
      },
      {
        station: "WSDR",
        frequency: "1240 AM\n93.1 FM",
        market: "Sterling",
        latitude: "41.788642",
        longitude: "-89.6962194",
      },
      {
        station: "WTRC",
        frequency: "1340 AM",
        market: "Elkhart",
        latitude: "41.6812083",
        longitude: "-85.9656249",
      },
      {
        station: "WTSO",
        frequency: "1070 AM",
        market: "Madison",
        latitude: "43.0721661",
        longitude: "-89.4007501",
      },
      {
        station: "WZOE",
        frequency: "1490 AM",
        market: "Princeton",
        latitude: "41.3680919",
        longitude: "-89.4648145",
      },
    ],
    "Cincinnati Reds": [
      {
        station: "WLW",
        frequency: "700 AM",
        market: "Cincinnati",
        latitude: "39.1031182",
        longitude: "-84.5120196",
      },
      {
        station: "W233BG",
        frequency: "94.5 FM",
        market: "Cincinnati",
        latitude: "39.1031182",
        longitude: "-84.5120196",
      },
      {
        station: "WATH",
        frequency: "970 AM",
        market: "Athens",
        latitude: "39.3292396",
        longitude: "-82.1012554",
      },
      {
        station: "W246CE",
        frequency: "97.1 FM",
        market: "Athens",
        latitude: "39.3292396",
        longitude: "-82.1012554",
      },
      {
        station: "WRBI",
        frequency: "103.9 FM",
        market: "Batesville",
        latitude: "39.3000511",
        longitude: "-85.222184",
      },
      {
        station: "WKXO",
        frequency: "1500 AM",
        market: "Berea",
        latitude: "37.568694",
        longitude: "-84.2963223",
      },
      {
        station: "W278CC",
        frequency: "103.5 FM",
        market: "Berea",
        latitude: "37.568694",
        longitude: "-84.2963223",
      },
      {
        station: "WGCL[6]",
        frequency: "1370 AM",
        market: "Bloomington",
        latitude: "39.165325",
        longitude: "-86.5263857",
      },
      {
        station: "W241CD[6]",
        frequency: "96.1 FM",
        market: "Bloomington",
        latitude: "39.165325",
        longitude: "-86.5263857",
      },
      {
        station: "WILE",
        frequency: "1270 AM",
        market: "Cambridge",
        latitude: "40.031183",
        longitude: "-81.5884561",
      },
      {
        station: "W300CB",
        frequency: "107.9 FM",
        market: "Cambridge",
        latitude: "40.031183",
        longitude: "-81.5884561",
      },
      {
        station: "WTCO",
        frequency: "1450 AM",
        market: "Campbellsville",
        latitude: "37.3433974",
        longitude: "-85.3419069",
      },
      {
        station: "W294CO",
        frequency: "106.7 FM",
        market: "Campbellsville",
        latitude: "37.3433974",
        longitude: "-85.3419069",
      },
      {
        station: "WKKI",
        frequency: "94.3 FM",
        market: "Celina",
        latitude: "40.5489358",
        longitude: "-84.5702338",
      },
      {
        station: "WCHS",
        frequency: "580 AM",
        market: "Charleston",
        latitude: "38.3498195",
        longitude: "-81.6326234",
      },
      {
        station: "W243DR",
        frequency: "96.5 FM",
        market: "Charleston",
        latitude: "38.3498195",
        longitude: "-81.6326234",
      },
      {
        station: "WBEX",
        frequency: "1490 AM",
        market: "Chillicothe",
        latitude: "39.3331197",
        longitude: "-82.9824019",
      },
      {
        station: "W224BR",
        frequency: "92.7 FM",
        market: "Chillicothe",
        latitude: "39.3331197",
        longitude: "-82.9824019",
      },
      {
        station: "WPWT",
        frequency: "870 AM",
        market: "Colonial Heights",
        latitude: "36.4851024",
        longitude: "-82.5032035",
      },
      {
        station: "W242CQ",
        frequency: "96.3 FM",
        market: "Colonial Heights",
        latitude: "36.4851024",
        longitude: "-82.5032035",
      },
      {
        station: "WAIN",
        frequency: "970 AM",
        market: "Columbia",
        latitude: "37.102841",
        longitude: "-85.3063504",
      },
      {
        station: "W270DI",
        frequency: "101.9 FM",
        market: "Columbia",
        latitude: "37.102841",
        longitude: "-85.3063504",
      },
      {
        station: "WYTS",
        frequency: "1230 AM",
        market: "Columbus",
        latitude: "39.9611755",
        longitude: "-82.9987942",
      },
      {
        station: "W287CP",
        frequency: "105.3 FM",
        market: "Columbus",
        latitude: "39.9611755",
        longitude: "-82.9987942",
      },
      {
        station: "WLPK",
        frequency: "1580 AM",
        market: "Connersville",
        latitude: "39.6411589",
        longitude: "-85.1410748",
      },
      {
        station: "W296BA",
        frequency: "106.9 FM",
        market: "Connersville",
        latitude: "39.6411589",
        longitude: "-85.1410748",
      },
      {
        station: "WCVL",
        frequency: "1550 AM",
        market: "Crawfordsville",
        latitude: "40.0411536",
        longitude: "-86.8744516",
      },
      {
        station: "W221CS",
        frequency: "92.1 FM",
        market: "Crawfordsville",
        latitude: "40.0411536",
        longitude: "-86.8744516",
      },
      {
        station: "W283AQ",
        frequency: "104.5 FM",
        market: "Cross Lanes",
        latitude: "38.4203709",
        longitude: "-81.7906847",
      },
      {
        station: "WCYN",
        frequency: "1400 AM",
        market: "Cynthiana",
        latitude: "38.3903526",
        longitude: "-84.2941013",
      },
      {
        station: "W267CW",
        frequency: "101.3 FM",
        market: "Cynthiana",
        latitude: "38.3903526",
        longitude: "-84.2941013",
      },
      {
        station: "WING",
        frequency: "1410 AM",
        market: "Dayton",
        latitude: "39.7589478",
        longitude: "-84.1916069",
      },
      {
        station: "WIEL",
        frequency: "1400 AM",
        market: "Elizabethtown",
        latitude: "37.7030646",
        longitude: "-85.8649408",
      },
      {
        station: "W291CU",
        frequency: "106.1 FM",
        market: "Elizabethtown",
        latitude: "37.7030646",
        longitude: "-85.8649408",
      },
      {
        station: "WEKB",
        frequency: "1460 AM",
        market: "Elkhorn City",
        latitude: "37.303997",
        longitude: "-82.3509794",
      },
      {
        station: "W282BZ",
        frequency: "104.3 FM",
        market: "Elkhorn City",
        latitude: "37.303997",
        longitude: "-82.3509794",
      },
      {
        station: "WHBE",
        frequency: "105.7 FM",
        market: "Eminence-Frankfort",
        latitude: "38.2009055",
        longitude: "-84.8732835",
      },
      {
        station: "WBVI",
        frequency: "96.7 FM",
        market: "Findlay",
        latitude: "41.04422",
        longitude: "-83.6499321",
      },
      {
        station: "WFOB",
        frequency: "1430 AM",
        market: "Fostoria",
        latitude: "41.156998",
        longitude: "-83.4168702",
      },
      {
        station: "W289CP",
        frequency: "105.7 FM",
        market: "Fostoria",
        latitude: "41.156998",
        longitude: "-83.4168702",
      },
      {
        station: "WCDS",
        frequency: "1230 AM",
        market: "Glasgow",
        latitude: "36.9958839",
        longitude: "-85.9119215",
      },
      {
        station: "W284DH",
        frequency: "104.7 FM",
        market: "Glasgow",
        latitude: "36.9958839",
        longitude: "-85.9119215",
      },
      {
        station: "WGOH",
        frequency: "1370 AM",
        market: "Grayson",
        latitude: "38.3325812",
        longitude: "-82.9485023",
      },
      {
        station: "W265CH",
        frequency: "100.9 FM",
        market: "Grayson",
        latitude: "38.3325812",
        longitude: "-82.9485023",
      },
      {
        station: "WUGO",
        frequency: "99.7 FM",
        market: "Grayson",
        latitude: "38.3325812",
        longitude: "-82.9485023",
      },
      {
        station: "WSRW",
        frequency: "1590 AM",
        market: "Hillsboro",
        latitude: "39.2022866",
        longitude: "-83.611587",
      },
      {
        station: "W268CC",
        frequency: "101.5 FM",
        market: "Hillsboro",
        latitude: "39.2022866",
        longitude: "-83.611587",
      },
      {
        station: "WHOP[7]",
        frequency: "1230 AM",
        market: "Hopkinsville",
        latitude: "36.8656008",
        longitude: "-87.4886186",
      },
      {
        station: "W237BV",
        frequency: "95.3 FM",
        market: "Hopkinsville",
        latitude: "36.8656008",
        longitude: "-87.4886186",
      },
      {
        station: "WVHU",
        frequency: "800 AM",
        market: "Huntington",
        latitude: "38.4192496",
        longitude: "-82.445154",
      },
      {
        station: "WIRO",
        frequency: "1230 AM",
        market: "Ironton",
        latitude: "38.5367471",
        longitude: "-82.6829406",
      },
      {
        station: "WZWB",
        frequency: "1420 AM",
        market: "Kenova-Huntington",
        latitude: "38.3989711",
        longitude: "-82.5782139",
      },
      {
        station: "W264BY",
        frequency: "100.7 FM",
        market: "Kingsport",
        latitude: "36.548434",
        longitude: "-82.5618186",
      },
      {
        station: "WLXG",
        frequency: "1300 AM",
        market: "Lexington",
        latitude: "38.0405837",
        longitude: "-84.5037164",
      },
      {
        station: "W223CV",
        frequency: "92.5 FM",
        market: "Lexington",
        latitude: "38.0405837",
        longitude: "-84.5037164",
      },
      {
        station: "WIMA",
        frequency: "1150 AM",
        market: "Lima",
        latitude: "40.742551",
        longitude: "-84.1052256",
      },
      {
        station: "WVOW",
        frequency: "1290 FM",
        market: "Logan",
        latitude: "37.8487147",
        longitude: "-81.9934581",
      },
      {
        station: "WVOW",
        frequency: "101.9 FM",
        market: "Logan",
        latitude: "37.8487147",
        longitude: "-81.9934581",
      },
      {
        station: "WFTG",
        frequency: "1400 AM",
        market: "London",
        latitude: "37.1289771",
        longitude: "-84.0832646",
      },
      {
        station: "W295CC",
        frequency: "106.9 FM",
        market: "London",
        latitude: "37.1289771",
        longitude: "-84.0832646",
      },
      {
        station: "WHBE",
        frequency: "680 AM",
        market: "Louisville",
        latitude: "38.2526647",
        longitude: "-85.7584557",
      },
      {
        station: "WXGO",
        frequency: "1270 AM",
        market: "Madison",
        latitude: "38.7358933",
        longitude: "-85.3799577",
      },
      {
        station: "WMOA",
        frequency: "1490 AM",
        market: "Marietta",
        latitude: "39.415352",
        longitude: "-81.4548436",
      },
      {
        station: "W267CQ",
        frequency: "101.3 FM",
        market: "Marietta",
        latitude: "39.415352",
        longitude: "-81.4548436",
      },
      {
        station: "WMDJ",
        frequency: "100.1 FM",
        market: "Martin",
        latitude: "37.5728764",
        longitude: "-82.752659",
      },
      {
        station: "WDLR",
        frequency: "1270 AM",
        market: "Marysville",
        latitude: "40.2364486",
        longitude: "-83.3671432",
      },
      {
        station: "W244DV",
        frequency: "96.7 FM",
        market: "Marysville",
        latitude: "40.2364486",
        longitude: "-83.3671432",
      },
      {
        station: "WTIG",
        frequency: "990 AM",
        market: "Massillon",
        latitude: "40.7967244",
        longitude: "-81.5215093",
      },
      {
        station: "WFTM",
        frequency: "1240 AM",
        market: "Maysville",
        latitude: "38.6411854",
        longitude: "-83.744365",
      },
      {
        station: "WZZW",
        frequency: "1600 AM",
        market: "Milton",
        latitude: "38.4345321",
        longitude: "-82.1323637",
      },
      {
        station: "W267CP",
        frequency: "101.3 FM",
        market: "Montgomery",
        latitude: "38.6625493",
        longitude: "-87.0461181",
      },
      {
        station: "WMOR",
        frequency: "106.1 FM",
        market: "Morehead-West Liberty",
        latitude: "37.8993476",
        longitude: "-83.2899343",
      },
      {
        station: "WMUN",
        frequency: "1340 AM",
        market: "Muncie",
        latitude: "40.1933767",
        longitude: "-85.3863599",
      },
      {
        station: "W275AV",
        frequency: "102.9 FM",
        market: "Muncie",
        latitude: "40.1933767",
        longitude: "-85.3863599",
      },
      {
        station: "WLOC",
        frequency: "1150 AM",
        market: "Munfordville",
        latitude: "37.2722751",
        longitude: "-85.8910819",
      },
      {
        station: "W269DD",
        frequency: "101.7 FM",
        market: "Munfordville",
        latitude: "37.2722751",
        longitude: "-85.8910819",
      },
      {
        station: "WNOS",
        frequency: "1450 AM",
        market: "New Bern",
        latitude: "35.108493",
        longitude: "-77.0441143",
      },
      {
        station: "W280ED",
        frequency: "103.9 FM",
        market: "New Bern",
        latitude: "35.108493",
        longitude: "-77.0441143",
      },
      {
        station: "WKYH",
        frequency: "600 AM",
        market: "Paintsville",
        latitude: "37.8145384",
        longitude: "-82.8071054",
      },
      {
        station: "W257EE",
        frequency: "99.3 FM",
        market: "Paintsville",
        latitude: "37.8145384",
        longitude: "-82.8071054",
      },
      {
        station: "WLSI",
        frequency: "900 AM",
        market: "Pikeville",
        latitude: "37.4792672",
        longitude: "-82.5187629",
      },
      {
        station: "WPKE",
        frequency: "1240 AM",
        market: "Pikeville",
        latitude: "37.4792672",
        longitude: "-82.5187629",
      },
      {
        station: "W240CL",
        frequency: "95.9 FM",
        market: "Pikeville",
        latitude: "37.4792672",
        longitude: "-82.5187629",
      },
      {
        station: "W251AI",
        frequency: "98.1 FM",
        market: "Pikeville",
        latitude: "37.4792672",
        longitude: "-82.5187629",
      },
      {
        station: "WWKU[7]",
        frequency: "1450 AM",
        market: "Plum Springs-Bowling Green",
        latitude: "37.0183763",
        longitude: "-86.3813774",
      },
      {
        station: "W223DL",
        frequency: "92.5 FM",
        market: "Plum Springs-Bowling Green",
        latitude: "37.0183763",
        longitude: "-86.3813774",
      },
      {
        station: "WPGW",
        frequency: "1440 AM",
        market: "Portland",
        latitude: "40.4344895",
        longitude: "-84.9777455",
      },
      {
        station: "WPGW",
        frequency: "100.9 FM",
        market: "Portland",
        latitude: "40.4344895",
        longitude: "-84.9777455",
      },
      {
        station: "W287BC",
        frequency: "105.3 FM",
        market: "Portland",
        latitude: "40.4344895",
        longitude: "-84.9777455",
      },
      {
        station: "WNXT",
        frequency: "1260 AM",
        market: "Portsmouth",
        latitude: "38.7317431",
        longitude: "-82.9976742",
      },
      {
        station: "W239CQ",
        frequency: "95.7 FM",
        market: "Portsmouth",
        latitude: "38.7317431",
        longitude: "-82.9976742",
      },
      {
        station: "WSJD",
        frequency: "100.5 FM",
        market: "Princeton",
        latitude: "38.3553236",
        longitude: "-87.5675222",
      },
      {
        station: "WMOV",
        frequency: "1360 AM",
        market: "Ravenswood",
        latitude: "38.9481366",
        longitude: "-81.7609646",
      },
      {
        station: "W228DJ",
        frequency: "93.5 FM",
        market: "Ravenswood",
        latitude: "38.9481366",
        longitude: "-81.7609646",
      },
      {
        station: "WKBV",
        frequency: "1490 AM",
        market: "Richmond",
        latitude: "39.8289369",
        longitude: "-84.8902382",
      },
      {
        station: "W265DN",
        frequency: "100.9 FM",
        market: "Richmond",
        latitude: "39.8289369",
        longitude: "-84.8902382",
      },
      {
        station: "WEKY",
        frequency: "1340 AM",
        market: "Richmond",
        latitude: "37.7478572",
        longitude: "-84.2946539",
      },
      {
        station: "W223CU",
        frequency: "92.5 FM",
        market: "Richmond",
        latitude: "37.7478572",
        longitude: "-84.2946539",
      },
      {
        station: "W294AF",
        frequency: "106.7 FM",
        market: "Ripley",
        latitude: "38.8186957",
        longitude: "-81.7106851",
      },
      {
        station: "WSLM",
        frequency: "1220 AM",
        market: "Salem",
        latitude: "38.6056131",
        longitude: "-86.101089",
      },
      {
        station: "WSLM",
        frequency: "97.9 FM",
        market: "Salem",
        latitude: "38.6056131",
        longitude: "-86.101089",
      },
      {
        station: "WMVR",
        frequency: "105.5 FM",
        market: "Sidney",
        latitude: "40.2842164",
        longitude: "-84.1554987",
      },
      {
        station: "WSFC",
        frequency: "1240 AM",
        market: "Somerset",
        latitude: "37.0920222",
        longitude: "-84.6041084",
      },
      {
        station: "WTLO",
        frequency: "1480 AM",
        market: "Somerset",
        latitude: "37.0920222",
        longitude: "-84.6041084",
      },
      {
        station: "WNDI",
        frequency: "1550 FM",
        market: "Sullivan",
        latitude: "39.0953199",
        longitude: "-87.4058525",
      },
      {
        station: "WNDI",
        frequency: "95.3 FM",
        market: "Sullivan",
        latitude: "39.0953199",
        longitude: "-87.4058525",
      },
      {
        station: "WAMW",
        frequency: "1580 AM",
        market: "Washington",
        latitude: "38.6592152",
        longitude: "-87.172789",
      },
      {
        station: "W240CE",
        frequency: "95.9 FM",
        market: "Washington",
        latitude: "38.6592152",
        longitude: "-87.172789",
      },
      {
        station: "WCHO",
        frequency: "1250 AM",
        market: "Washington Court House",
        latitude: "39.5373688",
        longitude: "-83.437579",
      },
      {
        station: "WTCW",
        frequency: "920 AM",
        market: "Whitesburg",
        latitude: "37.1184318",
        longitude: "-82.8268265",
      },
      {
        station: "W273BC",
        frequency: "102.5 FM",
        market: "Whitesburg",
        latitude: "37.1184318",
        longitude: "-82.8268265",
      },
      {
        station: "WBTH",
        frequency: "1400 AM",
        market: "Williamson",
        latitude: "37.6742682",
        longitude: "-82.2773629",
      },
      {
        station: "W273BC",
        frequency: "102.5 FM",
        market: "Williamson",
        latitude: "37.6742682",
        longitude: "-82.2773629",
      },
      {
        station: "WHIZ",
        frequency: "1240 AM",
        market: "Zanesville",
        latitude: "39.9403453",
        longitude: "-82.0131924",
      },
      {
        station: "W253CF",
        frequency: "98.5 FM",
        market: "Zanesville",
        latitude: "39.9403453",
        longitude: "-82.0131924",
      },
      {
        station: "W272EE",
        frequency: "102.3 FM",
        market: "Zanesville",
        latitude: "39.9403453",
        longitude: "-82.0131924",
      },
    ],
    "Cleveland Guardians": [
      {
        station: "WTAM",
        frequency: "1100 AM",
        market: "Cleveland",
        latitude: "41.49932",
        longitude: "-81.6943605",
      },
      {
        station: "WMMS",
        frequency: "100.7 FM",
        market: "Cleveland",
        latitude: "41.49932",
        longitude: "-81.6943605",
      },
      {
        station: "W295DE",
        frequency: "106.9 FM",
        market: "Cleveland",
        latitude: "41.49932",
        longitude: "-81.6943605",
      },
      {
        station: "WAKR",
        frequency: "1590 AM",
        market: "Akron",
        latitude: "41.0814447",
        longitude: "-81.5190053",
      },
      {
        station: "W228EL",
        frequency: "93.5 FM",
        market: "Akron",
        latitude: "41.0814447",
        longitude: "-81.5190053",
      },
      {
        station: "WFUN",
        frequency: "970 AM",
        market: "Ashtabula",
        latitude: "41.8650534",
        longitude: "-80.7898089",
      },
      {
        station: "WBNV",
        frequency: "93.5 FM",
        market: "Barnesville",
        latitude: "39.9881261",
        longitude: "-81.1764961",
      },
      {
        station: "WBLL",
        frequency: "1390 AM",
        market: "Bellefontaine",
        latitude: "40.3611643",
        longitude: "-83.7596557",
      },
      {
        station: "WBNO",
        frequency: "100.9 FM",
        market: "Bryan",
        latitude: "41.4747732",
        longitude: "-84.5524508",
      },
      {
        station: "WQEL",
        frequency: "92.7 FM",
        market: "Bucyrus",
        latitude: "40.8083909",
        longitude: "-82.9754649",
      },
      {
        station: "WHBC",
        frequency: "1480 AM",
        market: "Canton",
        latitude: "40.7989473",
        longitude: "-81.378447",
      },
      {
        station: "WTOH-HD2*",
        frequency: "98.9-2 FM",
        market: "Columbus",
        latitude: "39.9611755",
        longitude: "-82.9987942",
      },
      {
        station: "W240CX",
        frequency: "95.9 FM",
        market: "Columbus",
        latitude: "39.9611755",
        longitude: "-82.9987942",
      },
      {
        station: "WJER",
        frequency: "1450 AM",
        market: "Dover\u2013New Phila.",
        latitude: "40.4897871",
        longitude: "-81.4456706",
      },
      {
        station: "W265DL",
        frequency: "100.9 FM",
        market: "Dover\u2013New Phila.",
        latitude: "40.4897871",
        longitude: "-81.4456706",
      },
      {
        station: "WEOL",
        frequency: "930 AM",
        market: "Elyria",
        latitude: "41.3683798",
        longitude: "-82.1076486",
      },
      {
        station: "W262DM",
        frequency: "100.3 FM",
        market: "Elyria",
        latitude: "41.3683798",
        longitude: "-82.1076486",
      },
      {
        station: "WRIE",
        frequency: "1260 AM",
        market: "Erie",
        latitude: "42.1292241",
        longitude: "-80.085059",
      },
      {
        station: "W242CU",
        frequency: "96.3 FM",
        market: "Erie",
        latitude: "42.1292241",
        longitude: "-80.085059",
      },
      {
        station: "WFIN",
        frequency: "1330 AM",
        market: "Findlay",
        latitude: "41.04422",
        longitude: "-83.6499321",
      },
      {
        station: "W238CX",
        frequency: "95.5 FM",
        market: "Findlay",
        latitude: "41.04422",
        longitude: "-83.6499321",
      },
      {
        station: "WFOB",
        frequency: "1430 AM",
        market: "Fostoria",
        latitude: "41.156998",
        longitude: "-83.4168702",
      },
      {
        station: "W289CP",
        frequency: "105.7 FM",
        market: "Fostoria",
        latitude: "41.156998",
        longitude: "-83.4168702",
      },
      {
        station: "WMAN",
        frequency: "98.3 FM",
        market: "Fredericktown",
        latitude: "40.4810991",
        longitude: "-82.543402",
      },
      {
        station: "WKSN",
        frequency: "1340 AM",
        market: "Jamestown",
        latitude: "42.0970023",
        longitude: "-79.2353259",
      },
      {
        station: "WWSR",
        frequency: "93.1 FM",
        market: "Lima",
        latitude: "40.742551",
        longitude: "-84.1052256",
      },
      {
        station: "WMAN",
        frequency: "1400 AM",
        market: "Mansfield",
        latitude: "40.75839",
        longitude: "-82.5154471",
      },
      {
        station: "WMRN",
        frequency: "1490 AM",
        market: "Marion",
        latitude: "40.5886699",
        longitude: "-83.1285242",
      },
      {
        station: "WJAW",
        frequency: "100.9 FM",
        market: "Mcconnelsville",
        latitude: "39.6486822",
        longitude: "-81.8531912",
      },
      {
        station: "WMVO",
        frequency: "1300 AM",
        market: "Mount Vernon",
        latitude: "40.3932283",
        longitude: "-82.485436",
      },
      {
        station: "W265DJ",
        frequency: "100.9 FM",
        market: "Mount Vernon",
        latitude: "40.3932283",
        longitude: "-82.485436",
      },
      {
        station: "WLKR",
        frequency: "95.3 FM",
        market: "Norwalk",
        latitude: "41.2425531",
        longitude: "-82.6157331",
      },
      {
        station: "WLEC",
        frequency: "1450 AM",
        market: "Sandusky",
        latitude: "41.4561754",
        longitude: "-82.7116821",
      },
      {
        station: "W228EN",
        frequency: "93.5 FM",
        market: "Sandusky",
        latitude: "41.4561754",
        longitude: "-82.7116821",
      },
      {
        station: "WSPD",
        frequency: "1370 AM",
        market: "Toledo",
        latitude: "41.6528052",
        longitude: "-83.5378674",
      },
      {
        station: "W225AM",
        frequency: "92.9 FM",
        market: "Toledo",
        latitude: "41.6528052",
        longitude: "-83.5378674",
      },
      {
        station: "W295CI",
        frequency: "106.9 FM",
        market: "Urbana",
        latitude: "40.1083912",
        longitude: "-83.7524298",
      },
      {
        station: "WBTC",
        frequency: "1540 AM",
        market: "Uhrichsville",
        latitude: "40.3931218",
        longitude: "-81.3464998",
      },
      {
        station: "W270CI",
        frequency: "101.9 FM",
        market: "Uhrichsville",
        latitude: "40.3931218",
        longitude: "-81.3464998",
      },
      {
        station: "WQKT",
        frequency: "104.5 FM",
        market: "Wooster",
        latitude: "40.8050565",
        longitude: "-81.935143",
      },
      {
        station: "WKBN",
        frequency: "570 AM",
        market: "Youngstown",
        latitude: "41.0997803",
        longitude: "-80.6495194",
      },
    ],
    "Colorado Rockies": [
      {
        station: "KOA",
        frequency: "850 AM",
        market: "Denver",
        latitude: "39.7392358",
        longitude: "-104.990251",
      },
      {
        station: "KNFO",
        frequency: "106.1 FM",
        market: "Aspen",
        latitude: "39.1910983",
        longitude: "-106.8175387",
      },
      {
        station: "KIUP",
        frequency: "930 AM",
        market: "Durango",
        latitude: "37.27528",
        longitude: "-107.8800667",
      },
      {
        station: "KSIR",
        frequency: "1010 AM",
        market: "Fort Morgan",
        latitude: "40.2502582",
        longitude: "-103.7999509",
      },
      {
        station: "KRKY",
        frequency: "930 AM\n101.9 FM",
        market: "Granby",
        latitude: "40.086097",
        longitude: "-105.9394597",
      },
      {
        station: "KTMM",
        frequency: "1340 AM",
        market: "Grand Junction",
        latitude: "39.0638705",
        longitude: "-108.5506486",
      },
      {
        station: "KDZA",
        frequency: "1350 AM",
        market: "Pueblo",
        latitude: "38.2544472",
        longitude: "-104.6091409",
      },
      {
        station: "KQZR",
        frequency: "95.5 FM\n107.3 FM",
        market: "Steamboat Springs",
        latitude: "40.4849769",
        longitude: "-106.8317158",
      },
      {
        station: "KRFD",
        frequency: "94.5 FM",
        market: "Sterling",
        latitude: "40.6255415",
        longitude: "-103.207709",
      },
      {
        station: "KSKE",
        frequency: "101.7 FM",
        market: "Vail",
        latitude: "39.6433243",
        longitude: "-106.3780801",
      },
      {
        station: "KSPK",
        frequency: "102.3 FM",
        market: "Walsenburg",
        latitude: "37.6227357",
        longitude: "-104.780403",
      },
      {
        station: "KRDZ",
        frequency: "1440 AM",
        market: "Wray",
        latitude: "40.0758231",
        longitude: "-102.2232495",
      },
      {
        station: "KLOE",
        frequency: "730 AM",
        market: "Goodland",
        latitude: "39.350833",
        longitude: "-101.7101722",
      },
      {
        station: "KCSR",
        frequency: "610 AM",
        market: "Chadron",
        latitude: "42.8294187",
        longitude: "-102.9999069",
      },
      {
        station: "KEAU",
        frequency: "1240 AM",
        market: "Elko",
        latitude: "40.8324211",
        longitude: "-115.7631232",
      },
      {
        station: "KLMX",
        frequency: "1450 AM",
        market: "Clayton",
        latitude: "36.451693",
        longitude: "-103.1841039",
      },
      {
        station: "KBFS",
        frequency: "1450 AM",
        market: "Belle Fourche",
        latitude: "44.6713733",
        longitude: "-103.852148",
      },
      {
        station: "KNIT",
        frequency: "1320 AM",
        market: "Salt Lake City",
        latitude: "40.7607793",
        longitude: "-111.8910474",
      },
      {
        station: "KFBC",
        frequency: "1240 AM",
        market: "Cheyenne",
        latitude: "41.1399814",
        longitude: "-104.8202462",
      },
      {
        station: "KKTY",
        frequency: "1470 AM",
        market: "Douglas",
        latitude: "42.7596897",
        longitude: "-105.3822069",
      },
      {
        station: "KOWB",
        frequency: "1290 AM",
        market: "Laramie",
        latitude: "41.3113669",
        longitude: "-105.5911007",
      },
      {
        station: "KVOW",
        frequency: "1450 AM",
        market: "Lost Cabin",
        latitude: "43.2863485",
        longitude: "-107.632578",
      },
      {
        station: "KPOW",
        frequency: "1260 AM",
        market: "Powell",
        latitude: "44.7538408",
        longitude: "-108.7573524",
      },
      {
        station: "KYDT",
        frequency: "103.1 FM",
        market: "Sundance",
        latitude: "44.4063746",
        longitude: "-104.3757816",
      },
    ],
    "Detroit Tigers": [
      {
        station: "WXYT",
        frequency: "97.1 FM",
        market: "Detroit",
        latitude: "42.331427",
        longitude: "-83.0457538",
      },
      {
        station: "WXYT",
        frequency: "1270 AM",
        market: "Detroit",
        latitude: "42.331427",
        longitude: "-83.0457538",
      },
      {
        station: "WQBX",
        frequency: "104.9 FM",
        market: "Alma",
        latitude: "43.3789694",
        longitude: "-84.6602354",
      },
      {
        station: "WATZ",
        frequency: "99.3 FM",
        market: "Alpena",
        latitude: "45.0616794",
        longitude: "-83.4327528",
      },
      {
        station: "WLKI",
        frequency: "100.3 FM",
        market: "Angola",
        latitude: "41.6347726",
        longitude: "-84.9994088",
      },
      {
        station: "WTKA",
        frequency: "1050 AM",
        market: "Ann Arbor",
        latitude: "42.2808256",
        longitude: "-83.7430378",
      },
      {
        station: "W292FW",
        frequency: "106.3 FM",
        market: "Ann Arbor",
        latitude: "42.2808256",
        longitude: "-83.7430378",
      },
      {
        station: "WMTR",
        frequency: "96.1 FM",
        market: "Archbold",
        latitude: "41.5214401",
        longitude: "-84.307172",
      },
      {
        station: "WBCK",
        frequency: "95.3 FM",
        market: "Battle Creek",
        latitude: "42.3211522",
        longitude: "-85.1797142",
      },
      {
        station: "WBRN",
        frequency: "1460 AM",
        market: "Big Rapids",
        latitude: "43.6980782",
        longitude: "-85.4836557",
      },
      {
        station: "W243EN",
        frequency: "96.5 FM",
        market: "Big Rapids",
        latitude: "43.6980782",
        longitude: "-85.4836557",
      },
      {
        station: "W256CC",
        frequency: "99.1 FM",
        market: "Big Rapids",
        latitude: "43.6980782",
        longitude: "-85.4836557",
      },
      {
        station: "WKAD",
        frequency: "93.7 FM",
        market: "Cadillac",
        latitude: "44.2519526",
        longitude: "-85.4011619",
      },
      {
        station: "WKYO",
        frequency: "1360 AM",
        market: "Caro",
        latitude: "43.4911322",
        longitude: "-83.396897",
      },
      {
        station: "WMKT",
        frequency: "1270 AM",
        market: "Charlevoix",
        latitude: "45.3180632",
        longitude: "-85.2584004",
      },
      {
        station: "W272CR",
        frequency: "102.3 FM",
        market: "Charlevoix",
        latitude: "45.3180632",
        longitude: "-85.2584004",
      },
      {
        station: "WCHY",
        frequency: "95.3 FM",
        market: "Cheboygan",
        latitude: "45.6469563",
        longitude: "-84.4744795",
      },
      {
        station: "WDBC",
        frequency: "680 AM",
        market: "Escanaba",
        latitude: "45.7452466",
        longitude: "-87.0645801",
      },
      {
        station: "W233CT",
        frequency: "94.5 FM",
        market: "Escanaba",
        latitude: "45.7452466",
        longitude: "-87.0645801",
      },
      {
        station: "W243EO",
        frequency: "96.5 FM",
        market: "Frankenmuth",
        latitude: "43.331691",
        longitude: "-83.7380194",
      },
      {
        station: "WBNZ",
        frequency: "92.3 FM",
        market: "Frankfort",
        latitude: "44.6336096",
        longitude: "-86.2345396",
      },
      {
        station: "W280GB",
        frequency: "103.9 FM",
        market: "Gladstone",
        latitude: "45.8527435",
        longitude: "-87.0218049",
      },
      {
        station: "WGHN",
        frequency: "1370 AM",
        market: "Grand Haven",
        latitude: "43.0630734",
        longitude: "-86.2283864",
      },
      {
        station: "W235CM",
        frequency: "94.9 FM",
        market: "Grand Haven",
        latitude: "43.0630734",
        longitude: "-86.2283864",
      },
      {
        station: "WOOD",
        frequency: "1300 AM",
        market: "Grand Rapids",
        latitude: "42.9633599",
        longitude: "-85.6680863",
      },
      {
        station: "WMAX",
        frequency: "96.1 FM",
        market: "Grand Rapids",
        latitude: "42.9633599",
        longitude: "-85.6680863",
      },
      {
        station: "WCSR",
        frequency: "1340 AM",
        market: "Hillsdale",
        latitude: "41.9200472",
        longitude: "-84.6305101",
      },
      {
        station: "WCSR",
        frequency: "92.1 FM",
        market: "Hillsdale",
        latitude: "41.9200472",
        longitude: "-84.6305101",
      },
      {
        station: "W258DE",
        frequency: "99.5 FM",
        market: "Hillsdale",
        latitude: "41.9200472",
        longitude: "-84.6305101",
      },
      {
        station: "WHTC",
        frequency: "1450 AM",
        market: "Holland",
        latitude: "42.7875235",
        longitude: "-86.1089301",
      },
      {
        station: "W259CO",
        frequency: "99.7 FM",
        market: "Holland",
        latitude: "42.7875235",
        longitude: "-86.1089301",
      },
      {
        station: "WCCY",
        frequency: "1400 AM",
        market: "Houghton",
        latitude: "47.1211331",
        longitude: "-88.5694182",
      },
      {
        station: "W257CZ",
        frequency: "99.3 FM",
        market: "Houghton",
        latitude: "47.1211331",
        longitude: "-88.5694182",
      },
      {
        station: "WMIQ",
        frequency: "1450 AM",
        market: "Iron Mountain",
        latitude: "45.8202334",
        longitude: "-88.0659603",
      },
      {
        station: "WKHM-HD2*",
        frequency: "105.3-2 FM",
        market: "Jackson",
        latitude: "42.245869",
        longitude: "-84.4013462",
      },
      {
        station: "W270CJ",
        frequency: "101.9 FM",
        market: "Jackson",
        latitude: "42.245869",
        longitude: "-84.4013462",
      },
      {
        station: "WKZO",
        frequency: "590 AM",
        market: "Kalamazoo",
        latitude: "42.2917069",
        longitude: "-85.5872286",
      },
      {
        station: "W295CL",
        frequency: "106.9 FM",
        market: "Kalamazoo",
        latitude: "42.2917069",
        longitude: "-85.5872286",
      },
      {
        station: "WJIM",
        frequency: "1240 AM",
        market: "Lansing",
        latitude: "42.732535",
        longitude: "-84.5555347",
      },
      {
        station: "W299CR",
        frequency: "107.7 FM",
        market: "Lansing",
        latitude: "42.732535",
        longitude: "-84.5555347",
      },
      {
        station: "WLDN",
        frequency: "98.7 FM",
        market: "Ludington",
        latitude: "43.9552825",
        longitude: "-86.4525831",
      },
      {
        station: "WMLQ",
        frequency: "97.7 FM",
        market: "Manistee",
        latitude: "44.2444473",
        longitude: "-86.324253",
      },
      {
        station: "WDMJ",
        frequency: "1320 AM",
        market: "Marquette",
        latitude: "46.5436199",
        longitude: "-87.3953713",
      },
      {
        station: "WKBZ",
        frequency: "1090 AM",
        market: "Muskegon",
        latitude: "43.2341813",
        longitude: "-86.2483921",
      },
      {
        station: "WOOD",
        frequency: "106.9 FM",
        market: "Muskegon",
        latitude: "43.2341813",
        longitude: "-86.2483921",
      },
      {
        station: "WNBY",
        frequency: "1450 AM",
        market: "Newberry",
        latitude: "46.3549984",
        longitude: "-85.5095586",
      },
      {
        station: "WWTH",
        frequency: "100.7 FM",
        market: "Oscoda",
        latitude: "44.4202888",
        longitude: "-83.3307974",
      },
      {
        station: "WMBN",
        frequency: "1340 AM",
        market: "Petoskey",
        latitude: "45.3733428",
        longitude: "-84.9553296",
      },
      {
        station: "W284DF",
        frequency: "104.7 FM",
        market: "Petoskey",
        latitude: "45.3733428",
        longitude: "-84.9553296",
      },
      {
        station: "WPHM",
        frequency: "1380 AM",
        market: "Port Huron",
        latitude: "42.9708634",
        longitude: "-82.4249142",
      },
      {
        station: "WRGZ",
        frequency: "96.7 FM",
        market: "Rogers City",
        latitude: "45.4214024",
        longitude: "-83.8183303",
      },
      {
        station: "WGRY",
        frequency: "101.1 FM",
        market: "Roscommon",
        latitude: "44.4983504",
        longitude: "-84.5919682",
      },
      {
        station: "WSGW",
        frequency: "790 AM",
        market: "Saginaw",
        latitude: "43.4194699",
        longitude: "-83.9508068",
      },
      {
        station: "WTGV",
        frequency: "97.7 FM",
        market: "Sandusky",
        latitude: "43.4202989",
        longitude: "-82.8296572",
      },
      {
        station: "WSOO",
        frequency: "1230 AM",
        market: "Sault Ste. Marie",
        latitude: "46.4977115",
        longitude: "-84.3475876",
      },
      {
        station: "WCSY",
        frequency: "103.7 FM",
        market: "South Haven",
        latitude: "42.4030865",
        longitude: "-86.2736407",
      },
      {
        station: "WBET",
        frequency: "1230 AM",
        market: "Sturgis",
        latitude: "41.799217",
        longitude: "-85.4191482",
      },
      {
        station: "W259CR",
        frequency: "99.7 FM",
        market: "Sturgis",
        latitude: "41.799217",
        longitude: "-85.4191482",
      },
      {
        station: "WSJM",
        frequency: "94.9 FM",
        market: "St. Joseph",
        latitude: "42.0938577",
        longitude: "-86.4895463",
      },
      {
        station: "WCCW",
        frequency: "1310 AM",
        market: "Traverse City",
        latitude: "44.7630567",
        longitude: "-85.6206317",
      },
      {
        station: "WCCW",
        frequency: "107.5 FM",
        market: "Traverse City",
        latitude: "44.7630567",
        longitude: "-85.6206317",
      },
      {
        station: "WGFN",
        frequency: "98.1 FM",
        market: "Traverse City",
        latitude: "44.7630567",
        longitude: "-85.6206317",
      },
    ],
    "Houston Astros": [
      {
        station: "KBME",
        frequency: "790 AM",
        latitude: "29.7604267",
        longitude: "-95.3698028",
        market: "Houston-Galveston",
      },
      {
        station: "KTRH",
        frequency: "740 AM",
        latitude: "29.7604267",
        longitude: "-95.3698028",
        market: "Houston-Galveston",
      },
      {
        station: "KEYH",
        frequency: "850 AM",
        latitude: "29.7604267",
        longitude: "-95.3698028",
        market: "Houston-Galveston",
      },
      {
        station: "KNTE",
        frequency: "101.7 FM",
        latitude: "29.7604267",
        longitude: "-95.3698028",
        market: "Houston-Galveston",
      },
      {
        station: "KTLT",
        frequency: "98.1 FM",
        latitude: "32.7565042",
        longitude: "-99.896207",
        market: "Abilene",
      },
      {
        station: "K248CU",
        frequency: "97.5 FM",
        latitude: "30.267153",
        longitude: "-97.7430608",
        market: "Austin",
      },
      {
        station: "WUBR",
        frequency: "910 AM",
        latitude: "30.4514677",
        longitude: "-91.1871466",
        market: "Baton Rouge",
      },
      {
        station: "KBYG",
        frequency: "1400 AM",
        latitude: "32.2503979",
        longitude: "-101.4787355",
        market: "Big Spring-Snyder",
      },
      {
        station: "K292FE",
        frequency: "106.3 FM",
        latitude: "32.2503979",
        longitude: "-101.4787355",
        market: "Big Spring-Snyder",
      },
      {
        station: "KWHI",
        frequency: "1280 AM",
        latitude: "30.1668828",
        longitude: "-96.3977442",
        market: "Brenham",
      },
      {
        station: "KLWB",
        frequency: "103.7 FM",
        latitude: "30.317144",
        longitude: "-92.0490096",
        market: "Lafayette",
      },
      {
        station: "KCON",
        frequency: "92.7 FM",
        latitude: "35.0917506",
        longitude: "-92.4366522",
        market: "Central Arkansas",
      },
      {
        station: "KGAS",
        frequency: "1590 AM",
        latitude: "32.1573841",
        longitude: "-94.3374199",
        market: "Carthage",
      },
      {
        station: "KULM",
        frequency: "98.3 FM",
        latitude: "29.7066232",
        longitude: "-96.5396933",
        market: "Columbus",
      },
      {
        station: "KSIX",
        frequency: "1230 AM",
        latitude: "27.8005828",
        longitude: "-97.396381",
        market: "Corpus Christi",
      },
      {
        station: "KQBU",
        frequency: "920 AM",
        latitude: "31.7618778",
        longitude: "-106.4850217",
        market: "El Paso",
      },
      {
        station: "KNAF",
        frequency: "910 AM",
        latitude: "30.2752011",
        longitude: "-98.8719843",
        market: "Fredericksburg",
      },
      {
        station: "KTWL",
        frequency: "105.3 FM",
        latitude: "30.0974405",
        longitude: "-96.0782918",
        market: "Houston-Galveston",
      },
      {
        station: "KWRD",
        frequency: "1470 AM",
        latitude: "32.1532156",
        longitude: "-94.7993802",
        market: "Tyler-Longview",
      },
      {
        station: "KETX",
        frequency: "1440 AM",
        latitude: "30.7110216",
        longitude: "-94.9330202",
        market: "Houston-Galveston",
      },
      {
        station: "KMHT",
        frequency: "1450 AM",
        latitude: "32.5448714",
        longitude: "-94.3674184",
        market: "Tyler-Longview",
      },
      {
        station: "KMHT",
        frequency: "103.9 FM",
        latitude: "32.5448714",
        longitude: "-94.3674184",
        market: "Tyler-Longview",
      },
      {
        station: "KJTV",
        frequency: "950 AM",
        latitude: "33.5778631",
        longitude: "-101.8551665",
        market: "Lubbock",
      },
      {
        station: "KSML",
        frequency: "1260 AM",
        latitude: "31.3345562",
        longitude: "-94.7321127",
        market: "Lufkin-Nacogdoches",
      },
      {
        station: "KMVL",
        frequency: "1220 AM",
        latitude: "30.9499111",
        longitude: "-95.9116188",
        market: "Madisonville",
      },
      {
        station: "KMVL",
        frequency: "98.9 FM",
        latitude: "30.9499111",
        longitude: "-95.9116188",
        market: "Madisonville",
      },
      {
        station: "WSLA",
        frequency: "1560 AM",
        latitude: "30.2751945",
        longitude: "-89.7811745",
        market: "New Orleans",
      },
      {
        station: "KTKR",
        frequency: "760 AM",
        latitude: "29.4251905",
        longitude: "-98.4945922",
        market: "San Antonio",
      },
      {
        station: "KSYB",
        frequency: "1300 AM",
        latitude: "32.5251516",
        longitude: "-93.7501789",
        market: "Shreveport",
      },
      {
        station: "KTKC",
        frequency: "1460 AM",
        latitude: "33.0059692",
        longitude: "-93.4668396",
        market: "Shreveport",
      },
      {
        station: "KLCJ",
        frequency: "104.1 FM",
        latitude: "32.8609625",
        longitude: "-91.3884482",
        market: "Lake Charles",
      },
      {
        station: "KTEM",
        frequency: "1400 AM",
        latitude: "31.0982344",
        longitude: "-97.342782",
        market: "Killeen-Temple",
      },
      {
        station: "KVNN",
        frequency: "1340 AM",
        latitude: "28.8052674",
        longitude: "-97.0035982",
        market: "Victoria",
      },
      {
        station: "KBBW",
        frequency: "1010 AM",
        latitude: "31.549333",
        longitude: "-97.1466695",
        market: "Waco",
      },
      {
        station: "K289BU",
        frequency: "105.7 FM",
        latitude: "31.549333",
        longitude: "-97.1466695",
        market: "Waco",
      },
    ],
    "Kansas City Royals": [
      {
        market: "Cherokee",
        station: "KCHE",
        frequency: "1440 AM",
        latitude: "42.7496413",
        longitude: "-95.5515431",
      },
      {
        market: "Denison",
        station: "KDSN",
        frequency: "1530 AM",
        latitude: "42.024988",
        longitude: "-95.3588867",
      },
      {
        market: "Shenandoah",
        station: "KMA",
        frequency: "960 AM",
        latitude: "40.7667389",
        longitude: "-95.3761506",
      },
      {
        market: "Arkansas City",
        station: "KSOK",
        frequency: "1280 AM\n103.3 FM",
        latitude: "37.0619736",
        longitude: "-97.0383712",
      },
      {
        market: "Belolt",
        station: "KVSV",
        frequency: "1190 AM",
        latitude: "39.4561178",
        longitude: "-98.1061595",
      },
      {
        market: "Chanute",
        station: "KKOY",
        frequency: "1460 AM\n99.9 FM",
        latitude: "37.6792135",
        longitude: "-95.4572034",
      },
      {
        market: "Coffeyville",
        station: "KGGF",
        frequency: "690 AM\n98.9 FM",
        latitude: "37.0373005",
        longitude: "-95.6163665",
      },
      {
        market: "Colby",
        station: "KXXX",
        frequency: "790 AM",
        latitude: "39.3958369",
        longitude: "-101.0523773",
      },
      {
        market: "Concordia",
        station: "KNCK",
        frequency: "1390 AM\n98.3 FM",
        latitude: "39.5708354",
        longitude: "-97.6625396",
      },
      {
        market: "Emporia",
        station: "KVOE",
        frequency: "1400 AM\n96.9 FM",
        latitude: "38.403903",
        longitude: "-96.1816626",
      },
      {
        market: "Fort Scott",
        station: "KMDO\nKMDO",
        frequency: "103.9 FM\n1600 AM",
        latitude: "37.8397626",
        longitude: "-94.7082951",
      },
      {
        market: "Garden City",
        station: "KIUL",
        frequency: "1240 AM\n104.9 FM",
        latitude: "37.9716898",
        longitude: "-100.8726618",
      },
      {
        market: "Great Bend",
        station: "KVGB",
        frequency: "1590 AM\n95.5 FM",
        latitude: "38.3644567",
        longitude: "-98.7648073",
      },
      {
        market: "Hays",
        station: "KAYS",
        frequency: "1400 AM\n94.3 FM",
        latitude: "38.8791783",
        longitude: "-99.3267702",
      },
      {
        market: "Hutchinson",
        station: "KWBW",
        frequency: "1450 AM\n98.5 FM",
        latitude: "38.0608445",
        longitude: "-97.9297743",
      },
      {
        market: "Independence",
        station: "KIND",
        frequency: "1010 AM\n101.9 FM",
        latitude: "37.2242358",
        longitude: "-95.7083131",
      },
      {
        market: "Junction City",
        station: "KJCK",
        frequency: "1420 AM\n107.9 FM",
        latitude: "39.0286093",
        longitude: "-96.8313978",
      },
      {
        market: "Liberal",
        station: "KSCB",
        frequency: "1270 AM",
        latitude: "37.0430812",
        longitude: "-100.920999",
      },
      {
        market: "Manhattan",
        station: "KMAN",
        frequency: "1350 AM\n97.5 FM",
        latitude: "39.1836082",
        longitude: "-96.5716694",
      },
      {
        market: "Mcpherson",
        station: "KBBE",
        frequency: "96.7 FM",
        latitude: "38.3708415",
        longitude: "-97.6642087",
      },
      {
        market: "Norton",
        station: "KQNK",
        frequency: "1530 AM\n106.7 FM",
        latitude: "39.8338946",
        longitude: "-99.8915113",
      },
      {
        market: "Ottawa",
        station: "KOFO",
        frequency: "1220 AM\n103.7 FM",
        latitude: "38.615768",
        longitude: "-95.2686366",
      },
      {
        market: "Parsons",
        station: "KLKC",
        frequency: "1540 AM\n106.7 FM",
        latitude: "37.3403384",
        longitude: "-95.2610838",
      },
      {
        market: "Pittsburg",
        station: "KKOW",
        frequency: "860 AM\n97.5 FM",
        latitude: "37.410884",
        longitude: "-94.70496",
      },
      {
        market: "Pratt",
        station: "KMMM",
        frequency: "1290 AM",
        latitude: "37.6439071",
        longitude: "-98.7375911",
      },
      {
        market: "Russell",
        station: "KRSL",
        frequency: "990 AM\n98.1 FM",
        latitude: "38.8952893",
        longitude: "-98.8598061",
      },
      {
        market: "Salina",
        station: "KSAL",
        frequency: "1150 AM",
        latitude: "38.8402805",
        longitude: "-97.6114237",
      },
      {
        market: "Seneca",
        station: "KMZA",
        frequency: "92.1 FM",
        latitude: "39.834165",
        longitude: "-96.0641676",
      },
      {
        market: "Topeka",
        station: "WIBW",
        frequency: "580 AM",
        latitude: "39.0473451",
        longitude: "-95.6751576",
      },
      {
        market: "Ulysses",
        station: "KULY",
        frequency: "1420 AM\n106.7 FM",
        latitude: "37.5814095",
        longitude: "-101.3551696",
      },
      {
        market: "Wichita",
        station: "KFH",
        frequency: "1240 AM\n97.5 FM",
        latitude: "37.6871761",
        longitude: "-97.330053",
      },
      {
        market: "Ava",
        station: "KKOZ\nKKOZ",
        frequency: "92.1 FM\n1430 AM",
        latitude: "36.951999",
        longitude: "-92.6604457",
      },
      {
        market: "Boonville",
        station: "KWRT",
        frequency: "1370 AM",
        latitude: "38.9736392",
        longitude: "-92.7432418",
      },
      {
        market: "California",
        station: "KRLL",
        frequency: "1420 AM",
        latitude: "38.6275278",
        longitude: "-92.5665786",
      },
      {
        market: "Cameron",
        station: "KKWK",
        frequency: "100.1 FM",
        latitude: "39.7402801",
        longitude: "-94.241057",
      },
      {
        market: "Clinton",
        station: "KDKD",
        frequency: "1280 AM",
        latitude: "38.3686324",
        longitude: "-93.7782689",
      },
      {
        market: "Jeffereson City",
        station: "KJMO",
        frequency: "97.5 FM",
        latitude: "38.5767017",
        longitude: "-92.1735164",
      },
      {
        market: "Kansas City",
        station: "KCSP",
        frequency: "610 AM",
        latitude: "39.0997265",
        longitude: "-94.5785667",
      },
      {
        market: "Lamar",
        station: "KHST",
        frequency: "101.7 FM",
        latitude: "37.4950478",
        longitude: "-94.2766148",
      },
      {
        market: "Marceline",
        station: "KDWD",
        frequency: "99.1 FM",
        latitude: "39.7119703",
        longitude: "-92.9482526",
      },
      {
        market: "Marshall",
        station: "KMMO",
        frequency: "1300 AM\n102.9 FM",
        latitude: "39.1230777",
        longitude: "-93.1968704",
      },
      {
        market: "Moberly",
        station: "KWIX",
        frequency: "92.5 FM",
        latitude: "39.4183689",
        longitude: "-92.4382367",
      },
      {
        market: "Osage Beach",
        station: "KRMS",
        frequency: "1150 AM\n97.5 FM\n103.3 FM",
        latitude: "38.1503113",
        longitude: "-92.6179621",
      },
      {
        market: "Springfield",
        station: "KICK",
        frequency: "92.3 FM",
        latitude: "37.2089572",
        longitude: "-93.2922989",
      },
      {
        market: "St. Joseph",
        station: "KFEQ",
        frequency: "680 AM",
        latitude: "39.7674578",
        longitude: "-94.846681",
      },
      {
        market: "Trenton",
        station: "KTTN",
        frequency: "1600 AM\n92.3 FM",
        latitude: "40.0789018",
        longitude: "-93.6166074",
      },
      {
        market: "Warrensburg",
        station: "KOKO",
        frequency: "1450 AM",
        latitude: "38.7627893",
        longitude: "-93.7360498",
      },
      {
        market: "Fairbury",
        station: "KGMT",
        frequency: "1310 AM",
        latitude: "40.1466169",
        longitude: "-97.1798456",
      },
      {
        market: "Hastings",
        station: "KLIQ",
        frequency: "94.5 FM",
        latitude: "40.5862583",
        longitude: "-98.3898726",
      },
      {
        market: "Lincoln",
        station: "KFOR",
        frequency: "1240 AM\n103.3 FM",
        latitude: "40.813616",
        longitude: "-96.7025955",
      },
      {
        market: "Mccook",
        station: "KBRL",
        frequency: "1300 AM\n97.5 FM",
        latitude: "40.1966701",
        longitude: "-100.6248741",
      },
      {
        market: "Norfolk",
        station: "WJAG",
        frequency: "780 AM\n105.9 FM",
        latitude: "42.0327234",
        longitude: "-97.4137553",
      },
      {
        market: "Omaha",
        station: "KZOT",
        frequency: "1180 AM\n1620 AM\n1290 AM",
        latitude: "41.2565369",
        longitude: "-95.9345034",
      },
    ],
    "Los Angeles Angels": [
      {
        station: "KLAA",
        frequency: "830 AM",
        market: "Orange",
        latitude: "33.7879139",
        longitude: "-117.8531007",
      },
      {
        station: "KSPN",
        frequency: "710 AM",
        market: "Los Angeles",
        latitude: "34.0522342",
        longitude: "-118.2436849",
      },
      {
        station: "KXPS",
        frequency: "1010 AM",
        market: "Palm Springs",
        latitude: "33.8302961",
        longitude: "-116.5452921",
      },
      {
        station: "KMET",
        frequency: "1490 AM",
        market: "Banning",
        latitude: "33.9255713",
        longitude: "-116.8764103",
      },
      {
        station: "KLOA",
        frequency: "1240 AM",
        market: "Ridgecrest",
        latitude: "35.6224561",
        longitude: "-117.6708966",
      },
      {
        station: "KMPS",
        frequency: "910 AM",
        market: "Victorville",
        latitude: "34.5362184",
        longitude: "-117.2927641",
      },
    ],
    "Los Angeles Dodgers": [
      {
        station: "KLAC",
        frequency: "570 AM",
        market: "Los Angeles",
        latitude: "34.0522342",
        longitude: "-118.2436849",
      },
      {
        station: "KYSR-HD2",
        frequency: "98.7 FM",
        market: "Los Angeles",
        latitude: "34.0522342",
        longitude: "-118.2436849",
      },
      {
        station: "KHTY",
        frequency: "970 AM",
        market: "Bakersfield",
        latitude: "35.3732921",
        longitude: "-119.0187125",
      },
      {
        station: "KBOV",
        frequency: "1230 AM",
        market: "Bishop",
        latitude: "37.3614238",
        longitude: "-118.3996636",
      },
      {
        station: "KROP",
        frequency: "1300 AM",
        market: "Brawley",
        latitude: "32.9786566",
        longitude: "-115.530267",
      },
      {
        station: "KCBL",
        frequency: "1340 AM",
        market: "Fresno",
        latitude: "36.7377981",
        longitude: "-119.7871247",
      },
      {
        station: "KAVL",
        frequency: "610 AM",
        market: "Lancaster",
        latitude: "34.6867846",
        longitude: "-118.1541632",
      },
      {
        station: "KTNK",
        frequency: "1410 AM",
        market: "Lompoc",
        latitude: "34.6391501",
        longitude: "-120.4579409",
      },
      {
        station: "KVYB",
        frequency: "106.3 FM",
        market: "Oak View",
        latitude: "34.399996",
        longitude: "-119.3001134",
      },
      {
        station: "KKGX",
        frequency: "920 AM",
        market: "Palm Springs",
        latitude: "33.8302961",
        longitude: "-116.5452921",
      },
      {
        station: "KWDJ",
        frequency: "1360 AM",
        market: "Ridgecrest",
        latitude: "35.6224561",
        longitude: "-117.6708966",
      },
      {
        station: "KFOO",
        frequency: "1440 AM",
        market: "Riverside",
        latitude: "33.9806005",
        longitude: "-117.3754942",
      },
      {
        station: "KXTK",
        frequency: "1280 AM",
        market: "San Luis Obispo",
        latitude: "34.9530337",
        longitude: "-120.4357191",
      },
      {
        station: "KHTS",
        frequency: "1220 AM",
        market: "Santa Clarita",
        latitude: "34.3916641",
        longitude: "-118.542586",
      },
      {
        station: "KRZR",
        frequency: "1400 AM",
        market: "Visalia",
        latitude: "36.3302284",
        longitude: "-119.2920585",
      },
      {
        station: "KXST",
        frequency: "1140 AM",
        market: "Las Vegas",
        latitude: "36.171563",
        longitude: "-115.1391009",
      },
      {
        station: "KQTM",
        frequency: "101.7 FM",
        market: "Albuquerque",
        latitude: "35.0843859",
        longitude: "-106.650422",
      },
      {
        station: "KYVA",
        frequency: "1230 AM",
        market: "Gallup",
        latitude: "35.5280783",
        longitude: "-108.7425843",
      },
      {
        station: "KROD",
        frequency: "600 AM",
        market: "El Paso",
        latitude: "31.7618778",
        longitude: "-106.4850217",
      },
    ],
    "Miami Marlins": [
      {
        station: "WAVK",
        frequency: "97.7 FM",
        market: "Key West",
        latitude: "24.5554183",
        longitude: "-81.7841722",
      },
      {
        station: "WSTU",
        frequency: "1450 AM",
        market: "Stuart",
        latitude: "27.197548",
        longitude: "-80.2528257",
      },
      {
        station: "WBZT\nWKGR",
        frequency: "1230 AM\n98.7 FM-HD2",
        market: "West Palm Beach",
        latitude: "26.7153424",
        longitude: "-80.0533746",
      },
      {
        station: "W290DB\nWFSX",
        frequency: "105.9 FM\n92.5 FM-HD2",
        market: "Naples",
        latitude: "26.1420358",
        longitude: "-81.7948103",
      },
      {
        station: "WIXC",
        frequency: "1060 AM",
        market: "Titusville",
        latitude: "28.6122187",
        longitude: "-80.8075537",
      },
      {
        station: "WEBY\nW256DL",
        frequency: "1330 AM\n99.1 FM",
        market: "Pensacola",
        latitude: "30.421309",
        longitude: "-87.2169149",
      },
      {
        station: "WINZ\nWMIA",
        frequency: "940 AM\n93.9 FM-HD3",
        market: "Miami",
        latitude: "25.7616798",
        longitude: "-80.1917902",
      },
    ],
    "Milwaukee Brewers": [
      {
        station: "WACD",
        market: "Antigo",
        frequency: "106.1 FM",
        latitude: "45.1402",
        longitude: "-89.1523",
      },
      {
        station: "WHBY",
        market: "Appleton",
        frequency: "1150 AM",
        latitude: "44.2619",
        longitude: "-88.4154",
      },
      {
        station: "WHBY",
        market: "Appleton",
        frequency: "103.5 FM\n106.3 FM",
        latitude: "44.2619",
        longitude: "-88.4154",
      },
      {
        station: "WATW",
        market: "Ashland",
        frequency: "1400 AM",
        latitude: "46.5924",
        longitude: "-90.8838",
      },
      {
        station: "WBEV",
        market: "Beaver Dam",
        frequency: "1430 AM",
        latitude: "43.4578",
        longitude: "-88.8373",
      },
      {
        station: "WISS",
        market: "Berlin",
        frequency: "1100 AM",
        latitude: "43.9680",
        longitude: "-88.9434",
      },
      {
        station: "WISS",
        market: "Berlin",
        frequency: "98.7 FM",
        latitude: "43.9680",
        longitude: "-88.9434",
      },
      {
        station: "WWIS",
        market: "Black River Falls",
        frequency: "99.7 FM",
        latitude: "44.2947",
        longitude: "-90.8515",
      },
      {
        station: "WRJO",
        market: "Eagle River",
        frequency: "94.5 FM",
        latitude: "45.9172",
        longitude: "-89.2443",
      },
      {
        station: "WAYY",
        market: "Eau Claire",
        frequency: "790 AM",
        latitude: "44.8113",
        longitude: "-91.4985",
      },
      {
        station: "WAYY",
        market: "Eau Claire",
        frequency: "105.1 FM",
        latitude: "44.8113",
        longitude: "-91.4985",
      },
      {
        station: "KFIZ",
        market: "Fond Du Lac",
        frequency: "1450 AM",
        latitude: "43.7730",
        longitude: "-88.4471",
      },
      {
        station: "WFAW",
        market: "Fort Atkinson",
        frequency: "940 AM",
        latitude: "42.9289",
        longitude: "-88.8371",
      },
      {
        station: "WTAQ",
        market: "Green Bay",
        frequency: "1360 AM",
        latitude: "44.5133",
        longitude: "-88.0133",
      },
      {
        station: "WTAQ",
        market: "Green Bay",
        frequency: "97.5 FM",
        latitude: "44.5133",
        longitude: "-88.0133",
      },
      {
        station: "WCLO",
        market: "Janesville",
        frequency: "1230 AM",
        latitude: "42.6828",
        longitude: "-89.0187",
      },
      {
        station: "WCLO",
        market: "Janesville",
        frequency: "92.7 FM",
        latitude: "42.6828",
        longitude: "-89.0187",
      },
      {
        station: "WKTY",
        market: "La Crosse",
        frequency: "580 AM",
        latitude: "43.8138",
        longitude: "-91.2519",
      },
      {
        station: "WKTY",
        market: "La Crosse",
        frequency: "96.7 FM",
        latitude: "43.8138",
        longitude: "-91.2519",
      },
      {
        station: "WOZN",
        market: "Madison",
        frequency: "1670 AM",
        latitude: "43.0731",
        longitude: "-89.4012",
      },
      {
        station: "WOZN",
        market: "Madison",
        frequency: "96.7 FM",
        latitude: "43.0731",
        longitude: "-89.4012",
      },
      {
        station: "WOMT",
        market: "Manitowoc",
        frequency: "1240 AM",
        latitude: "44.0886",
        longitude: "-87.6576",
      },
      {
        station: "WMAM",
        market: "Marinette",
        frequency: "570 AM",
        latitude: "45.1000",
        longitude: "-87.6307",
      },
      {
        station: "WIGM",
        market: "Medford",
        frequency: "1490 AM",
        latitude: "45.1386",
        longitude: "-90.3401",
      },
      {
        station: "WTMJ",
        market: "Milwaukee",
        frequency: "620 AM",
        latitude: "43.0389",
        longitude: "-87.9065",
      },
      {
        station: "WTMJ",
        market: "Milwaukee",
        frequency: "103.3 FM",
        latitude: "43.0389",
        longitude: "-87.9065",
      },
      {
        station: "WCQM",
        market: "Park Falls",
        frequency: "98.3 FM",
        latitude: "45.9344",
        longitude: "-90.4416",
      },
      {
        station: "WPVL",
        market: "Platteville",
        frequency: "1590 AM",
        latitude: "42.7342",
        longitude: "-90.4785",
      },
      {
        station: "WQPC",
        market: "Prairie Du Chien",
        frequency: "94.3 FM",
        latitude: "43.0517",
        longitude: "-91.1412",
      },
      {
        station: "WRDB",
        market: "Reedsburg",
        frequency: "1400 AM",
        latitude: "43.5325",
        longitude: "-90.0026",
      },
      {
        station: "WJMC",
        market: "Rice Lake",
        frequency: "1240 AM",
        latitude: "45.5061",
        longitude: "-91.7382",
      },
      {
        station: "WRCO",
        market: "Richland Center",
        frequency: "1450 AM",
        latitude: "43.3347",
        longitude: "-90.3868",
      },
      {
        station: "WRCO",
        market: "Richland Center",
        frequency: "107.7 FM",
        latitude: "43.3347",
        longitude: "-90.3868",
      },
      {
        station: "WEVR",
        market: "River Falls",
        frequency: "1550 AM",
        latitude: "44.8614",
        longitude: "-92.6238",
      },
      {
        station: "WEVR",
        market: "River Falls",
        frequency: "106.3 FM",
        latitude: "44.8614",
        longitude: "-92.6238",
      },
      {
        station: "WTCH",
        market: "Shawano",
        frequency: "960 AM",
        latitude: "44.7822",
        longitude: "-88.6090",
      },
      {
        station: "WTCH",
        market: "Shawano",
        frequency: "96.1 FM",
        latitude: "44.7822",
        longitude: "-88.6090",
      },
      {
        station: "WHBL",
        market: "Sheboygan",
        frequency: "1330 AM",
        latitude: "43.7508",
        longitude: "-87.7145",
      },
      {
        station: "WSAU",
        market: "Stevens Point",
        frequency: "99.9 FM",
        latitude: "44.5236",
        longitude: "-89.5746",
      },
      {
        station: "WDOR",
        market: "Sturgeon Bay",
        frequency: "93.9 FM",
        latitude: "44.8342",
        longitude: "-87.3770",
      },
      {
        station: "WDUX",
        market: "Waupaca",
        frequency: "92.7 FM",
        latitude: "44.3580",
        longitude: "-89.0859",
      },
      {
        station: "WSAU",
        market: "Wausau",
        frequency: "550 AM",
        latitude: "44.9591",
        longitude: "-89.6301",
      },
      {
        station: "WSAU",
        market: "Wausau",
        frequency: "95.1 FM",
        latitude: "44.9591",
        longitude: "-89.6301",
      },
      {
        station: "WJMS",
        market: "Ironwood",
        frequency: "590 AM",
        latitude: "46.4547",
        longitude: "-90.1710",
      },
    ],
    "Minnesota Twins": [
      {
        market: "Aitkin",
        latitude: "46.5330129",
        longitude: "-93.7102493",
        station: "KKIN",
        frequency: "94.3 FM",
      },
      {
        market: "Albert Lea",
        latitude: "43.6478006",
        longitude: "-93.3686555",
        station: "KATE",
        frequency: "1450 AM",
      },
      {
        market: "Alexandria",
        latitude: "45.8848164",
        longitude: "-95.3776663",
        station: "KULO",
        frequency: "94.3 FM",
      },
      {
        market: "Austin",
        latitude: "43.6666296",
        longitude: "-92.9746367",
        station: "KAUS",
        frequency: "1480 AM",
      },
      {
        market: "Bagley",
        latitude: "47.5216231",
        longitude: "-95.3983491",
        station: "KKCQ",
        frequency: "96.7 FM",
      },
      {
        market: "Bemidji",
        latitude: "47.471573",
        longitude: "-94.8826861",
        station: "KBUN",
        frequency: "1450 AM",
      },
      {
        market: "Bemidji",
        latitude: "47.471573",
        longitude: "-94.8826861",
        station: "FM Signal",
        frequency: "94.9 FM",
      },
      {
        market: "Benson",
        latitude: "45.3149603",
        longitude: "-95.6000349",
        station: "KBMO",
        frequency: "1290 AM",
      },
      {
        market: "Benson",
        latitude: "45.3149603",
        longitude: "-95.6000349",
        station: "FM Signal",
        frequency: "103.5 FM",
      },
      {
        market: "Blackduck",
        latitude: "47.7330101",
        longitude: "-94.548579",
        station: "KBUN",
        frequency: "104.5 FM",
      },
      {
        market: "Brainerd",
        latitude: "46.3526733",
        longitude: "-94.2020084",
        station: "KUAL",
        frequency: "103.5 FM",
      },
      {
        market: "Crookston",
        latitude: "47.7745617",
        longitude: "-96.6093911",
        station: "KROX",
        frequency: "1260 AM",
      },
      {
        market: "Crookston",
        latitude: "47.7745617",
        longitude: "-96.6093911",
        station: "FM Signal",
        frequency: "92.1 FM",
      },
      {
        market: "Crookston",
        latitude: "47.7745617",
        longitude: "-96.6093911",
        station: "FM Signal",
        frequency: "105.7 FM",
      },
      {
        market: "Dassel",
        latitude: "45.0816298",
        longitude: "-94.3069284",
        station: "KARP",
        frequency: "106.9 FM",
      },
      {
        market: "Detroit Lakes",
        latitude: "46.8171808",
        longitude: "-95.8453253",
        station: "KDLM",
        frequency: "1340 AM",
      },
      {
        market: "Detroit Lakes",
        latitude: "46.8171808",
        longitude: "-95.8453253",
        station: "FM Signal",
        frequency: "93.1 FM",
      },
      {
        market: "Detroit Lakes",
        latitude: "46.8171808",
        longitude: "-95.8453253",
        station: "FM Signal",
        frequency: "96.9 FM",
      },
      {
        market: "Duluth",
        latitude: "46.7866719",
        longitude: "-92.1004852",
        station: "KDAL",
        frequency: "610 AM",
      },
      {
        market: "Duluth",
        latitude: "46.7866719",
        longitude: "-92.1004852",
        station: "FM Signal",
        frequency: "103.9 FM",
      },
      {
        market: "Ely",
        latitude: "47.9032372",
        longitude: "-91.8670873",
        station: "WELY",
        frequency: "94.5 FM",
      },
      {
        market: "Ely",
        latitude: "47.9032372",
        longitude: "-91.8670873",
        station: "WELY",
        frequency: "1450 AM",
      },
      {
        market: "Eveleth",
        latitude: "47.4624279",
        longitude: "-92.5399063",
        station: "KRBT",
        frequency: "1340 AM",
      },
      {
        market: "Eveleth",
        latitude: "47.4624279",
        longitude: "-92.5399063",
        station: "FM Signal",
        frequency: "104.9 FM",
      },
      {
        market: "Fairmont",
        latitude: "43.6521775",
        longitude: "-94.4610832",
        station: "KSUM",
        frequency: "1370 AM",
      },
      {
        market: "Fergus Falls",
        latitude: "46.2835212",
        longitude: "-96.0777887",
        station: "KBRF",
        frequency: "1250 AM",
      },
      {
        market: "Glencoe",
        latitude: "44.7690437",
        longitude: "-94.1517524",
        station: "KGLB",
        frequency: "1310 AM",
      },
      {
        market: "Glencoe",
        latitude: "44.7690437",
        longitude: "-94.1517524",
        station: "FM Signal",
        frequency: "96.1 FM",
      },
      {
        market: "Glenwood",
        latitude: "45.6502392",
        longitude: "-95.3900419",
        station: "KMGK",
        frequency: "107.1 FM",
      },
      {
        market: "Grand Rapids",
        latitude: "47.2371658",
        longitude: "-93.5302142",
        station: "KOZY",
        frequency: "1320 AM",
      },
      {
        market: "Grand Rapids",
        latitude: "47.2371658",
        longitude: "-93.5302142",
        station: "FM Signal",
        frequency: "93.1 FM",
      },
      {
        market: "Hibbing",
        latitude: "47.4271546",
        longitude: "-92.9376887",
        station: "WMFG",
        frequency: "1240 AM",
      },
      {
        market: "Int'L Falls",
        latitude: "48.6023149",
        longitude: "-93.4040679",
        station: "KGHS",
        frequency: "1230 AM",
      },
      {
        market: "Int'L Falls",
        latitude: "48.6023149",
        longitude: "-93.4040679",
        station: "FM Signal",
        frequency: "95.3 FM",
      },
      {
        market: "Little Falls",
        latitude: "45.9805556",
        longitude: "-94.3694444",
        station: "WYRQ",
        frequency: "92.1 FM",
      },
      {
        market: "Mankato",
        latitude: "44.1635775",
        longitude: "-93.9993996",
        station: "KTOE",
        frequency: "1420 AM",
      },
      {
        market: "Mankato",
        latitude: "44.1635775",
        longitude: "-93.9993996",
        station: "FM Signal",
        frequency: "102.7 FM",
      },
      {
        market: "Marshall",
        latitude: "44.448423",
        longitude: "-95.7911916",
        station: "KMHL",
        frequency: "1400 AM",
      },
      {
        market: "Marshall",
        latitude: "44.448423",
        longitude: "-95.7911916",
        station: "FM Signal",
        frequency: "107.1 FM",
      },
      {
        market: "Minneapolis",
        latitude: "44.9537029",
        longitude: "-93.0899578",
        station: "WCCO",
        frequency: "830 AM",
      },
      {
        market: "Minneapolis",
        latitude: "44.9537029",
        longitude: "-93.0899578",
        station: "KMNB",
        frequency: "102.9 FM",
      },
      {
        market: "Montevideo",
        latitude: "44.9410447",
        longitude: "-95.7236136",
        station: "KMGM",
        frequency: "105.5 FM",
      },
      {
        market: "Moose Lake",
        latitude: "46.4513889",
        longitude: "-92.7666667",
        station: "WMOZ",
        frequency: "106.9 FM",
      },
      {
        market: "Morris",
        latitude: "45.5919444",
        longitude: "-95.9188889",
        station: "KMRS",
        frequency: "1230 AM",
      },
      {
        market: "Morris",
        latitude: "45.5919444",
        longitude: "-95.9188889",
        station: "FM Signal",
        frequency: "107.7 FM",
      },
      {
        market: "New Prague",
        latitude: "44.5432966",
        longitude: "-93.5760652",
        station: "KCHK",
        frequency: "1350 AM",
      },
      {
        market: "New Prague",
        latitude: "44.5432966",
        longitude: "-93.5760652",
        station: "FM Signal",
        frequency: "106.7 FM",
      },
      {
        market: "New Ulm",
        latitude: "44.3144394",
        longitude: "-94.4593018",
        station: "KNUJ",
        frequency: "860 AM",
      },
      {
        market: "New Ulm",
        latitude: "44.3144394",
        longitude: "-94.4593018",
        station: "FM Signal",
        frequency: "97.3 FM",
      },
      {
        market: "Northfield",
        latitude: "44.4582983",
        longitude: "-93.161604",
        station: "KYMN",
        frequency: "1080 AM",
      },
      {
        market: "Northfield",
        latitude: "44.4582983",
        longitude: "-93.161604",
        station: "FM Signal",
        frequency: "95.1 FM",
      },
      {
        market: "Ortonville",
        latitude: "45.3047822",
        longitude: "-96.4450624",
        station: "KDIO",
        frequency: "1350 AM",
      },
      {
        market: "Ortonville",
        latitude: "45.3047822",
        longitude: "-96.4450624",
        station: "FM Signal",
        frequency: "94.5 FM",
      },
      {
        market: "Owatonna",
        latitude: "44.0879297",
        longitude: "-93.2260184",
        station: "KRFO",
        frequency: "1390 AM",
      },
      {
        market: "Owatonna",
        latitude: "44.0879297",
        longitude: "-93.2260184",
        station: "FM Signal",
        frequency: "94.7 FM",
      },
      {
        market: "Park Rapids",
        latitude: "46.9221813",
        longitude: "-95.0586322",
        station: "KPRM",
        frequency: "870 AM",
      },
      {
        market: "Park Rapids",
        latitude: "46.9221813",
        longitude: "-95.0586322",
        station: "KDKK",
        frequency: "97.5 FM",
      },
      {
        market: "Pine City",
        latitude: "45.8229105",
        longitude: "-92.9703435",
        station: "WCMP",
        frequency: "100.9 FM",
      },
      {
        market: "Preston",
        latitude: "43.6737553",
        longitude: "-92.0836809",
        station: "KFIL",
        frequency: "103.1 FM",
      },
      {
        market: "Red Wing",
        latitude: "44.5660691",
        longitude: "-92.5369563",
        station: "KWNG",
        frequency: "105.9 FM",
      },
      {
        market: "Rochester",
        latitude: "44.0121221",
        longitude: "-92.4801989",
        station: "KROC",
        frequency: "1340 AM",
      },
      {
        market: "Rochester",
        latitude: "44.0121221",
        longitude: "-92.4801989",
        station: "FM Signal",
        frequency: "96.9 FM",
      },
      {
        market: "Roseau",
        latitude: "48.8460926",
        longitude: "-95.762766",
        station: "KRWB",
        frequency: "1410 AM",
      },
      {
        market: "Roseau",
        latitude: "48.8460926",
        longitude: "-95.762766",
        station: "FM Signal",
        frequency: "102.9 FM",
      },
      {
        market: "St. Cloud",
        latitude: "45.5579451",
        longitude: "-94.1632404",
        station: "WJON",
        frequency: "1240 AM",
      },
      {
        market: "St. Cloud",
        latitude: "45.5579451",
        longitude: "-94.1632404",
        station: "FM Signal",
        frequency: "95.3 FM",
      },
      {
        market: "Sleepy Eye",
        latitude: "44.2980556",
        longitude: "-94.7263889",
        station: "KNUJ",
        frequency: "107.3 FM",
      },
      {
        market: "Thief River Falls",
        latitude: "48.1191353",
        longitude: "-96.1811473",
        station: "KTRF",
        frequency: "1230 AM",
      },
      {
        market: "Thief River Falls",
        latitude: "48.1191353",
        longitude: "-96.1811473",
        station: "FM Signal",
        frequency: "93.3 FM",
      },
      {
        market: "Wabasha",
        latitude: "44.3754518",
        longitude: "-92.0236059",
        station: "WBHA",
        frequency: "1190 AM",
      },
      {
        market: "Wabasha",
        latitude: "44.3754518",
        longitude: "-92.0236059",
        station: "FM Signal",
        frequency: "99.7 FM",
      },
      {
        market: "Wadena",
        latitude: "46.4424614",
        longitude: "-95.1361386",
        station: "KWAD",
        frequency: "920 AM",
      },
      {
        market: "Wadena",
        latitude: "46.4424614",
        longitude: "-95.1361386",
        station: "FM Signal",
        frequency: "101.7 FM",
      },
      {
        market: "Willmar",
        latitude: "45.1223711",
        longitude: "-95.048659",
        station: "KDJS",
        frequency: "1590 AM",
      },
      {
        market: "Willmar",
        latitude: "45.1223711",
        longitude: "-95.048659",
        station: "FM Signal",
        frequency: "105.7 FM",
      },
      {
        market: "Windom",
        latitude: "43.8727352",
        longitude: "-95.1113808",
        station: "KDOM",
        frequency: "1580 AM",
      },
      {
        market: "Windom",
        latitude: "43.8727352",
        longitude: "-95.1113808",
        station: "FM Signal",
        frequency: "103.1 FM",
      },
      {
        market: "Winona",
        latitude: "44.0553908",
        longitude: "-91.6663523",
        station: "KWNO",
        frequency: "1230 AM",
      },
      {
        market: "Winona",
        latitude: "44.0553908",
        longitude: "-91.6663523",
        station: "FM Signal",
        frequency: "98.7 FM",
      },
      {
        market: "Worthington",
        latitude: "43.6210985",
        longitude: "-95.5941436",
        station: "KWOA",
        frequency: "730 AM",
      },
      {
        market: "Worthington",
        latitude: "43.6210985",
        longitude: "-95.5941436",
        station: "FM Signal",
        frequency: "100 FM",
      },
      {
        market: "Des Moines",
        latitude: "41.5868353",
        longitude: "-93.6249593",
        station: "KBGG",
        frequency: "1700 AM",
      },
      {
        market: "Des Moines",
        latitude: "41.5868353",
        longitude: "-93.6249593",
        station: "FM Signal",
        frequency: "101.3 FM",
      },
      {
        market: "Iowa Falls",
        latitude: "42.5198868",
        longitude: "-93.2645741",
        station: "KIFG",
        frequency: "1510 AM",
      },
      {
        market: "Iowa Falls",
        latitude: "42.5198868",
        longitude: "-93.2645741",
        station: "KIFG",
        frequency: "95.3 FM",
      },
      {
        market: "Mason City",
        latitude: "43.1535728",
        longitude: "-93.2010367",
        station: "KGLO",
        frequency: "1300 AM",
      },
      {
        market: "Spencer",
        latitude: "43.1450748",
        longitude: "-95.144318",
        station: "KICD",
        frequency: "1240 AM",
      },
      {
        market: "Spencer",
        latitude: "43.1450748",
        longitude: "-95.144318",
        station: "FM Signal",
        frequency: "102.5 FM",
      },
      {
        market: "Bismarck",
        latitude: "46.8083268",
        longitude: "-100.7837392",
        station: "KXMR",
        frequency: "710 AM",
      },
      {
        market: "Carrington",
        latitude: "47.4497201",
        longitude: "-99.1262234",
        station: "KDAK",
        frequency: "1600 AM",
      },
      {
        market: "Carrington",
        latitude: "47.4497201",
        longitude: "-99.1262234",
        station: "FM Signal",
        frequency: "100.1 FM",
      },
      {
        market: "Devils Lake",
        latitude: "48.112779",
        longitude: "-98.8651202",
        station: "KDLR",
        frequency: "1240 AM",
      },
      {
        market: "Devils Lake",
        latitude: "48.112779",
        longitude: "-98.8651202",
        station: "FM Signal",
        frequency: "99.7 FM",
      },
      {
        market: "Dickinson",
        latitude: "46.8791756",
        longitude: "-102.7896242",
        station: "KLTC",
        frequency: "1460 AM",
      },
      {
        market: "Fargo",
        latitude: "46.8771863",
        longitude: "-96.7898034",
        station: "KFGO",
        frequency: "790 AM",
      },
      {
        market: "Fargo",
        latitude: "46.8771863",
        longitude: "-96.7898034",
        station: "KFGO",
        frequency: "104.7 FM",
      },
      {
        market: "Fargo",
        latitude: "46.8771863",
        longitude: "-96.7898034",
        station: "FM Signal",
        frequency: "94.1 FM",
      },
      {
        market: "Grand Forks",
        latitude: "47.9252568",
        longitude: "-97.0328547",
        station: "KNOX",
        frequency: "1310 AM",
      },
      {
        market: "Grand Forks",
        latitude: "47.9252568",
        longitude: "-97.0328547",
        station: "FM Signal",
        frequency: "103.3 FM",
      },
      {
        market: "Grand Forks",
        latitude: "47.9252568",
        longitude: "-97.0328547",
        station: "FM Signal",
        frequency: "107.9 FM",
      },
      {
        market: "Jamestown",
        latitude: "46.9105438",
        longitude: "-98.7084357",
        station: "KQDJ",
        frequency: "1400 AM",
      },
      {
        market: "Jamestown",
        latitude: "46.9105438",
        longitude: "-98.7084357",
        station: "FM Signal",
        frequency: "107.1 FM",
      },
      {
        market: "Mayville",
        latitude: "47.4980408",
        longitude: "-97.3245354",
        station: "KMAV",
        frequency: "105.5 FM",
      },
      {
        market: "Minot",
        latitude: "48.2329668",
        longitude: "-101.2922906",
        station: "KCJB",
        frequency: "910 AM",
      },
      {
        market: "Oakes",
        latitude: "46.1385786",
        longitude: "-98.0903794",
        station: "KDDR",
        frequency: "1220 AM",
      },
      {
        market: "Oakes",
        latitude: "46.1385786",
        longitude: "-98.0903794",
        station: "FM Signal",
        frequency: "100.3 FM",
      },
      {
        market: "Valley City",
        latitude: "46.9233129",
        longitude: "-98.0031547",
        station: "KOVC",
        frequency: "1490 AM",
      },
      {
        market: "Valley City",
        latitude: "46.9233129",
        longitude: "-98.0031547",
        station: "FM Signal",
        frequency: "96.3 FM",
      },
      {
        market: "Wahpeton",
        latitude: "46.2652367",
        longitude: "-96.6059072",
        station: "KBMW",
        frequency: "1450 AM",
      },
      {
        market: "Wahpeton",
        latitude: "46.2652367",
        longitude: "-96.6059072",
        station: "FM Signal",
        frequency: "94.3 FM",
      },
      {
        market: "Aberdeen",
        latitude: "45.4646985",
        longitude: "-98.4864829",
        station: "KGIM",
        frequency: "1420 AM",
      },
      {
        market: "Huron",
        latitude: "44.3633173",
        longitude: "-98.2142572",
        station: "KOKK",
        frequency: "1210 AM",
      },
      {
        market: "Huron",
        latitude: "44.3633173",
        longitude: "-98.2142572",
        station: "FM Signal",
        frequency: "105.1 FM",
      },
      {
        market: "Pierre",
        latitude: "44.3667876",
        longitude: "-100.3537522",
        station: "KGFX",
        frequency: "1060 AM",
      },
      {
        market: "Pierre",
        latitude: "44.3667876",
        longitude: "-100.3537522",
        station: "FM Signal",
        frequency: "103.1 FM",
      },
      {
        market: "Sioux Falls",
        latitude: "43.5460223",
        longitude: "-96.731265",
        station: "KSOO",
        frequency: "1000 AM",
      },
      {
        market: "Sioux Falls",
        latitude: "43.5460223",
        longitude: "-96.731265",
        station: "FM Signal",
        frequency: "102.3 FM",
      },
      {
        market: "Watertown",
        latitude: "44.8994088",
        longitude: "-97.1150732",
        station: "KWAT",
        frequency: "950 AM",
      },
      {
        market: "Yankton",
        latitude: "42.8711088",
        longitude: "-97.3972813",
        station: "WNAX",
        frequency: "570 AM",
      },
      {
        market: "Yankton",
        latitude: "42.8711088",
        longitude: "-97.3972813",
        station: "FM Signal",
        frequency: "96.9 FM",
      },
      {
        market: "Eau Claire",
        latitude: "44.811349",
        longitude: "-91.4984941",
        station: "WMEQ",
        frequency: "106.3 FM",
      },
      {
        market: "Eau Claire",
        latitude: "44.811349",
        longitude: "-91.4984941",
        station: "FM Signal",
        frequency: "98.7 FM",
      },
    ],
    "New York Yankees": [
      {
        market: "Honolulu",
        latitude: "21.3098845",
        longitude: "-157.8581401",
        station: "KHKA\nKKEA",
        frequency: "1420 AM\n1500 AM",
      },
      {
        market: "Newark",
        latitude: "43.0467301",
        longitude: "-77.0952516",
        station: "WACK\nWUUF",
        frequency: "1420 AM\n103.5 FM",
      },
      {
        market: "New Haven",
        latitude: "41.308274",
        longitude: "-72.9278835",
        station: "WELI",
        frequency: "960 AM",
      },
      {
        market: "Gloversville",
        latitude: "43.0528506",
        longitude: "-74.3437467",
        station: "WENT",
        frequency: "1340 AM",
      },
      {
        market: "Newburgh",
        latitude: "41.4459271",
        longitude: "-74.4229341",
        station: "WGNY",
        frequency: "1220 AM",
      },
      {
        market: "Tampa",
        latitude: "27.950575",
        longitude: "-82.4571776",
        station: "WWBA",
        frequency: "820 AM",
      },
      {
        market: "Rochester",
        latitude: "43.1565779",
        longitude: "-77.6088465",
        station: "WNBL",
        frequency: "107.3 FM",
      },
      {
        market: "Trumansburg",
        latitude: "42.5422939",
        longitude: "-76.6660589",
        station: "WPIE",
        frequency: "1160 AM\n107.1 FM",
      },
      {
        market: "Providence",
        latitude: "41.8239891",
        longitude: "-71.4128343",
        station: "WPRV",
        frequency: "790 AM",
      },
      {
        market: "Cobleskill",
        latitude: "42.677853",
        longitude: "-74.4854172",
        station: "WSDE",
        frequency: "1190 AM\n94.3 FM",
      },
      {
        market: "Utica",
        latitude: "43.100903",
        longitude: "-75.232664",
        station: "WTLB",
        frequency: "1310 AM",
      },
      {
        market: "Rome",
        latitude: "43.2128473",
        longitude: "-75.4557303",
        station: "WRNY",
        frequency: "1350 AM",
      },
      {
        market: "Little Falls",
        latitude: "43.0434039",
        longitude: "-74.8595957",
        station: "WIXT",
        frequency: "1230 AM",
      },
      {
        market: "Syracuse",
        latitude: "43.0481221",
        longitude: "-76.1474244",
        station: "WTKV",
        frequency: "105.5 FM",
      },
      {
        market: "Albany",
        latitude: "42.6525793",
        longitude: "-73.7562317",
        station: "WTMM",
        frequency: "104.5 FM",
      },
      {
        market: "Hartford",
        latitude: "41.7658043",
        longitude: "-72.6733723",
        station: "WUCS",
        frequency: "97.9 FM",
      },
      {
        market: "New York",
        latitude: "40.7127753",
        longitude: "-74.0059728",
        station: "WFAN",
        frequency: "660 AM",
      },
    ],
    "New York Mets": [
      {
        station: "WCBS",
        frequency: "880 AM",
        market: "New York",
        latitude: "40.7127753",
        longitude: "-74.0059728",
      },
      {
        station: "WPSL",
        frequency: "1590 AM",
        market: "Port St. Lucie",
        latitude: "27.2730492",
        longitude: "-80.3582261",
      },
    ],
    "Oakland Athletics": [
      {
        station: "KAHI",
        frequency: "104.5 FM\n950 AM",
        market: "Auburn",
        latitude: "38.8965654",
        longitude: "-121.0768901",
      },
      {
        station: "KATA",
        frequency: "1340 AM",
        market: "Eureka",
        latitude: "40.8020712",
        longitude: "-124.1636729",
      },
      {
        station: "KDAC",
        frequency: "104.1 FM\n1230 AM",
        market: "Ft. Bragg",
        latitude: "39.445723",
        longitude: "-123.8052935",
      },
      {
        station: "KESP",
        frequency: "970 AM",
        market: "Modesto",
        latitude: "37.6392595",
        longitude: "-120.9970014",
      },
      {
        station: "KFPT",
        frequency: "790 AM",
        market: "Fresno",
        latitude: "36.7377981",
        longitude: "-119.7871247",
      },
      {
        station: "KHTK",
        frequency: "1140 AM",
        market: "Sacramento",
        latitude: "38.5815719",
        longitude: "-121.4943996",
      },
      {
        station: "KNRO",
        frequency: "1400 AM\n103.9 FM",
        market: "Redding",
        latitude: "40.5865396",
        longitude: "-122.3916754",
      },
      {
        station: "KPOD",
        frequency: "1240 AM\n106.7 FM",
        market: "Crescent City",
        latitude: "41.7557501",
        longitude: "-124.2025913",
      },
      {
        station: "KRKC",
        frequency: "1490 AM",
        market: "King City",
        latitude: "36.2127439",
        longitude: "-121.1260287",
      },
      {
        station: "KXBX",
        frequency: "1270 AM",
        market: "Lakeport",
        latitude: "39.0429506",
        longitude: "-122.9158293",
      },
      {
        station: "KIQI",
        frequency: "1010 AM",
        market: "San Francisco",
        latitude: "37.7749295",
        longitude: "-122.4194155",
      },
      {
        station: "KATD",
        frequency: "990 AM",
        market: "Pittsburg",
        latitude: "38.0279762",
        longitude: "-121.8846806",
      },
      {
        station: "KUKI",
        frequency: "1400 AM\n103.3 FM",
        market: "Ukiah",
        latitude: "39.1501709",
        longitude: "-123.2077831",
      },
      {
        station: "BLOOMBERG",
        frequency: "960 AM",
        market: "Bay Area",
        latitude: "37.8271784",
        longitude: "-122.2913078",
      },
      {
        station: "BLOOMBERG",
        frequency: "103.7 FM",
        market: "Bay Area",
        latitude: "37.8271784",
        longitude: "-122.2913078",
      },
      {
        station: "KPAY",
        frequency: "1290 AM",
        market: "Chico",
        latitude: "39.7284944",
        longitude: "-121.8374777",
      },
    ],
    "Philadelphia Phillies": [
      {
        station: "WTTM",
        market: "Lindenwold",
        latitude: "39.8201681",
        longitude: "-74.9851678",
        frequency: "1680 AM",
      },
      {
        station: "WIP",
        market: "Philadelphia",
        latitude: "39.9525839",
        longitude: "-75.1652215",
        frequency: "94.1 FM",
      },
      {
        station: "WSAN",
        market: "Allentown",
        latitude: "40.6022939",
        longitude: "-75.4714098",
        frequency: "1470 AM",
      },
      {
        station: "WEST",
        market: "Easton",
        latitude: "40.688432",
        longitude: "-75.2207323",
        frequency: "1400 AM",
      },
      {
        station: "WMID",
        market: "Atlantic City",
        latitude: "39.3642834",
        longitude: "-74.4229266",
        frequency: "1340 AM",
      },
      {
        station: "WWAC",
        market: "Ocean City",
        latitude: "39.3160641",
        longitude: "-74.6053047",
        frequency: "1020 AM",
      },
      {
        station: "WHLM",
        market: "Bloomsburg",
        latitude: "41.003698",
        longitude: "-76.4549457",
        frequency: "930 AM",
      },
      {
        station: "WGET",
        market: "Gettysburg",
        latitude: "39.8309293",
        longitude: "-77.2310955",
        frequency: "1320 AM",
      },
      {
        station: "WNPV",
        market: "Lansdale",
        latitude: "40.2414952",
        longitude: "-75.2837862",
        frequency: "1440 AM",
      },
      {
        station: "WLBR",
        market: "Lebanon",
        latitude: "40.3409251",
        longitude: "-76.4113497",
        frequency: "1270 AM",
      },
      {
        station: "WBCB",
        market: "Levittown",
        latitude: "40.1551096",
        longitude: "-74.8287747",
        frequency: "1490 AM",
      },
      {
        station: "WPPA",
        market: "Pottsville",
        latitude: "40.6856456",
        longitude: "-76.195499",
        frequency: "1360 AM",
      },
      {
        station: "WEEU",
        market: "Reading",
        latitude: "40.3356483",
        longitude: "-75.9268747",
        frequency: "830 AM",
      },
      {
        station: "WEJL",
        market: "Scranton",
        latitude: "41.408969",
        longitude: "-75.6624122",
        frequency: "630 AM",
      },
      {
        station: "WKOK",
        market: "Sunbury",
        latitude: "40.862585",
        longitude: "-76.7944104",
        frequency: "1070 AM",
      },
      {
        station: "WCMC",
        market: "Wildwood",
        latitude: "38.9917797",
        longitude: "-74.8148894",
        frequency: "1230 AM",
      },
      {
        station: "WZXL",
        market: "Wildwood",
        latitude: "38.9917797",
        longitude: "-74.8148894",
        frequency: "100.7 FM",
      },
      {
        station: "WEZW",
        market: "Wildwood Crest",
        latitude: "38.9748351",
        longitude: "-74.8335015",
        frequency: "93.1 FM",
      },
      {
        station: "WFUZ",
        market: "Wilkes-Barre",
        latitude: "41.2459149",
        longitude: "-75.8813075",
        frequency: "1240 AM",
      },
      {
        station: "WRAK",
        market: "Williamsport",
        latitude: "41.2411897",
        longitude: "-77.0010786",
        frequency: "1400 AM",
      },
      {
        station: "WDEL",
        market: "Wilmington",
        latitude: "39.744655",
        longitude: "-75.5483909",
        frequency: "1150 AM",
      },
      {
        station: "WDEL",
        market: "Canton",
        latitude: "40.0583238",
        longitude: "-74.4056612",
        frequency: "101.7 FM",
      },
      {
        station: "WGMD",
        market: "Rehoboth Beach",
        latitude: "38.7209454",
        longitude: "-75.0760137",
        frequency: "92.7 FM",
      },
    ],
    "Pittsburgh Pirates": [
      {
        station: "KDKA",
        frequency: "93.7 FM",
        market: "Pittsburgh",
        latitude: "40.4406248",
        longitude: "-79.9958864",
      },
      {
        station: "KDKA",
        frequency: "1020 AM",
        market: "Pittsburgh",
        latitude: "40.4406248",
        longitude: "-79.9958864",
      },
      {
        station: "WTNA",
        frequency: "1430 AM",
        market: "Altoona",
        latitude: "40.5186809",
        longitude: "-78.3947359",
      },
      {
        station: "W259DG",
        frequency: "99.7 FM",
        market: "Altoona",
        latitude: "40.5186809",
        longitude: "-78.3947359",
      },
      {
        station: "WMBA",
        frequency: "1460 AM",
        market: "Ambridge",
        latitude: "40.5892339",
        longitude: "-80.22506",
      },
      {
        station: "W239CR",
        frequency: "95.7 FM",
        market: "Ambridge",
        latitude: "40.5892339",
        longitude: "-80.22506",
      },
      {
        station: "WBVP",
        frequency: "1230 AM",
        market: "Beaver Falls",
        latitude: "40.7520097",
        longitude: "-80.3192295",
      },
      {
        station: "W257EA",
        frequency: "99.3 FM",
        market: "Beaver Falls",
        latitude: "40.7520097",
        longitude: "-80.3192295",
      },
      {
        station: "WESB",
        frequency: "1490 AM",
        market: "Bradford",
        latitude: "41.9564953",
        longitude: "-78.649163",
      },
      {
        station: "W298CM",
        frequency: "107.5 FM",
        market: "Bradford",
        latitude: "41.9564953",
        longitude: "-78.649163",
      },
      {
        station: "WISR",
        frequency: "680 AM",
        market: "Butler",
        latitude: "40.8611755",
        longitude: "-79.8953328",
      },
      {
        station: "W298CW",
        frequency: "107.5 FM",
        market: "Butler",
        latitude: "40.8611755",
        longitude: "-79.8953328",
      },
      {
        station: "WKAZ",
        frequency: "680 AM",
        market: "Charleston",
        latitude: "38.3498195",
        longitude: "-81.6326234",
      },
      {
        station: "W235BX",
        frequency: "94.9 FM",
        market: "Colliers",
        latitude: "40.3668437",
        longitude: "-80.5389724",
      },
      {
        station: "WFRM",
        frequency: "600 AM",
        market: "Coudersport",
        latitude: "41.7747878",
        longitude: "-78.0205602",
      },
      {
        station: "W243EB",
        frequency: "96.5 FM",
        market: "Coudersport",
        latitude: "41.7747878",
        longitude: "-78.0205602",
      },
      {
        station: "WCBC",
        frequency: "1270 AM",
        market: "Cumberland",
        latitude: "39.6528654",
        longitude: "-78.7625185",
      },
      {
        station: "W276DQ",
        frequency: "103.1 FM",
        market: "Cumberland",
        latitude: "39.6528654",
        longitude: "-78.7625185",
      },
      {
        station: "WCED",
        frequency: "1420 AM",
        market: "Dubois",
        latitude: "41.1210115",
        longitude: "-78.7583257",
      },
      {
        station: "W259DC",
        frequency: "99.7 FM",
        market: "Dubois",
        latitude: "41.1210115",
        longitude: "-78.7583257",
      },
      {
        station: "W300BR",
        frequency: "107.9 FM",
        market: "Dubois",
        latitude: "41.1210115",
        longitude: "-78.7583257",
      },
      {
        station: "WJET",
        frequency: "1400 AM",
        market: "Erie",
        latitude: "42.1292241",
        longitude: "-80.085059",
      },
      {
        station: "W244DX",
        frequency: "96.7 FM",
        market: "Erie",
        latitude: "42.1292241",
        longitude: "-80.085059",
      },
      {
        station: "WBKE",
        frequency: "1490 AM",
        market: "Fairmont",
        latitude: "39.4850848",
        longitude: "-80.1425781",
      },
      {
        station: "W252EF",
        frequency: "98.3 FM",
        market: "Fairmont",
        latitude: "39.4850848",
        longitude: "-80.1425781",
      },
      {
        station: "WFRA",
        frequency: "1450 AM",
        market: "Franklin",
        latitude: "41.3978358",
        longitude: "-79.8314437",
      },
      {
        station: "W251CL",
        frequency: "98.1 FM",
        market: "Franklin",
        latitude: "41.3978358",
        longitude: "-79.8314437",
      },
      {
        station: "WRVC",
        frequency: "930 AM",
        market: "Huntington",
        latitude: "38.4192496",
        longitude: "-82.445154",
      },
      {
        station: "W231BS",
        frequency: "94.1 FM",
        market: "Huntington",
        latitude: "38.4192496",
        longitude: "-82.445154",
      },
      {
        station: "WCCS",
        frequency: "1160 AM",
        market: "Indiana",
        latitude: "40.6214551",
        longitude: "-79.1525349",
      },
      {
        station: "W266CZ",
        frequency: "101.1 FM",
        market: "Indiana",
        latitude: "40.6214551",
        longitude: "-79.1525349",
      },
      {
        station: "WEJS",
        frequency: "1600 AM",
        market: "Jersey Shore",
        latitude: "41.2020171",
        longitude: "-77.2644152",
      },
      {
        station: "W281CI",
        frequency: "104.1 FM",
        market: "Jersey Shore",
        latitude: "41.2020171",
        longitude: "-77.2644152",
      },
      {
        station: "WNTJ",
        frequency: "1490 AM",
        market: "Johnstown",
        latitude: "40.3267407",
        longitude: "-78.9219698",
      },
      {
        station: "W283CX",
        frequency: "104.5 FM",
        market: "Johnstown",
        latitude: "40.3267407",
        longitude: "-78.9219698",
      },
      {
        station: "WKLP",
        frequency: "1390 AM",
        market: "Keyser",
        latitude: "39.4409277",
        longitude: "-78.9739156",
      },
      {
        station: "WCNS",
        frequency: "1480 AM",
        market: "Latrobe",
        latitude: "40.3211808",
        longitude: "-79.3794811",
      },
      {
        station: "W298DH",
        frequency: "107.5 FM",
        market: "Latrobe",
        latitude: "40.3211808",
        longitude: "-79.3794811",
      },
      {
        station: "W224AI",
        frequency: "92.7 FM",
        market: "Loyalsock Township",
        latitude: "41.2511146",
        longitude: "-76.972031",
      },
      {
        station: "WMGW",
        frequency: "1490 AM",
        market: "Meadville",
        latitude: "41.6414438",
        longitude: "-80.1514484",
      },
      {
        station: "W264DK",
        frequency: "100.7 FM",
        market: "Meadville",
        latitude: "41.6414438",
        longitude: "-80.1514484",
      },
      {
        station: "WLLF",
        frequency: "96.7 FM",
        market: "Mercer",
        latitude: "41.2270025",
        longitude: "-80.2397852",
      },
      {
        station: "WAJR",
        frequency: "1440 AM",
        market: "Morgantown",
        latitude: "39.629526",
        longitude: "-79.9558968",
      },
      {
        station: "W283CR",
        frequency: "104.5 FM",
        market: "Morgantown",
        latitude: "39.629526",
        longitude: "-79.9558968",
      },
      {
        station: "WUZZ",
        frequency: "1280 AM",
        market: "New Castle",
        latitude: "41.0036719",
        longitude: "-80.347009",
      },
      {
        station: "W248DJ",
        frequency: "97.5 FM",
        market: "New Castle",
        latitude: "41.0036719",
        longitude: "-80.347009",
      },
      {
        station: "WETZ",
        frequency: "1330 AM",
        market: "New Martinsville",
        latitude: "39.6445214",
        longitude: "-80.8575993",
      },
      {
        station: "W226BE",
        frequency: "93.1 FM",
        market: "New Martinsville",
        latitude: "39.6445214",
        longitude: "-80.8575993",
      },
      {
        station: "W283DH",
        frequency: "104.5 FM",
        market: "New Martinsville",
        latitude: "39.6445214",
        longitude: "-80.8575993",
      },
      {
        station: "WPXZ",
        frequency: "104.1 FM",
        market: "Punxsutawney",
        latitude: "40.9436753",
        longitude: "-78.9708668",
      },
      {
        station: "WDDH",
        frequency: "97.5 FM",
        market: "Ridgway",
        latitude: "41.4203391",
        longitude: "-78.7286388",
      },
      {
        station: "W244CH",
        frequency: "96.7 FM",
        market: "Rockton",
        latitude: "41.0756158",
        longitude: "-78.6530827",
      },
      {
        station: "WNTI",
        frequency: "990 AM",
        market: "Somerset",
        latitude: "40.008411",
        longitude: "-79.0780831",
      },
      {
        station: "W278CR",
        frequency: "103.5 FM",
        market: "Somerset",
        latitude: "40.008411",
        longitude: "-79.0780831",
      },
      {
        station: "WJAW",
        frequency: "630 AM",
        market: "St. Marys",
        latitude: "39.3962109",
        longitude: "-81.2013363",
      },
      {
        station: "W225CW",
        frequency: "92.9 FM",
        market: "St. Marys",
        latitude: "39.3962109",
        longitude: "-81.2013363",
      },
      {
        station: "WQWK",
        frequency: "1450 AM",
        market: "State College",
        latitude: "40.7933949",
        longitude: "-77.8600012",
      },
      {
        station: "W279DK",
        frequency: "103.7 FM",
        market: "State College",
        latitude: "40.7933949",
        longitude: "-77.8600012",
      },
      {
        station: "WCDK",
        frequency: "106.3 FM",
        market: "Steubenville",
        latitude: "40.3697905",
        longitude: "-80.6339638",
      },
      {
        station: "WTIV",
        frequency: "1230 AM",
        market: "Titusville",
        latitude: "41.6270036",
        longitude: "-79.6736631",
      },
      {
        station: "W287DC",
        frequency: "105.3 FM",
        market: "Titusville",
        latitude: "41.6270036",
        longitude: "-79.6736631",
      },
      {
        station: "WTRN",
        frequency: "1340 AM",
        market: "Tyrone",
        latitude: "40.6706184",
        longitude: "-78.2386244",
      },
      {
        station: "W264BZ",
        frequency: "100.7 FM",
        market: "Tyrone",
        latitude: "40.6706184",
        longitude: "-78.2386244",
      },
      {
        station: "WMBS",
        frequency: "590 AM",
        market: "Uniontown",
        latitude: "39.900076",
        longitude: "-79.7164326",
      },
      {
        station: "W266DB",
        frequency: "101.1 FM",
        market: "Uniontown",
        latitude: "39.900076",
        longitude: "-79.7164326",
      },
      {
        station: "WICU",
        frequency: "1310 AM",
        market: "Warren",
        latitude: "41.8439486",
        longitude: "-79.1450445",
      },
      {
        station: "W244DY",
        frequency: "96.7 FM",
        market: "Warren",
        latitude: "41.8439486",
        longitude: "-79.1450445",
      },
      {
        station: "WJPA",
        frequency: "1450 AM",
        market: "Washington",
        latitude: "40.17396",
        longitude: "-80.2461714",
      },
      {
        station: "WEIR",
        frequency: "1430 AM",
        market: "Weirton",
        latitude: "40.4189566",
        longitude: "-80.5895167",
      },
      {
        station: "W271CZ",
        frequency: "102.1 FM",
        market: "Weirton",
        latitude: "40.4189566",
        longitude: "-80.5895167",
      },
      {
        station: "WBBD",
        frequency: "1400 AM",
        market: "Wheeling",
        latitude: "40.0639616",
        longitude: "-80.7209149",
      },
      {
        station: "W280EW",
        frequency: "103.9 FM",
        market: "Wheeling",
        latitude: "40.0639616",
        longitude: "-80.7209149",
      },
      {
        station: "WLYC",
        frequency: "1050 AM",
        market: "Williamsport",
        latitude: "41.2411897",
        longitude: "-77.0010786",
      },
      {
        station: "WBBW",
        frequency: "1240 AM",
        market: "Youngstown",
        latitude: "41.0997803",
        longitude: "-80.6495194",
      },
    ],
    "San Diego Padres": [
      {
        station: "The Fan",
        frequency: "97.3 FM",
        market: "San Diego",
        latitude: "32.715738",
        longitude: "-117.1610838",
      },
      {
        station: "KXFG",
        frequency: "92.9 FM",
        market: "Temecula",
        latitude: "33.4936391",
        longitude: "-117.1483648",
      },
      {
        station: "KKGX",
        frequency: "920 AM\n99.1 AM",
        market: "Palm Springs",
        latitude: "33.8302961",
        longitude: "-116.5452921",
      },
      {
        station: "The Fan",
        frequency: "98.5 HD2 FM",
        market: "Las Vegas",
        latitude: "36.171563",
        longitude: "-115.1391009",
      },
    ],
    "San Francisco Giants": [
      {
        station: "KNBR",
        frequency: "104.5 FM",
        market: "San Francisco",
        latitude: "37.7749295",
        longitude: "-122.4194155",
      },
      {
        station: "KNBR",
        frequency: "680 AM",
        market: "San Francisco",
        latitude: "37.7749295",
        longitude: "-122.4194155",
      },
      {
        station: "KEKA",
        frequency: "101.5 FM",
        market: "Eureka",
        latitude: "40.8020712",
        longitude: "-124.1636729",
      },
      {
        station: "K265BT",
        frequency: "100.9 FM",
        market: "Arcata",
        latitude: "40.8665166",
        longitude: "-124.0828396",
      },
      {
        station: "KFIG",
        frequency: "1430 AM",
        market: "Fresno",
        latitude: "36.7377981",
        longitude: "-119.7871247",
      },
      {
        station: "KFPT",
        frequency: "790 AM",
        market: "Fresno",
        latitude: "36.7377981",
        longitude: "-119.7871247",
      },
      {
        station: "KHKA",
        frequency: "1500 AM",
        market: "Honolulu",
        latitude: "21.3098845",
        longitude: "-157.8581401",
      },
      {
        station: "K272GC",
        frequency: "102.3 FM",
        market: "Honolulu",
        latitude: "21.3098845",
        longitude: "-157.8581401",
      },
      {
        station: "KKJL",
        frequency: "1400 AM",
        market: "San Luis Obispo",
        latitude: "35.2827524",
        longitude: "-120.6596156",
      },
      {
        station: "K293AW",
        frequency: "106.5 FM",
        market: "San Luis Obispo",
        latitude: "35.2827524",
        longitude: "-120.6596156",
      },
      {
        station: "KKXS",
        frequency: "96.1 FM",
        market: "Redding",
        latitude: "40.5865396",
        longitude: "-122.3916754",
      },
      {
        station: "KPUA",
        frequency: "670 AM",
        market: "Hilo",
        latitude: "19.7216023",
        longitude: "-155.084887",
      },
      {
        station: "K253CR",
        frequency: "98.5 FM",
        market: "Hilo",
        latitude: "19.7216023",
        longitude: "-155.084887",
      },
      {
        station: "KUNK",
        frequency: "92.7 FM",
        market: "Mendocino",
        latitude: "39.3076744",
        longitude: "-123.7994591",
      },
      {
        station: "K244AH",
        frequency: "96.7 FM",
        market: "Ukiah",
        latitude: "39.1501709",
        longitude: "-123.2077831",
      },
    ],
    "Seattle Mariners": [
      {
        station: "KXRO",
        frequency: "1320 AM",
        market: "Aberdeen",
        latitude: "46.9753708",
        longitude: "-123.8157218",
      },
      {
        station: "KPUG",
        frequency: "1170 AM",
        market: "Bellingham",
        latitude: "48.7519112",
        longitude: "-122.4786854",
      },
      {
        station: "KELA",
        frequency: "1470 AM",
        market: "Centralia",
        latitude: "46.7162136",
        longitude: "-122.9542972",
      },
      {
        station: "KMAX",
        frequency: "840 AM",
        market: "Colfax",
        latitude: "46.7297771",
        longitude: "-117.1817377",
      },
      {
        station: "KCRK",
        frequency: "92.1 FM",
        market: "Colville",
        latitude: "48.5449971",
        longitude: "-117.9009544",
      },
      {
        station: "KXLE",
        frequency: "1240 AM",
        market: "Ellensburg",
        latitude: "46.9965144",
        longitude: "-120.5478474",
      },
      {
        station: "KLOG",
        frequency: "1490 AM",
        market: "Kelso",
        latitude: "46.146779",
        longitude: "-122.9084445",
      },
      {
        station: "KWIQ",
        frequency: "1020 AM",
        market: "Moses Lake",
        latitude: "47.3176385",
        longitude: "-119.5536491",
      },
      {
        station: "KBRC",
        frequency: "1430 AM",
        market: "Mount Vernon",
        latitude: "48.4241961",
        longitude: "-121.7114362",
      },
      {
        station: "KONP",
        frequency: "1450 AM",
        market: "Port Angeles",
        latitude: "48.118146",
        longitude: "-123.4307413",
      },
      {
        station: "KIRO",
        frequency: "710 AM\n 97.3 FM",
        market: "Seattle",
        latitude: "47.6062095",
        longitude: "-122.3320708",
      },
      {
        station: "KMAS",
        frequency: "1030 AM",
        market: "Shelton",
        latitude: "47.0468547",
        longitude: "-122.9527886",
      },
      {
        station: "KXLY",
        frequency: "920 AM",
        market: "Spokane",
        latitude: "47.6587802",
        longitude: "-117.4260466",
      },
      {
        station: "KIRO",
        frequency: "97.3 FM",
        market: "Tacoma",
        latitude: "47.2528768",
        longitude: "-122.4442906",
      },
      {
        station: "KJOX",
        frequency: "1340 AM",
        market: "Tri-Cities",
        latitude: "46.2349999",
        longitude: "-119.2233014",
      },
      {
        station: "KGDC",
        frequency: "1320 AM",
        market: "Walla Walla",
        latitude: "46.0645809",
        longitude: "-118.3430209",
      },
      {
        station: "KKRT",
        frequency: "900 AM",
        market: "Wenatchee",
        latitude: "47.4234599",
        longitude: "-120.3103494",
      },
      {
        station: "KBBO",
        frequency: "1390 & FM 104.5 AM",
        market: "Yakima",
        latitude: "46.6020711",
        longitude: "-120.5058987",
      },
    ],
    "St. Louis Cardinals": [
      {
        market: "Batesville",
        station: "KBTA",
        frequency: "1340 AM",
        latitude: "35.7696862",
        longitude: "-91.6409431",
      },
      {
        market: "Conway",
        station: "KCON",
        frequency: "92.7 FM",
        latitude: "35.0917506",
        longitude: "-92.4366522",
      },
      {
        market: "Crossett",
        station: "KAGH",
        frequency: "800 AM\n104.9 FM",
        latitude: "33.1240976",
        longitude: "-91.9611917",
      },
      {
        market: "Gravette",
        station: "KURM",
        frequency: "100.3 FM",
        latitude: "36.4222948",
        longitude: "-94.4518839",
      },
      {
        market: "Harrison",
        station: "KHOZ",
        frequency: "900 AM\n94.9 FM",
        latitude: "36.2297712",
        longitude: "-93.107405",
      },
      {
        market: "Helena",
        station: "KFFA",
        frequency: "1360 AM",
        latitude: "34.5291253",
        longitude: "-90.5900273",
      },
      {
        market: "Jonesboro",
        station: "KWHF",
        frequency: "95.9 FM",
        latitude: "35.835696",
        longitude: "-90.7051346",
      },
      {
        market: "Morrilton",
        station: "KVOM",
        frequency: "800 AM",
        latitude: "35.1526163",
        longitude: "-92.7437844",
      },
      {
        market: "Morrilton",
        station: "K223CR",
        frequency: "92.5 FM",
        latitude: "35.1526163",
        longitude: "-92.7437844",
      },
      {
        market: "Mountain Home",
        station: "KTLO",
        frequency: "1240 AM",
        latitude: "36.3400904",
        longitude: "-92.385217",
      },
      {
        market: "Pocahontas",
        station: "KPOC",
        frequency: "104.1 FM",
        latitude: "36.2632561",
        longitude: "-90.9716953",
      },
      {
        market: "Salem",
        station: "KSAR",
        frequency: "92.3 FM",
        latitude: "36.3710114",
        longitude: "-91.8227",
      },
      {
        market: "Stuttgart",
        station: "KWAK",
        frequency: "1240 AM\n102.7 FM",
        latitude: "34.4940567",
        longitude: "-91.5580953",
      },
      {
        market: "Van Buren",
        station: "KHGG",
        frequency: "1580 AM",
        latitude: "35.4589055",
        longitude: "-94.3565578",
      },
      {
        market: "Aledo",
        station: "WRMJ",
        frequency: "102.3 FM",
        latitude: "41.1997568",
        longitude: "-90.7493063",
      },
      {
        market: "Bloomington",
        station: "WJBC",
        frequency: "1230 AM\n102.1 FM",
        latitude: "40.4842027",
        longitude: "-88.9936873",
      },
      {
        market: "Carmi",
        station: "WROY",
        frequency: "1460 AM\n93.3 FM",
        latitude: "38.0908796",
        longitude: "-88.1586488",
      },
      {
        market: "Champaign",
        station: "WSJK",
        frequency: "93.5 FM",
        latitude: "40.1164204",
        longitude: "-88.2433829",
      },
      {
        market: "Danville",
        station: "WDAN",
        frequency: "1490 AM",
        latitude: "40.124481",
        longitude: "-87.6300207",
      },
      {
        market: "Decatur",
        station: "W277DB",
        frequency: "103.3 FM",
        latitude: "39.8403147",
        longitude: "-88.9548001",
      },
      {
        market: "Decatur",
        station: "WSOY",
        frequency: "1340 AM",
        latitude: "39.8403147",
        longitude: "-88.9548001",
      },
      {
        market: "Effingham",
        station: "WHQQ",
        frequency: "98.9 FM",
        latitude: "39.1200418",
        longitude: "-88.5433829",
      },
      {
        market: "Fairfield",
        station: "WOKZ",
        frequency: "105.9 FM",
        latitude: "38.378937",
        longitude: "-88.3597683",
      },
      {
        market: "Galesburg",
        station: "WGIL",
        frequency: "1400 AM\n93.7 FM",
        latitude: "40.9478158",
        longitude: "-90.3712395",
      },
      {
        market: "Harrisburg",
        station: "WEBQ",
        frequency: "102.3 FM",
        latitude: "37.7383812",
        longitude: "-88.540607",
      },
      {
        market: "Jacksonville",
        station: "WEAI",
        frequency: "107.1 FM",
        latitude: "39.733936",
        longitude: "-90.2290098",
      },
      {
        market: "Litchfield",
        station: "WSMI",
        frequency: "1540 AM\n106.9 FM",
        latitude: "39.1753256",
        longitude: "-89.6542579",
      },
      {
        market: "Marion",
        station: "WHET",
        frequency: "97.7 FM",
        latitude: "37.7306054",
        longitude: "-88.9331256",
      },
      {
        market: "Mount Vernon",
        station: "WMIX",
        frequency: "94.1 FM",
        latitude: "38.3172714",
        longitude: "-88.9031201",
      },
      {
        market: "Murphysboro",
        station: "WINI",
        frequency: "1420 AM\n93.5 FM",
        latitude: "37.7644952",
        longitude: "-89.3350888",
      },
      {
        market: "Olney",
        station: "WVLN\nWSEI",
        frequency: "740 AM\n92.9 FM",
        latitude: "38.7308811",
        longitude: "-88.0853154",
      },
      {
        market: "Pana",
        station: "WTIM",
        frequency: "107.9 FM",
        latitude: "39.3889311",
        longitude: "-89.0800809",
      },
      {
        market: "Peoria",
        station: "WIRL\nWPBG",
        frequency: "1290 AM\n95.9 FM",
        latitude: "40.6936488",
        longitude: "-89.5889864",
      },
      {
        market: "Pittsfield",
        station: "WBBA",
        frequency: "97.5 FM",
        latitude: "39.6078254",
        longitude: "-90.8051289",
      },
      {
        market: "Quincy",
        station: "WGEM",
        frequency: "105.1 FM",
        latitude: "39.9356016",
        longitude: "-91.4098726",
      },
      {
        market: "Robinson",
        station: "WTAY",
        frequency: "1570 AM\n94.3 FM",
        latitude: "39.0053201",
        longitude: "-87.7391935",
      },
      {
        market: "Shelbyville",
        station: "WTIM",
        frequency: "107.5 FM",
        latitude: "39.4064284",
        longitude: "-88.7900689",
      },
      {
        market: "Sparta",
        station: "W247CP",
        frequency: "97.3 FM",
        latitude: "38.1231053",
        longitude: "-89.7017658",
      },
      {
        market: "Sparta",
        station: "WHCO",
        frequency: "1230 AM",
        latitude: "38.1231053",
        longitude: "-89.7017658",
      },
      {
        market: "Springfield",
        station: "WTAX",
        frequency: "1240 AM\n93.5 FM",
        latitude: "39.7817213",
        longitude: "-89.6501481",
      },
      {
        market: "Taylorville",
        station: "WTIM",
        frequency: "870 AM\n96.1 FM",
        latitude: "39.548935",
        longitude: "-89.294533",
      },
      {
        market: "Evansville",
        station: "WREF",
        frequency: "97.7 FM",
        latitude: "37.9715592",
        longitude: "-87.5710898",
      },
      {
        market: "Jasper",
        station: "WQKZ",
        frequency: "98.5 FM",
        latitude: "38.3914418",
        longitude: "-86.9311094",
      },
      {
        market: "Linton",
        station: "WQTY",
        frequency: "93.3 FM",
        latitude: "39.0347666",
        longitude: "-87.1658458",
      },
      {
        market: "Mt. Vernon",
        station: "WYFX",
        frequency: "106.7 FM",
        latitude: "37.9322662",
        longitude: "-87.8950267",
      },
      {
        market: "Vincennes",
        station: "WAOV",
        frequency: "1450 AM\n97.7 FM",
        latitude: "38.677269",
        longitude: "-87.5286325",
      },
      {
        market: "Atlantic",
        station: "KJAN",
        frequency: "1220 AM\n101.1 FM",
        latitude: "41.4036007",
        longitude: "-95.0138776",
      },
      {
        market: "Bloomfield",
        station: "KUDV",
        frequency: "106.9 FM",
        latitude: "40.7516884",
        longitude: "-92.4149074",
      },
      {
        market: "Keokuk",
        station: "KOKX",
        frequency: "1310 AM\n93.3 FM",
        latitude: "40.4044731",
        longitude: "-91.3963966",
      },
      {
        market: "Maquoketa",
        station: "KMAQ",
        frequency: "95.1 FM",
        latitude: "42.0689088",
        longitude: "-90.6656914",
      },
      {
        market: "Newton",
        station: "KCOB",
        frequency: "1280 AM\n95.9 FM",
        latitude: "41.7033177",
        longitude: "-93.0573533",
      },
      {
        market: "Shenandoah",
        station: "KMA",
        frequency: "99.1 FM",
        latitude: "40.7667389",
        longitude: "-95.3761506",
      },
      {
        market: "Benton",
        station: "WCBL",
        frequency: "99.1 FM",
        latitude: "36.8572781",
        longitude: "-88.350315",
      },
      {
        market: "Madisonville",
        station: "WFMW",
        frequency: "730 AM\n94.9 FM",
        latitude: "37.3281005",
        longitude: "-87.4988882",
      },
      {
        market: "Mayfield",
        station: "WYMC",
        frequency: "1430 AM\n93.9 FM",
        latitude: "36.7417235",
        longitude: "-88.6367154",
      },
      {
        market: "Morganfield",
        station: "WMSK",
        frequency: "101.3 FM",
        latitude: "37.6833776",
        longitude: "-87.9166915",
      },
      {
        market: "Murray",
        station: "WNBS",
        frequency: "1340 AM\n97.9 FM",
        latitude: "36.6103334",
        longitude: "-88.314761",
      },
      {
        market: "Paducah",
        station: "WREZ",
        frequency: "105.5 FM",
        latitude: "37.0833893",
        longitude: "-88.6000478",
      },
      {
        market: "Wickliffe",
        station: "WGKY",
        frequency: "95.9 FM",
        latitude: "36.9647754",
        longitude: "-89.0892348",
      },
      {
        market: "Bethany",
        station: "KAAN",
        frequency: "870 AM\n103.7 FM\n95.5 FM",
        latitude: "40.2683344",
        longitude: "-94.0282861",
      },
      {
        market: "Cape Girardeau",
        station: "KYRX",
        frequency: "97.3 FM",
        latitude: "37.3058839",
        longitude: "-89.5181476",
      },
      {
        market: "Cape Girardeau",
        station: "K241CK",
        frequency: "96.1 FM",
        latitude: "37.3058839",
        longitude: "-89.5181476",
      },
      {
        market: "Cassville",
        station: "KRMO",
        frequency: "990 AM",
        latitude: "36.6770111",
        longitude: "-93.8688109",
      },
      {
        market: "Columbia",
        station: "KSSZ",
        frequency: "93.9 FM",
        latitude: "38.9517053",
        longitude: "-92.3340724",
      },
      {
        market: "Doniphan",
        station: "KOEA",
        frequency: "97.5 FM",
        latitude: "36.6208917",
        longitude: "-90.8234551",
      },
      {
        market: "Fulton",
        station: "KTGR",
        frequency: "1580 AM\n105.1 FM",
        latitude: "38.8467082",
        longitude: "-91.9479586",
      },
      {
        market: "Hannibal",
        station: "KHMO",
        frequency: "1070 AM",
        latitude: "39.7083789",
        longitude: "-91.3584816",
      },
      {
        market: "Houston",
        station: "KBTC",
        frequency: "1250 AM\n105.7 FM",
        latitude: "37.3261588",
        longitude: "-91.955988",
      },
      {
        market: "Jefferson City",
        station: "KATI",
        frequency: "94.3 FM",
        latitude: "38.5767017",
        longitude: "-92.1735164",
      },
      {
        market: "Joplin",
        station: "KZYM",
        frequency: "1230 AM",
        latitude: "37.0842271",
        longitude: "-94.513281",
      },
      {
        market: "Kennett",
        station: "KTMO",
        frequency: "106.5 FM",
        latitude: "36.2361762",
        longitude: "-90.0556493",
      },
      {
        market: "Kirksville",
        station: "KIRX",
        frequency: "1450 AM\n99.7 FM",
        latitude: "40.1947539",
        longitude: "-92.5832496",
      },
      {
        market: "Lebanon",
        station: "KJEL",
        frequency: "103.7 FM",
        latitude: "37.6805967",
        longitude: "-92.6637865",
      },
      {
        market: "Memphis",
        station: "KMEM",
        frequency: "100.5 FM",
        latitude: "40.4578119",
        longitude: "-92.1712924",
      },
      {
        market: "Mexico",
        station: "KXEO",
        frequency: "1340 AM",
        latitude: "39.1730652",
        longitude: "-91.887271",
      },
      {
        market: "Moberly",
        station: "KRES",
        frequency: "104.7 FM",
        latitude: "39.4183689",
        longitude: "-92.4382367",
      },
      {
        market: "Moberly",
        station: "KIRK",
        frequency: "99.9 FM",
        latitude: "39.4183689",
        longitude: "-92.4382367",
      },
      {
        market: "Monett",
        station: "KKBL",
        frequency: "95.9 FM",
        latitude: "36.9289502",
        longitude: "-93.9277071",
      },
      {
        market: "Nevada",
        station: "KNEM\nKNMO",
        frequency: "1240 AM\n97.5 FM\n99.9 FM",
        latitude: "37.8392052",
        longitude: "-94.3546719",
      },
      {
        market: "Park Hills",
        station: "KDBB",
        frequency: "104.3 FM",
        latitude: "37.854218",
        longitude: "-90.5181804",
      },
      {
        market: "Perryville",
        station: "KSGM",
        frequency: "980 AM",
        latitude: "37.7242202",
        longitude: "-89.8612196",
      },
      {
        market: "Poplar Bluff",
        station: "KPPL",
        frequency: "92.5 FM",
        latitude: "36.7569994",
        longitude: "-90.392888",
      },
      {
        market: "Poplar Bluff",
        station: "K231BM",
        frequency: "94.1 FM",
        latitude: "36.7569994",
        longitude: "-90.392888",
      },
      {
        market: "Rolla",
        station: "KTTR",
        frequency: "99.7 FM",
        latitude: "37.948544",
        longitude: "-91.7715303",
      },
      {
        market: "Salem",
        station: "KSMO",
        frequency: "1340 AM",
        latitude: "37.6455993",
        longitude: "-91.5359808",
      },
      {
        market: "Sedalia",
        station: "KSIS",
        frequency: "1050 AM",
        latitude: "38.7044609",
        longitude: "-93.2282613",
      },
      {
        market: "Sikeston",
        station: "KBXB",
        frequency: "97.9 FM",
        latitude: "36.876719",
        longitude: "-89.5878579",
      },
      {
        market: "Springfield",
        station: "KOMG",
        frequency: "105.1 FM",
        latitude: "37.2089572",
        longitude: "-93.2922989",
      },
      {
        market: "St. Louis (Flagship)",
        station: "KMOX",
        frequency: "1120 AM",
        latitude: "38.6270025",
        longitude: "-90.1994042",
      },
      {
        market: "St. Louis",
        station: "K254CR",
        frequency: "98.7 FM",
        latitude: "38.6270025",
        longitude: "-90.1994042",
      },
      {
        market: "Sullivan",
        station: "KTUI",
        frequency: "102.1 FM",
        latitude: "38.2081045",
        longitude: "-91.1604207",
      },
      {
        market: "Warrensburg",
        station: "KWKJ",
        frequency: "98.5 FM",
        latitude: "38.7627893",
        longitude: "-93.7360498",
      },
      {
        market: "Waynesville",
        station: "KFBD",
        frequency: "97.9 FM",
        latitude: "37.8286516",
        longitude: "-92.2007227",
      },
      {
        market: "West Plains",
        station: "KWPM",
        frequency: "1450 AM\n105.1 FM",
        latitude: "36.7281154",
        longitude: "-91.8523711",
      },
      {
        market: "Willow Springs",
        station: "KUKU",
        frequency: "100.3 FM",
        latitude: "36.9922768",
        longitude: "-91.9698742",
      },
      {
        market: "Bartlesville",
        station: "KPGM",
        frequency: "1500 AM\n99.1 FM",
        latitude: "36.7473114",
        longitude: "-95.9808179",
      },
      {
        market: "Bartlesville",
        station: "KWON",
        frequency: "1400 AM\n93.3 FM\n95.1 FM",
        latitude: "36.7473114",
        longitude: "-95.9808179",
      },
      {
        market: "Enid",
        station: "KCRC",
        frequency: "1390 AM",
        latitude: "36.3955891",
        longitude: "-97.8783911",
      },
      {
        market: "Norman",
        station: "KREF",
        frequency: "1400 AM",
        latitude: "35.2225668",
        longitude: "-97.4394777",
      },
      {
        market: "Norman",
        station: "K257DA",
        frequency: "99.3 FM",
        latitude: "35.2225668",
        longitude: "-97.4394777",
      },
      {
        market: "Ponca City",
        station: "WBBZ",
        frequency: "1230 AM",
        latitude: "36.706981",
        longitude: "-97.0855948",
      },
      {
        market: "Shawnee",
        station: "KGFF",
        frequency: "1450 AM\n100.9 FM",
        latitude: "35.3272928",
        longitude: "-96.9253004",
      },
      {
        market: "Clarksville",
        station: "WKFN",
        frequency: "540 AM\n104.1 FM",
        latitude: "36.5297706",
        longitude: "-87.3594528",
      },
      {
        market: "Jackson",
        station: "WDXI",
        frequency: "1310 AM\n103.5 FM",
        latitude: "35.6145169",
        longitude: "-88.8139469",
      },
      {
        market: "Lexington",
        station: "WZLT",
        frequency: "99.3 FM",
        latitude: "35.6509029",
        longitude: "-88.3933796",
      },
      {
        market: "Memphis",
        station: "WMC",
        frequency: "790 AM",
        latitude: "35.1495343",
        longitude: "-90.0489801",
      },
      {
        market: "Memphis",
        station: "WMFS",
        frequency: "680 AM\n92.9 FM",
        latitude: "35.1495343",
        longitude: "-90.0489801",
      },
      {
        market: "Paris",
        station: "WHNY",
        frequency: "1000 AM\n104.7 FM\n97.5 FM",
        latitude: "36.3020023",
        longitude: "-88.3267107",
      },
      {
        market: "Ripley",
        station: "WTRB",
        frequency: "1570 AM\n104.9 FM",
        latitude: "35.7453552",
        longitude: "-89.5297973",
      },
      {
        market: "Union City",
        station: "KYTN",
        frequency: "104.9 FM",
        latitude: "36.4242303",
        longitude: "-89.0570096",
      },
    ],
    "Tampa Bay Rays": [
      {
        market: "St. Petersburg",
        station: "WDAE",
        frequency: "95.3 FM\n620 AM",
        latitude: "27.7676008",
        longitude: "-82.6402915",
      },
      {
        market: "Arcadia",
        station: "WCXS",
        frequency: "106.5 FM\n1480 AM",
        latitude: "27.2158826",
        longitude: "-81.8584164",
      },
      {
        market: "Bradenton",
        station: "WWPR",
        frequency: "1490 AM",
        latitude: "27.4989278",
        longitude: "-82.5748194",
      },
      {
        market: "Brooksville",
        station: "WWJB",
        frequency: "92.3 FM\n1450 AM",
        latitude: "28.5552719",
        longitude: "-82.3878709",
      },
      {
        market: "Dade City",
        station: "WDCF",
        frequency: "102.3 FM\n1350 AM",
        latitude: "28.3647248",
        longitude: "-82.1959178",
      },
      {
        market: "Daytona Beach",
        station: "WELE",
        frequency: "1380 AM",
        latitude: "29.2108147",
        longitude: "-81.0228331",
      },
      {
        market: "Englewood",
        station: "WENG",
        frequency: "107.5 FM\n1530 AM",
        latitude: "26.9620053",
        longitude: "-82.3525952",
      },
      {
        market: "Fort Myers",
        station: "WWCN",
        frequency: "99.3 FM",
        latitude: "26.640628",
        longitude: "-81.8723084",
      },
      {
        market: "Gainesville",
        station: "WRUF",
        frequency: "98.1 FM\n850 AM",
        latitude: "29.6516344",
        longitude: "-82.3248262",
      },
      {
        market: "Homosassa",
        station: "WWJB",
        frequency: "99.9 FM",
        latitude: "28.7813722",
        longitude: "-82.6151001",
      },
      {
        market: "Jacksonville",
        station: "WYKB",
        frequency: "105.3 FM",
        latitude: "30.3321838",
        longitude: "-81.655651",
      },
      {
        market: "Naples",
        station: "WWCN",
        frequency: "99.3 FM",
        latitude: "26.1420358",
        longitude: "-81.7948103",
      },
      {
        market: "Orlando",
        station: "WYGM",
        frequency: "96.9 FM\n740 AM",
        latitude: "28.5383832",
        longitude: "-81.3789269",
      },
      {
        market: "Pascagoula",
        station: "WPMO",
        frequency: "1580 AM",
        latitude: "30.3657552",
        longitude: "-88.5561272",
      },
      {
        market: "Pensacola",
        station: "WJNZ",
        frequency: "1000 AM",
        latitude: "30.421309",
        longitude: "-87.2169149",
      },
      {
        market: "Port Charlotte",
        station: "WENG",
        frequency: "98.1 FM",
        latitude: "26.9761707",
        longitude: "-82.0906448",
      },
      {
        market: "Spring Hill",
        station: "WWJB",
        frequency: "103.9 FM",
        latitude: "28.4831682",
        longitude: "-82.5369872",
      },
      {
        market: "St. Petersburg",
        station: "WGES",
        frequency: "680 AM",
        latitude: "27.7676008",
        longitude: "-82.6402915",
      },
      {
        market: "Zephyrhills",
        station: "WZHR",
        frequency: "104.3 FM\n1400 AM",
        latitude: "28.2336196",
        longitude: "-82.1811947",
      },
    ],
    "Texas Rangers": [
      {
        station: "KZQQ",
        frequency: "1560 AM",
        market: "Abilene",
        latitude: "32.4487364",
        longitude: "-99.7331439",
      },
      {
        station: "KVLF",
        frequency: "1240 AM",
        market: "Alpine",
        latitude: "30.3584919",
        longitude: "-103.6610115",
      },
      {
        station: "KALP",
        frequency: "92.7 FM",
        market: "Alpine",
        latitude: "30.3584919",
        longitude: "-103.6610115",
      },
      {
        station: "KGNC",
        frequency: "710 AM",
        market: "Amarillo",
        latitude: "35.2219971",
        longitude: "-101.8312969",
      },
      {
        station: "KNNU",
        frequency: "92.3 FM",
        market: "Antlers",
        latitude: "34.231212",
        longitude: "-95.6202476",
      },
      {
        station: "KPYN",
        frequency: "95.5 FM",
        market: "Atlanta",
        latitude: "33.1137419",
        longitude: "-94.1643537",
      },
      {
        station: "KTAE",
        frequency: "1260 AM",
        market: "Austin",
        latitude: "30.267153",
        longitude: "-97.7430608",
      },
      {
        station: "KAMD",
        frequency: "97.1 FM",
        market: "Bearden",
        latitude: "33.7246503",
        longitude: "-92.6156607",
      },
      {
        station: "KTON",
        frequency: "100.9 FM",
        market: "Belton",
        latitude: "31.0560132",
        longitude: "-97.464453",
      },
      {
        station: "KBST",
        frequency: "1490 AM",
        market: "Big Spring",
        latitude: "32.2503979",
        longitude: "-101.4787355",
      },
      {
        station: "KNTX",
        frequency: "1410 AM",
        market: "Bowie",
        latitude: "33.5589953",
        longitude: "-97.8486457",
      },
      {
        station: "KNEL",
        frequency: "1490 AM",
        market: "Brady",
        latitude: "31.1351682",
        longitude: "-99.3350552",
      },
      {
        station: "KKBI",
        frequency: "1490 FM",
        market: "Broken Bow",
        latitude: "34.0292764",
        longitude: "-94.7391045",
      },
      {
        station: "KBWD",
        frequency: "1380 AM",
        market: "Brownwood",
        latitude: "31.7093197",
        longitude: "-98.9911611",
      },
      {
        station: "KEOJ",
        frequency: "101.1 FM",
        market: "Caney",
        latitude: "37.0114608",
        longitude: "-95.9352584",
      },
      {
        station: "K248DE",
        frequency: "97.5 FM",
        market: "Canyon",
        latitude: "34.9803342",
        longitude: "-101.9188024",
      },
      {
        station: "KGAS",
        frequency: "104.3 FM",
        market: "Carthage",
        latitude: "32.1573841",
        longitude: "-94.3374199",
      },
      {
        station: "KCHT",
        frequency: "99.7 FM",
        market: "Childress",
        latitude: "34.4264529",
        longitude: "-100.2040019",
      },
      {
        station: "KCKB",
        frequency: "104.1 FM",
        market: "Cisco",
        latitude: "32.3881861",
        longitude: "-98.9792336",
      },
      {
        station: "KICA",
        frequency: "980 AM",
        market: "Clovis",
        latitude: "34.4047987",
        longitude: "-103.2052272",
      },
      {
        station: "KCOM",
        frequency: "1550 AM",
        market: "Comanche",
        latitude: "31.8973693",
        longitude: "-98.6036581",
      },
      {
        station: "KCOM",
        frequency: "100.5 FM",
        market: "Comanche",
        latitude: "31.8973693",
        longitude: "-98.6036581",
      },
      {
        station: "KRLD",
        frequency: "105.3 FM",
        market: "Dallas-Fort Worth",
        latitude: "32.7078751",
        longitude: "-96.9209135",
      },
      {
        station: "KJIM",
        frequency: "1500 AM",
        market: "Denison",
        latitude: "33.7556593",
        longitude: "-96.536658",
      },
      {
        station: "KDHN",
        frequency: "1470 AM",
        market: "Dimmitt",
        latitude: "34.5509028",
        longitude: "-102.3118607",
      },
      {
        station: "K274DG",
        frequency: "102.7 FM",
        market: "Dimmitt",
        latitude: "34.5509028",
        longitude: "-102.3118607",
      },
      {
        station: "KHEY",
        frequency: "1380 AM",
        market: "El Paso",
        latitude: "31.7618778",
        longitude: "-106.4850217",
      },
      {
        station: "KTON",
        frequency: "100.9 FM",
        market: "Fort Hood",
        latitude: "31.1363315",
        longitude: "-97.7797049",
      },
      {
        station: "KHGZ",
        frequency: "98.9 FM",
        market: "Glenwood",
        latitude: "34.3271349",
        longitude: "-93.5497446",
      },
      {
        station: "KBLY",
        frequency: "100.5 FM",
        market: "Graham",
        latitude: "33.1070597",
        longitude: "-98.589502",
      },
      {
        station: "KSEY",
        frequency: "1230 AM",
        market: "Haskell",
        latitude: "33.1576031",
        longitude: "-99.7337014",
      },
      {
        station: "KWRD",
        frequency: "1470 AM",
        market: "Henderson",
        latitude: "32.1532156",
        longitude: "-94.7993802",
      },
      {
        station: "KHGZ",
        frequency: "670 AM",
        market: "Hot Springs",
        latitude: "34.5077615",
        longitude: "-93.0546003",
      },
      {
        station: "KHGZ",
        frequency: "98.9 FM",
        market: "Hot Springs",
        latitude: "34.5077615",
        longitude: "-93.0546003",
      },
      {
        station: "KPLT",
        frequency: "96.3 FM",
        market: "Hugo",
        latitude: "34.01066",
        longitude: "-95.5096821",
      },
      {
        station: "KRZI",
        frequency: "1660 AM",
        market: "Killeen",
        latitude: "31.1171194",
        longitude: "-97.7277959",
      },
      {
        station: "KPET",
        frequency: "690 AM",
        market: "Lamesa",
        latitude: "32.7376001",
        longitude: "-101.950992",
      },
      {
        station: "KJTV",
        frequency: "950 AM",
        market: "Levelland",
        latitude: "33.5873164",
        longitude: "-102.37796",
      },
      {
        station: "KTTU",
        frequency: "97.3 FM",
        market: "Lubbock",
        latitude: "33.5778631",
        longitude: "-101.8551665",
      },
      {
        station: "KMAD",
        frequency: "1550 AM",
        market: "Madill",
        latitude: "34.0903751",
        longitude: "-96.7716691",
      },
      {
        station: "KMHT",
        frequency: "103.9 FM",
        market: "Marshall",
        latitude: "32.5448714",
        longitude: "-94.3674184",
      },
      {
        station: "KQIK",
        frequency: "105.9 FM",
        market: "Mcalester",
        latitude: "34.9334298",
        longitude: "-95.7697131",
      },
      {
        station: "KLRK",
        frequency: "1590 AM",
        market: "Mexia",
        latitude: "31.6798895",
        longitude: "-96.482203",
      },
      {
        station: "KBEF",
        frequency: "104.5 FM",
        market: "Minden",
        latitude: "32.6154278",
        longitude: "-93.2868354",
      },
      {
        station: "KLRK",
        frequency: "101.3 FM",
        market: "Moody",
        latitude: "31.3082273",
        longitude: "-97.3613977",
      },
      {
        station: "KCKB",
        frequency: "104.1 FM",
        market: "Moran",
        latitude: "32.5476245",
        longitude: "-99.1659072",
      },
      {
        station: "KYAL",
        frequency: "97.1 FM",
        market: "Muskogee",
        latitude: "35.7478769",
        longitude: "-95.3696909",
      },
      {
        station: "KNAS",
        frequency: "105.5 FM",
        market: "Nashville",
        latitude: "33.9482519",
        longitude: "-93.8470509",
      },
      {
        station: "KBLY",
        frequency: "100.5 FM",
        market: "Newcastle",
        latitude: "33.1940005",
        longitude: "-98.738673",
      },
      {
        station: "KYZS",
        frequency: "1490 AM",
        market: "New Chapel Hill",
        latitude: "32.3051516",
        longitude: "-95.1730025",
      },
      {
        station: "KNTX",
        frequency: "104.3 FM",
        market: "Nocona",
        latitude: "33.7867684",
        longitude: "-97.7258626",
      },
      {
        station: "KEBC",
        frequency: "1560 AM",
        market: "Oklahoma City",
        latitude: "35.4675602",
        longitude: "-97.5164276",
      },
      {
        station: "KBVP",
        frequency: "104.3 FM",
        market: "Olney",
        latitude: "33.9137085",
        longitude: "-98.4933873",
      },
      {
        station: "KPLT",
        frequency: "1490 AM",
        market: "Paris",
        latitude: "33.6609389",
        longitude: "-95.555513",
      },
      {
        station: "KVOP",
        frequency: "1090 AM",
        market: "Plainview",
        latitude: "34.1847936",
        longitude: "-101.7068417",
      },
      {
        station: "KPYN",
        frequency: "900 AM",
        market: "Queen City",
        latitude: "33.1487411",
        longitude: "-94.150187",
      },
      {
        station: "K270CO",
        frequency: "101.9 FM",
        market: "Round Rock",
        latitude: "30.5082551",
        longitude: "-97.678896",
      },
      {
        station: "KGKL",
        frequency: "960 AM",
        market: "San Angelo",
        latitude: "31.4637723",
        longitude: "-100.4370375",
      },
      {
        station: "KZDC",
        frequency: "1250 AM",
        market: "San Antonio",
        latitude: "29.4251905",
        longitude: "-98.4945922",
      },
      {
        station: "KZDC",
        frequency: "94.5 FM",
        market: "San Antonio",
        latitude: "29.4251905",
        longitude: "-98.4945922",
      },
      {
        station: "K260CR",
        frequency: "99.9 FM",
        market: "Sapula",
        latitude: "35.9987007",
        longitude: "-96.1141664",
      },
      {
        station: "KSEY",
        frequency: "94.3 FM",
        market: "Seymour",
        latitude: "33.5942608",
        longitude: "-99.2603531",
      },
      {
        station: "KJIM",
        frequency: "101.3 FM",
        market: "Sherman",
        latitude: "33.6356618",
        longitude: "-96.6088805",
      },
      {
        station: "KSYB",
        frequency: "1300 AM",
        market: "Shreveport",
        latitude: "32.5251516",
        longitude: "-93.7501789",
      },
      {
        station: "KSNY",
        frequency: "1450 AM",
        market: "Snyder",
        latitude: "32.7178862",
        longitude: "-100.9176184",
      },
      {
        station: "KSTV",
        frequency: "1510 AM",
        market: "Stephenville",
        latitude: "32.2206958",
        longitude: "-98.2022633",
      },
      {
        station: "K230BS",
        frequency: "93.9 FM",
        market: "Stephenville",
        latitude: "32.2206958",
        longitude: "-98.2022633",
      },
      {
        station: "KSST",
        frequency: "1230 AM",
        market: "Sulphur Springs",
        latitude: "33.1384479",
        longitude: "-95.6010668",
      },
      {
        station: "KTON",
        frequency: "1330 AM",
        market: "Temple",
        latitude: "31.0982344",
        longitude: "-97.342782",
      },
      {
        station: "KKTK",
        frequency: "1400 AM",
        market: "Texarkana",
        latitude: "33.425125",
        longitude: "-94.0476882",
      },
      {
        station: "KYAL",
        frequency: "1550 AM",
        market: "Tulsa",
        latitude: "36.1539816",
        longitude: "-95.992775",
      },
      {
        station: "KRWR",
        frequency: "92.1 FM",
        market: "Tyler",
        latitude: "32.3512601",
        longitude: "-95.3010624",
      },
      {
        station: "KITO",
        frequency: "96.1 FM",
        market: "Vinita",
        latitude: "36.6386937",
        longitude: "-95.1541339",
      },
      {
        station: "KNCB",
        frequency: "1320 AM",
        market: "Vivian",
        latitude: "32.8715278",
        longitude: "-93.9874032",
      },
      {
        station: "KRZI",
        frequency: "1660 AM",
        market: "Waco",
        latitude: "31.549333",
        longitude: "-97.1466695",
      },
      {
        station: "KRZI",
        frequency: "99.1 FM",
        market: "Waco",
        latitude: "31.549333",
        longitude: "-97.1466695",
      },
      {
        station: "K264AN",
        frequency: "100.7 FM",
        market: "Wolfforth",
        latitude: "33.5059189",
        longitude: "-102.0090575",
      },
    ],
    "Toronto Blue Jays": [
      {
        market: "Vancouver",
        station: "CISL",
        frequency: "650 AM",
        latitude: "49.2827291",
        longitude: "-123.1207375",
      },
      {
        market: "Calgary",
        station: "CFAC",
        frequency: "960 AM",
        latitude: "51.0447331",
        longitude: "-114.0718831",
      },
      {
        market: "Edmonton",
        station: "CFRN",
        frequency: "101.7 FM",
        latitude: "53.5461245",
        longitude: "-113.4938229",
      },
      {
        market: "Estevan",
        station: "CJSL",
        frequency: "1150 AM",
        latitude: "49.1390842",
        longitude: "-102.9914807",
      },
      {
        market: "Kindersley",
        station: "CFYM",
        frequency: "1210 AM",
        latitude: "51.4675266",
        longitude: "-109.1546825",
      },
      {
        market: "Rosetown",
        station: "CJYM",
        frequency: "1330 AM",
        latitude: "51.5554913",
        longitude: "-107.9910335",
      },
      {
        market: "Weyburn",
        station: "CFSL",
        frequency: "1190 AM",
        latitude: "49.6632836",
        longitude: "-103.8532906",
      },
      {
        market: "Kitchener",
        station: "CKGL",
        frequency: "570 AM",
        latitude: "43.4516395",
        longitude: "-80.4925337",
      },
      {
        market: "London",
        station: "CFPL",
        frequency: "980 AM",
        latitude: "42.9849233",
        longitude: "-81.2452768",
      },
      {
        market: "North Bay",
        station: "CKAT",
        frequency: "600 AM",
        latitude: "46.3091152",
        longitude: "-79.4608204",
      },
      {
        market: "Ottawa",
        station: "CIWW",
        frequency: "1310 AM\n101.1 FM",
        latitude: "45.4215296",
        longitude: "-75.6971931",
      },
      {
        market: "Peterborough",
        station: "CJMB",
        frequency: "90.5 FM",
        latitude: "44.3047061",
        longitude: "-78.3199606",
      },
      {
        market: "Sarnia",
        station: "CHOK",
        frequency: "1070 AM",
        latitude: "42.974536",
        longitude: "-82.4065901",
      },
      {
        market: "Stratford",
        station: "CJCS",
        frequency: "1240 AM",
        latitude: "43.3700007",
        longitude: "-80.9822286",
      },
      {
        market: "Toronto",
        station: "CJCL",
        frequency: "590 AM",
        latitude: "43.653226",
        longitude: "-79.3831843",
      },
      {
        market: "Wingham",
        station: "CKNX",
        frequency: "920 AM",
        latitude: "43.8879476",
        longitude: "-81.3116601",
      },
      {
        market: "Montreal",
        station: "CKGM",
        frequency: "690 AM",
        latitude: "45.5016889",
        longitude: "-73.567256",
      },
      {
        market: "Halifax",
        station: "CJNI",
        frequency: "95.7 FM",
        latitude: "44.6487635",
        longitude: "-63.5752387",
      },
    ],
    "Washington Nationals": [
      {
        market: "Washington, D.C.",
        latitude: "38.9071923",
        longitude: "-77.0368707",
        station: "WJFK",
        frequency: "106.7 FM",
      },
      {
        market: "Cumberland",
        latitude: "39.6528654",
        longitude: "-78.7625185",
        station: "WCMD",
        frequency: "1230 AM\n102.1 FM",
      },
      {
        market: "Thurmont",
        latitude: "39.6237094",
        longitude: "-77.4108201",
        station: "WTHU",
        frequency: "1450 AM",
      },
      {
        market: "Charlottesville",
        latitude: "38.0293059",
        longitude: "-78.4766781",
        station: "WCHV",
        frequency: "107.5 FM",
      },
      {
        market: "Front Royal",
        latitude: "38.9181671",
        longitude: "-78.1944446",
        station: "WFTR",
        frequency: "1450 AM",
      },
      {
        market: "Lynchburg",
        latitude: "37.4137536",
        longitude: "-79.1422464",
        station: "WBRG",
        frequency: "105.1 FM\n1050 AM",
      },
      {
        market: "Martinsville",
        latitude: "36.6915262",
        longitude: "-79.8725386",
        station: "WHEE",
        frequency: "1370 AM",
      },
      {
        market: "Richmond",
        latitude: "37.5407246",
        longitude: "-77.4360481",
        station: "WRNL",
        frequency: "910 AM",
      },
      {
        market: "Richmond",
        latitude: "37.5407246",
        longitude: "-77.4360481",
        station: "WRVA",
        frequency: "1140 AM",
      },
      {
        market: "Martinsburg",
        latitude: "39.4562099",
        longitude: "-77.9638869",
        station: "WRNR",
        frequency: "740 AM\n106.5 FM",
      },
      {
        market: "Greenville",
        latitude: "35.612661",
        longitude: "-77.3663538",
        station: "WECU",
        frequency: "1570 AM",
      },
      {
        market: "Nags Head",
        latitude: "35.9573922",
        longitude: "-75.6240619",
        station: "WOBX",
        frequency: "98.1 FM",
      },
      {
        market: "New Bern",
        latitude: "35.108493",
        longitude: "-77.0441143",
        station: "WWNB",
        frequency: "1490 AM\n103.9 FM",
      },
    ],
  },
};
