import React, { useState, useEffect } from "react";
import Geolocation from "@react-native-community/geolocation";
import microphone from "./microphone.svg";
import venmo from "./venmo.png";
import "./App.css";
import Button from "@material-ui/core/Button";
import { STATION_COORDINATES } from "./StationCoordinates";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Spinner from "./Spinner";
import { ALL_TEAMS, ALL_SPORTS } from "./SportsAndTeams";
import TwitterIcon from "@material-ui/icons/Twitter";
import MobileStoreButton from "react-mobile-store-button";

const App = () => {
  const [error, setError] = useState("");
  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [hasPosition, setHasPosition] = useState(false);
  const [topNetwork, setTopNetwork] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sportSelected, setSportSelected] = useState(
    localStorage.getItem("sport") || ALL_SPORTS[0]
  );
  const [teamSelected, setTeamSelected] = useState(
    localStorage.getItem("team") || ALL_TEAMS[sportSelected][0]
  );

  const getPosition = async () => {
    setIsLoading(true);
    Geolocation.getCurrentPosition(
      (pos) => {
        setError("");
        setPosition({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        });
      },
      (e) => {
        setError(e.message);
        setIsLoading(false);
      }
    );
    setHasPosition(true);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2, unit) => {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  };

  useEffect(() => {
    if (hasPosition) {
      let closest = 9999999;
      let topNetwork = {};
      for (
        var i = 0;
        i < STATION_COORDINATES[sportSelected][teamSelected].length;
        i++
      ) {
        let radioCoordinates = STATION_COORDINATES[sportSelected][teamSelected];
        let distance = calculateDistance(
          position.latitude,
          position.longitude,
          radioCoordinates[i].latitude,
          radioCoordinates[i].longitude,
          "K"
        );
        if (distance < closest) {
          closest = distance;
          topNetwork = radioCoordinates[i];
        }
      }
      setTopNetwork([topNetwork]);
      setIsLoading(false);
    }
  }, [position]);
  const iOSUrl =
    "https://apps.apple.com/us/app/my-teams-radio/id1633152942";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.myteamsradionative";
  return (
    <div className="App">
      <header className="App-header">
        <h2>My Team's Radio</h2>
        <img src={microphone} className="App-logo" alt="microphone" />
        <h5 style={{ maxWidth: "800px", padding: "20px", marginBottom: "0px" }}>
          Due to a tremendous amount of positive feedback to this site, I
          decided to make this both an iOS and Android app. Please consider
          downloading from the Apple App Store or the Google Play Store. Thank you
          for the support!
        </h5>
        <h5>-Brett</h5>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
          {/* <div> */}
            <MobileStoreButton
              style={{alignItems: "center", justifyContent: "center", height: "30px", width: "230px", marginBottom: "10px"}}
              store="ios"
              url={iOSUrl}
              linkProps={{ title: "Apple Store" }}
            />
          {/* </div> */}
          {/* <div> */}
            <MobileStoreButton
            style={{alignItems: "center", justifyContent: "center", height: "50px", width: "250px"}}
              store="android"
              url={androidUrl}
              linkProps={{ title: "Google Play Store" }}
            />
          {/* </div> */}
        </div>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <select
            style={{ fontSize: "16px" }}
            value={sportSelected}
            onChange={(e) => {
              setSportSelected(e.target.value);
              localStorage.setItem("sport", e.target.value);
            }}
          >
            {ALL_SPORTS.map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
          <select
            style={{ marginLeft: "5px", fontSize: "16px" }}
            value={teamSelected}
            onChange={(e) => {
              setTeamSelected(e.target.value);
              localStorage.setItem("team", e.target.value);
            }}
          >
            {ALL_TEAMS[sportSelected].map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
        </div> */}
        {/* <div>
          <Button
            style={{ marginTop: "50px", marginBottom: "25px" }}
            variant="contained"
            onClick={getPosition}
          >
            {isLoading ? <Spinner /> : "Find Closest Station"}
          </Button>
          {error ? (
            <div style={{ color: "#FFCCCC", textAlign: 'left', marginLeft: '20px', marginRight: '20px' }}>
              <h6>
                Whoops! You will need to allow location services to use this site.
              </h6>
              <h6>
                iPhone: Settings &gt; Safari/Chrome &gt; Location &gt; Ask
              </h6>
              <h6>
                Android: Settings &gt; Safari/Chrome &gt; Permissions &gt; Location &gt; Ask
              </h6>
            </div>
          ) : (
            <></>
          )}
          {hasPosition && !error && (
            <div>
              <TableContainer className="root">
                <Table className="table">
                  <TableHead className="columns">
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="center" className="cell">
                        Market
                      </TableCell>
                      <TableCell align="center" className="cell">
                        Station
                      </TableCell>
                      <TableCell align="center" className="cell">
                        Frequency
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="rows">
                    {topNetwork.map((data, index) => (
                      <TableRow>
                        <TableCell align="center" className="cell">
                          {index + 1}.
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {data.market}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="cell"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {data.station}
                        </TableCell>
                        <TableCell
                          align="right"
                          className="cell"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {data.frequency}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div> */}
        {/* <h6>Venmo @bgeshk to help maintain server costs</h6> */}
        {/* <div style={{ display: "flex", flexDirection: "row" }}>
          <a href="https://twitter.com/MyTeamsRadio" style={{ color: "white" }}>
            <TwitterIcon />
          </a>
          <a href="https://venmo.com/code?user_id=1541334561193984734&created=1655957557.897052&printed=1">
            <img
              src={venmo}
              alt="venmo"
              style={{
                color: "white",
                background: "white",
                marginLeft: "15px",
              }}
              height={25}
              width={25}
            />
          </a>
        </div> */}
      </header>
      {/* <footer className="App-footer">
        <h6>
          This site is not affiliated, authorized, or in any way connected to
          the MLB, NFL, NBA, NHL or their affiliates.
        </h6>
      </footer> */}
    </div>
  );
};

export default App;
